import { useNavigate, useParams } from "react-router-dom";
import { options } from "src/core/statics/nfts-mock-data/nfts-mock-data";
import { BiExport, BiDotsHorizontalRounded } from "react-icons/bi";
import { t } from "i18next";
import TitleDetailsPageComponent from "src/components/user/title-details-page-component";
import { appRoutesObj } from "src/app.paths";
import HighchartsComponent from "src/components/user/high-charts-component/high-charts-component";
import ToggleList from "src/components/user/toggle-component";
import { useEffect, useRef, useState } from "react";
import DataTableNfts from "src/components/user/data-table-nfts";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoFacebook } from "react-icons/io";
import CountdownTimer from "src/components/user/count-down-timer-component/count-down-timer-component";
import LoginPopupComponent from "src/components/user/popup-login-component";
import BuyNftPopupComponent from "src/components/user/buy-nft-popup-component";

export const offersData = [
  {
    sarPrices: "11.000",
    usdPrice: "2932.32",
    qnantity: "1",
    floorDiference: "90% below",
    explration: "26 Days",
    form: "273E40",
  },
  {
    sarPrices: "11.000",
    usdPrice: "2932.32",
    qnantity: "1",
    floorDiference: "90% below",
    explration: "26 Days",
    form: "273E40",
  },
  {
    sarPrices: "11.000",
    usdPrice: "2932.32",
    qnantity: "1",
    floorDiference: "90% below",
    explration: "26 Days",
    form: "273E40",
  },
  {
    sarPrices: "11.000",
    usdPrice: "2932.32",
    qnantity: "1",
    floorDiference: "90% below",
    explration: "26 Days",
    form: "273E40",
  },
];

const itemActivityData = [
  {
    Event: "Transfer",
    Price: "10.000",
    To: "0x456...def",
    Date: "2024-06-01",
    form: "273E40",
  },
  {
    Event: "Sale",
    Price: "12.000",
    To: "0x012...jkl",
    Date: "2024-06-02",
    form: "ABC123",
  },
  {
    Event: "Transfer",
    Price: "10.000",
    To: "0x456...def",
    Date: "2024-06-01",
    form: "273E40",
  },
  {
    Event: "Transfer",
    Price: "10.000",
    To: "0x456...def",
    Date: "2024-06-01",
    form: "273E40",
  },
];

const offersHeaders = [
  "SAR Price",
  "USD Price",
  "Qunantity",
  "Floor Diference",
  "Explration",
  "Form",
];
function NftsDetailsItemContainer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedItem2, setSelectedItem2] = useState<string>("Offers");
  const [showShareMenu, setShowShareMenu] = useState<boolean>(false);
  const [likedItems, setLikedItems] = useState<number[]>([]);
  const [isOpenPlaceAbidPopup, setIsOpenPlaceAbidPopup] =
    useState<boolean>(false);
  const shareMenuRef = useRef<HTMLDivElement>(null);
  const items = options.flatMap((option) => option.Item || []);
  const selectedItem = items.find((item) => item.id.toString() === id);
  const Nftspage: string = appRoutesObj.getNftsPagePath();
  const endTime = "2024-08-17T00:00:00";
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    navigate(Nftspage);
  };
  const handleToggle = (itemName: string) => {
    setSelectedItem2(itemName);
  };
  const toggleLike = () => {
    if (selectedItem) {
      const newLikedItems = likedItems.includes(selectedItem.id)
        ? likedItems.filter((itemId) => itemId !== selectedItem.id)
        : [...likedItems, selectedItem.id];
      console.log("New Liked Items:", newLikedItems);
      setLikedItems(newLikedItems);
      localStorage.setItem("likedItems", JSON.stringify(newLikedItems));
    }
  };
  const itemActivityHeaders = ["Event", "Price", "Form", "To", "Date"];
  const handleClickOutside = (event: MouseEvent) => {
    if (
      shareMenuRef.current &&
      !shareMenuRef.current.contains(event.target as Node)
    ) {
      setShowShareMenu(false);
    }
  };
  const handlePlaceBidClick = () => {
    if (loggedIn) {
      setIsOpenPlaceAbidPopup(true);
    } else {
      setShowLoginPopup(true);
    }
  };
  const copyLinkToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => alert("Link copied to clipboard"))
      .catch((err) => console.error("Failed to copy the link", err));
  };
  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.href
    )}`;
    window.open(facebookUrl, "_blank");
  };
  const ShareOnX = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      window.location.href
    )}`;
    window.open(twitterUrl, "_blank");
  };
  useEffect(() => {
    const initialValues = localStorage.getItem("user");
    if (initialValues) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    if (showShareMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showShareMenu]);
  return (
    <div className="pt-5 relative">
      <TitleDetailsPageComponent
        home={"Home"}
        onClick={handleClick}
        titlePage={"NFTs"}
        titleDetailsPage={"Inception"}
      />
      <div className="px-32 mobile:px-4 tablet:px-4 mt-[40px] mobile:mb-40">
        <div className="flex flex-col pt-4 px-4 mobile:px-2 mobile:pt-2 bg-[#002A66] rounded-lg">
          <div className="flex mobile:flex-col gap-5">
            <div className="flex-col w-full flex gap-4">
              <img
                className="h-[572px] mobile:h-[339px]"
                src={selectedItem?.img}
                alt=""
              />
              <div className="flex flex-col w-full mobile:hidden justify-center px-6 gap-4 text-white mobile:text-base bg-[#00398A] h-[398px] rounded-lg">
                <div>
                  <p className="text-[19px] mobile:text-base mb-2 font-openSans">
                    {t("Details")}
                  </p>
                  <hr className="opacity-20" />
                </div>
                <div className="flex justify-between font-openSans pt-5">
                  <p>{t("contract Address")}</p>
                  <p>{selectedItem?.contractAddress}</p>
                </div>
                <div className="flex justify-between font-openSans pt-5">
                  <p>{t("Token")}</p>
                  <p>{selectedItem?.token}</p>
                </div>
                <div className="flex justify-between font-openSans pt-5">
                  <p>{t("Token standard")}</p>
                  <p>{selectedItem?.tokenStandard}</p>
                </div>
                <div className="flex justify-between font-openSans pt-5">
                  <p>{t("Last updated")}</p>
                  <p>{selectedItem?.lastUpdated}</p>
                </div>
                <div className="flex justify-between font-openSans pt-5">
                  <p>{t("Creator Earnings")}</p>
                  <p>{selectedItem?.creatorEarnings}</p>
                </div>
              </div>
            </div>
            <div className="flex max-w-[516px] desktop:max-w-[616px] tablet:max-w-[380px] laptop:max-w-[400px] mobile:justify-center">
              <div className="flex flex-col gap-4 w-full">
                <p className="flex justify-between text-[28px] text-white font-montserrat font-bold">
                  <p className="flex gap-2">
                    <p>{selectedItem?.name}</p>
                    <p> #{selectedItem?.id}</p>
                  </p>
                  <p className="flex gap-2">
                    <div className="px-[53px] mobile:px-6">
                      <div className="absolute mt-6">
                        {showShareMenu && (
                          <div
                            ref={shareMenuRef}
                            className="w-[111px] text-[13px] gap-1.5 pt-1.5 font-openSans font-normal flex flex-col items-center h-auto mt-2 bg-Primary-500 rounded shadow"
                          >
                            <div
                              onClick={copyLinkToClipboard}
                              className="w-full text-center cursor-pointer"
                            >
                              {t("Copy link")}
                            </div>
                            <hr className="w-full bg-white opacity-20" />
                            <div className="w-full text-center items-center flex justify-center">
                              <IoLogoFacebook
                                onClick={shareOnFacebook}
                                className="text-white cursor-pointer"
                                size={20}
                              />
                            </div>
                            <hr className="w-full bg-white opacity-20" />
                            <div className="w-full text-center items-center flex justify-center mb-1">
                              {" "}
                              <FaXTwitter
                                onClick={ShareOnX}
                                className="text-white cursor-pointer"
                                size={20}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {likedItems.includes(selectedItem?.id || -1) ? (
                      <FaHeart
                        size={20}
                        className="text-red-500 mt-1 cursor-pointer"
                        onClick={toggleLike}
                      /> // Liked icon
                    ) : (
                      <FaRegHeart
                        className="mt-1 cursor-pointer"
                        size={20}
                        onClick={toggleLike}
                      /> // Not liked icon
                    )}{" "}
                    <BiExport
                      size={24}
                      className="cursor-pointer"
                      onClick={() => setShowShareMenu(!showShareMenu)}
                    />
                    <BiDotsHorizontalRounded size={24} />
                  </p>
                </p>
                <div className="flex w-full gap-[32px] mt-2">
                  <img
                    className="w-[80px] h-[80px] rounded-full"
                    src={selectedItem?.img}
                    alt=""
                  />
                  <div>
                    <p className="text-[19px] text-[#C1C1C1] font-openSans">
                      {" "}
                      Owner
                    </p>
                    <p className="text-[23px] mobile:text-base text-white font-bold font-montserrat">
                      {selectedItem?.allName}
                    </p>
                    <p className="text-[19px] mobile:text-base text-[#C1C1C1] font-normal font-openSans">
                      {selectedItem?.username}
                    </p>
                  </div>
                </div>
                <div className="flex items-center px-4 justify-between font-bold text-[19px] mobile:text-base font-montserrat text-white bg-[#00398A] h-[75px] mobile:h-[54px] rounded-lg">
                  <p>{selectedItem?.price} SAR</p>
                  <CountdownTimer endTime={endTime} />
                </div>
                <div className="flex flex-col justify-center px-6 text-white bg-[#00398A] h-[231px] mobile:h-auto mobile:pt-8 mobile:pb-6 rounded-lg">
                  <p className="text-[19px] mobile:text-base tablet:text-base tablet:mb-0 mb-2 font-openSans">
                    {t("Description")}
                  </p>
                  <hr className="opacity-20" />
                  <p className="font-openSans pt-5 mobile:min-w-[294px] tablet:w-[350px]  min-w-[200px] text-white text-base font-normal leading-snug">
                    {selectedItem?.description}
                  </p>
                </div>
                {/* // button Bid */}
                <button
                  onClick={handlePlaceBidClick}
                  className="flex items-center px-4 mt-2 justify-center font-montserrat text-white bg-secondary-500 h-[48px] rounded-lg"
                >
                  {t("Collect Now")}
                </button>
                <div className="flex mt-1 pt-8 flex-col justify-center px-6 text-white bg-[#00398A] h-[398px] rounded-lg">
                  <p className="text-[19px] mb-2 font-openSans">
                    Price History
                  </p>
                  <hr className="opacity-20" />
                  <p className="font-openSans pt-5">
                    <HighchartsComponent />
                  </p>
                </div>
                {/* mobile */}
                <div className=" desktop:hidden laptop:hidden largeLaptop:hidden largeDesktop:hidden tablet:hidden">
                  <div className="flex flex-col w-full justify-center px-6 gap-4 text-white bg-[#00398A] h-[398px] rounded-lg">
                    <div>
                      <p className="text-[19px] mb-2 font-openSans">Details</p>
                      <hr className="opacity-20" />
                    </div>
                    <div className="flex justify-between font-openSans pt-5">
                      <p>contract Address</p>
                      <p>0x1984...c38f</p>
                    </div>
                    <div className="flex  justify-between font-openSans pt-5">
                      <p>Token</p>
                      <p>0270</p>
                    </div>
                    <div className="flex  justify-between font-openSans pt-5">
                      <p>Token standard</p>
                      <p>Erc-721</p>
                    </div>
                    <div className="flex  justify-between font-openSans pt-5">
                      <p>Last updated</p>
                      <p>8 months ago</p>
                    </div>
                    <div className="flex  justify-between font-openSans pt-5">
                      <p>Creator Earnings</p>
                      <p>8%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col h-[413px] mobile:h-auto mobile:pb-4 mt-4 mb-4 bg-[#00398A] rounded-lg">
            <ToggleList onToggle={handleToggle} />
            <div>
              {selectedItem2 === "Offers" && (
                <DataTableNfts data={offersData} title={offersHeaders} />
              )}
              {selectedItem2 === "Item Activity" && (
                <DataTableNfts
                  data={itemActivityData}
                  title={itemActivityHeaders}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpenPlaceAbidPopup && (
        <BuyNftPopupComponent
          currentPrice={selectedItem?.price}
          name={selectedItem?.name}
          img={selectedItem?.img}
          onClickToClose={() => setIsOpenPlaceAbidPopup(false)}
        />
      )}
      {showLoginPopup && (
        <LoginPopupComponent onClickToClose={() => setShowLoginPopup(false)} />
      )}
    </div>
  );
}

export default NftsDetailsItemContainer;
