import {
  nftsHistoryTypes,
  option,
  walletHistoryTypes,
} from "src/core/types/user.type";

export const options: option[] = [
  {
    label: "Item",
    Item: [
      {
        id: 1,
        allName: "Abdullah Ahmed",
        name: "Inception1",
        img: "/assets/images/Frame 33.png",
        price: 10,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
        filters: ["listed", "new"],
        dateReceived: "2024-07-20T10:30:00Z",
        dateListed: "2024-08-01T15:00:00Z",
      },
      {
        id: 2,
        allName: "Abdullah Ahmed",
        name: "Inception2",
        img: "/assets/images/nfts-card-0.png",
        price: 15,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
        filters: ["hasOffers"],
        dateReceived: "2024-06-15T09:45:00Z",
        dateListed: "2024-06-25T12:00:00Z",
      },
      {
        id: 3,
        allName: "Abdullah Ahmed",
        name: "Inception3",
        img: "/assets/images/Frame 33.png",
        price: 20,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
        filters: ["onAuction"],
        dateReceived: "2024-05-10T08:00:00Z",
        dateListed: "2024-05-20T14:30:00Z",
      },
      {
        id: 4,
        allName: "Abdullah Ahmed",
        name: "Inception4",
        img: "/assets/images/Frame 33 (2).png",
        price: 30,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
        filters: ["listed", "onAuction"],
        dateReceived: "2024-04-01T11:00:00Z",
        dateListed: "2024-04-15T16:00:00Z",
      },
      {
        id: 5,
        allName: "Abdullah Ahmed",
        name: "Inception5",
        img: "/assets/images/nfts-card-0.png",
        price: 25,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
        filters: ["listed"],
        dateReceived: "2024-03-05T07:30:00Z",
        dateListed: "2024-03-10T10:00:00Z",
      },
      {
        id: 6,
        allName: "Abdullah Ahmed",
        name: "Inception6",
        img: "/assets/images/Frame 33 (2).png",
        price: 35,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
        filters: ["listed", "hasOffers"],
        dateReceived: "2024-02-20T13:15:00Z",
        dateListed: "2024-02-25T15:00:00Z",
      },
      // Other items...
    ],
  },
  {
    label: "Collections",
    Collections: [
      {
        id: 1,
        name: "Inception",
        allName: "Abdullah Ahmed",
        img: [
          "/assets/images/Frame 33 (2).png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/Frame 33.png",
          "/assets/images/Frame 33 (2).png",
          "/assets/images/nfts-card-0.png",
        ],
        price: 5,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
      },
      {
        id: 2,
        name: "Inception",
        allName: "Abdullah Ahmed",
        img: [
          "/assets/images/Frame 33.png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/Frame 33 (2).png",
          "/assets/images/Frame 33.png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/nfts-card-0.png",
        ],
        price: 7,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
      },
      {
        id: 3,
        name: "Inception",
        allName: "Abdullah Ahmed",
        img: [
          "/assets/images/Frame 33.png",
          "/assets/images/Frame 33 (2).png",
          "/assets/images/nfts-card-0.png",
        ],
        price: 9,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
      },
      {
        id: 4,
        name: "Inception",
        allName: "Abdullah Ahmed",
        img: [
          "/assets/images/Frame 33.png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/Frame 33.png",
          "/assets/images/Frame 33 (2).png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/Frame 33.png",
          "/assets/images/nfts-card-0.png",
          "/assets/images/nfts-card-0.png",
        ],
        price: 8,
        username: "@loidonsaf",
        description:
          "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space. This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
        contractAddress: "0x1984...c38f",
        token: "0270",
        tokenStandard: "Erc-721",
        lastUpdated: "8 months ago",
        creatorEarnings: "8%",
      },
      // Other Collections objects...
    ],
  },
];
export const headersNFtsHistory = [
  "Event",
  "Item",
  "Unit Price",
  "Quantity",
  "From",
  "To",
  "Date",
];

export const nftsHistoryData: nftsHistoryTypes[] = [
  {
    event: "Sale",
    item: "NFT",
    unitPrice: "25 SAR",
    quantity: "1",
    from: "User123",
    to: "User456",
    date: "2024-06-15",
  },
  {
    event: "Bid",
    item: "Collection",
    unitPrice: "25 SAR",
    quantity: "2",
    from: "User456",
    to: "User123",
    date: "2024-07-01",
  },
  {
    event: "Sale",
    item: "NFT",
    unitPrice: "25 SAR",
    quantity: "1",
    from: "User123",
    to: "User456",
    date: "2024-06-15",
  },
  {
    event: "Bid",
    item: "Collection",
    unitPrice: "25 SAR",
    quantity: "2",
    from: "User456",
    to: "User123",
    date: "2024-07-01",
  },
];
export const headersWalletHistory = ["Event", "Token", "Pool", "Price", "Date"];

export const walletHistoryData: walletHistoryTypes[] = [
  {
    event: "Sale",
    item: "NFT",
    pool: "25 SAR",
    price: "1",
    date: "2024-06-15",
  },
  {
    event: "Bid",
    item: "Collection",
    pool: "25 SAR",
    price: "2",
    date: "2024-07-29",
  },
];
