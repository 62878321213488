import { Layout } from "src/components/common/layout";
import NftsDetailsCollectionsContainer from "src/containers/user/nfts-details-collections-container/nfts-details-collections-container";

function NftsDetailsCollectionsPage() {
  return (
    <Layout>
      <div>
        <NftsDetailsCollectionsContainer />
      </div>
    </Layout>
  );
}

export default NftsDetailsCollectionsPage;
