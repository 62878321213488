import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const HighchartsComponent: React.FC = () => {
  const options: Highcharts.Options = {
    chart: {
      height: 303,
      type: "area",
      backgroundColor: "#00398A",
      borderRadius: 10,
    },
    title: {
      text: "",
      style: {
        color: "#FFFFFF",
        fontFamily: "Arial, sans-serif",
        fontSize: "20px",
      },
    },
    subtitle: {
      text: "",
      style: {
        color: "#FFFFFF",
        fontFamily: "Arial, sans-serif",
        fontSize: "14px",
      },
    },
    xAxis: {
      categories: ["AAA", "BBB", "CCC", "DDD", "EEE"],
      title: {
        text: "",
        style: {
          color: "var(---800, rgba(255, 255, 255, 0.85))",
          fontFamily: "Arial, sans-serif",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          color: "var(---800, rgba(255, 255, 255, 0.85))",
          fontFamily: "openSans, sans-serif",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
        style: {
          color: "var(---800, rgba(255, 255, 255, 0.85))",
          fontFamily: "openSans sans-serif",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          color: "var(---800, rgba(255, 255, 255, 0.85))",
          fontFamily: "",
        },
      },
      gridLineWidth: 1,
      gridLineColor: "rgba(255, 255, 255, 0.15)",
      gridLineDashStyle: "ShortDash",
      plotLines: [
        {
          color: "var(---800, rgba(255, 255, 255, 0.85))",
          width: 2,
          value: 0,
        },
      ],
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 5,
          lineColor: "#50B848",
          lineWidth: 1.5,
          fillColor: "#FFFFFF",
        },
        allowPointSelect: false,
      },
    },
    series: [
      {
        type: "area",
        name: "",
        data: [220.9, 350.5, 116.4, 49.2, 274],
        color: "#50B848",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(80, 184, 72 , 0.2)"],
            [1, "rgba(80, 184, 72 , 0)"],
          ],
        },
      } as Highcharts.SeriesAreaOptions,
    ],
    legend: {
      itemStyle: {
        color: "#50B848",
        fontFamily: "Arial, sans-serif",
        fontSize: "0px",
        gridLineColor: "#50B848",
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HighchartsComponent;
