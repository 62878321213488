import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import DropdwonIdVerificationComponent from "src/components/user/drop-dwon-id-verification-component/drop-dwon-id-verification-component";
import PhotoIdComponent from "src/components/user/photo-id-component/photo-id-component";
import RadioButtonIdComponent from "src/components/user/radio-button-component/radio-button-component";

function IdVerificationContainer() {
  const navigate = useNavigate();
  const cities = [
    "Kingdom of Soudi Arabia",
    "Los Angeles",
    "Chicago",
    "San Francisco",
  ];
  const documentTypes = [
    "Government ID",
    "Passport",
    "Driver's License",
    "Residency Permit",
  ];
  const handlePage = () => {
    navigate(appRoutesObj.getBasePath());
  };

  return (
    <div className="bg-Primary-950 h-screen">
      <div className="w-[538px] mobile:w-full absolute mobile:left-0 -left-12 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
      <div className="items-start justify-start text-white p-4 relative">
        <img
          className="max-w-[175px] max-h-[67px]"
          src="/assets/images/Logo-White.png"
          alt=""
        />
      </div>
      <div className="flex justify-center px-36 items-center mobile:px-4 tablet:px-4 relative">
        <div className="rounded-lg z-30 bg-Primary-700 mb-12 w-full h-auto mobile:h-auto tablet:h-auto">
          <p className="flex justify-end items-end mobile:flex-col tablet:flex-col text-white p-5 mobile:p-2 tablet:p-2 underline cursor-pointer">
            Skip
          </p>

          <p className="text-center font-montserrat font-bold tracking-tight leading-loose text-white text-2xl">
            ID Verification
          </p>
          <div className="flex flex-col justify-center pt-[32px] items-center">
            <DropdwonIdVerificationComponent cities={cities} />
            <RadioButtonIdComponent documentTypes={documentTypes} />
            <PhotoIdComponent />
            <div className="flex gap-[24px] pt-4 mobile:gap-[15px] mb-4">
              <button className="w-[204px] mobile:w-[163px] h-[48px] border border-secondary-500 rounded-lg text-white font-openSans">
                Previous
              </button>
              <button
                onClick={handlePage}
                className="w-[204px] mobile:w-[163px] h-[48px] rounded-lg bg-secondary-500 text-white font-openSans"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-60 mobile:w-full absolute -bottom-0 mobile:-bottom-0 -right-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
    </div>
  );
}

export default IdVerificationContainer;
