import { useState } from "react";
import ExploreSportsCardComponents from "./explore-sports-card-components";

export function TabBar() {
  const [openTab, setOpenTab] = useState<number>(1);

  return (
    <div>
      <div className="flex flex-col items-center justify-center max-w-xl pt-7 tablet:pt-[50px]">
        <div className="mobile:w-[350px]">
          <ul className="flex gap-4 mobile:overflow-x-auto mobile:no-scrollbar">
            <li>
              <div
                className={`p-[2px] w-[67px] rounded-2xl bg-gradient-to-l from-[#50B848] to-[#0047AB]`}
              >
                <div className="flex items-center rounded-2xl justify-center w-full h-full bg-[#010e20] back">
                  <div
                    className={`rounded-2xl w-[67px] text-center ${
                      openTab === 1
                        ? "bg-gradient-to-l from-[#50B848]  to-[#0047AB] cursor-default "
                        : "cursor-pointer"
                    }`}
                  >
                    <div
                      onClick={() => setOpenTab(1)}
                      className={`inline-block px-4 py-2 text-white bg-[#0047AB1F] rounded-2xl shadow`}
                    >
                      All
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className={`p-[2px] w-full rounded-2xl bg-gradient-to-l from-[#50B848] to-[#0047AB]`}
              >
                <div className="flex items-center rounded-2xl justify-center w-full h-full bg-[#010e20] back">
                  <div
                    className={`rounded-2xl ${
                      openTab === 2
                        ? "bg-gradient-to-l from-[#50B848]  to-[#0047AB] cursor-default"
                        : "cursor-pointer"
                    } `}
                  >
                    <div
                      onClick={() => setOpenTab(2)}
                      className={`inline-block px-4 py-2 text-white bg-[#0047AB1F] rounded-2xl shadow`}
                    >
                      Football
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className={`p-[2px] w-full rounded-2xl bg-gradient-to-l from-[#50B848] to-[#0047AB]`}
              >
                <div className="flex items-center rounded-2xl justify-center w-full h-full bg-[#010e20] back">
                  <div
                    className={`rounded-2xl ${
                      openTab === 3
                        ? "bg-gradient-to-l from-[#50B848]  to-[#0047AB] cursor-default"
                        : "cursor-pointer"
                    } `}
                  >
                    <div
                      onClick={() => setOpenTab(3)}
                      className={`inline-block px-4 py-2 text-white bg-[#0047AB1F] rounded-2xl shadow`}
                    >
                      Basketball
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className={`p-[2px] w-full rounded-2xl bg-gradient-to-l from-[#50B848] to-[#0047AB]`}
              >
                <div className="flex items-center rounded-2xl justify-center w-full h-full bg-[#010e20] back">
                  <div
                    className={`rounded-2xl ${
                      openTab === 4
                        ? "bg-gradient-to-l from-[#50B848]  to-[#0047AB] cursor-default"
                        : "cursor-pointer"
                    } `}
                  >
                    <div
                      onClick={() => setOpenTab(4)}
                      className={`inline-block px-4 py-2 text-white bg-[#0047AB1F] rounded-2xl shadow`}
                    >
                      Cricket
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div
                className={`p-[2px] w-full rounded-2xl bg-gradient-to-l from-[#50B848] to-[#0047AB]`}
              >
                <div className="flex items-center rounded-2xl justify-center w-full h-full bg-[#010e20] back">
                  <div
                    className={`rounded-2xl ${
                      openTab === 5
                        ? "bg-gradient-to-l from-[#50B848]  to-[#0047AB] cursor-default"
                        : "cursor-pointer"
                    } `}
                  >
                    <div
                      onClick={() => setOpenTab(5)}
                      className={`inline-block px-4 py-2 text-white bg-[#0047AB1F] rounded-2xl shadow`}
                    >
                      Tennis
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="p-3 mt-6">
          <div className={openTab === 1 ? "block" : "hidden"}>
            <ExploreSportsCardComponents />
          </div>
          <div className={openTab === 2 ? "block" : "hidden"}>
            <ExploreSportsCardComponents />
          </div>
          <div className={openTab === 3 ? "block" : "hidden"}>
            <ExploreSportsCardComponents />
          </div>
          <div className={openTab === 4 ? "block" : "hidden"}>
            <ExploreSportsCardComponents />
          </div>
          <div className={openTab === 5 ? "block" : "hidden"}>
            <ExploreSportsCardComponents />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabBar;
