import * as yup from "yup";

export const WalletSchema = (balance: number) =>
  yup.object().shape({
    addAmount: yup
      .number()
      .required("Amount is required")
      .typeError("Amount price must be a number"),
    withdrawAmount: yup
      .number()
      .required("Amount is required")
      .max(
        balance,
        "The amount is greater than the amount available in the wallet"
      )
      .typeError("Amount price must be a number"),
  });
