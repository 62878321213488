import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { appRoutesObj } from "src/app.paths";
import EmptyStateComponent from "./portfolio-emtpy-state-component";
import { BiFilterAlt, BiSearch, BiSort } from "react-icons/bi";
import { options } from "src/core/statics/nfts-mock-data/nfts-mock-data";
import NoMatchingStateComponent from "./no-matching-state-component";
import { Item } from "src/core/types/user.type";

const filterOptions = [
  { label: "Listed", value: "listed" },
  { label: "On Auction", value: "onAuction" },
  { label: "New", value: "new" },
  { label: "Has Offers", value: "hasOffers" },
];
const sortOptions = [
  { label: "Recently received", value: "recentlyReceived" },
  { label: "Recently listed", value: "recentlyListed" },
  { label: "Price high to low", value: "priceHighToLow" },
  { label: "Price low to high", value: "priceLowToHigh" },
  { label: "Highest floor", value: "highestFloor" },
  { label: "Best offer", value: "bestOffer" },
];
function DealsComponent() {
  const [likedCards, setLikedCards] = useState<number[]>([]);
  const [likeCounts, setLikeCounts] = useState<{ [key: number]: number }>({});
  const [visibleSection, setVisibleSection] = useState<
    "none" | "filter" | "sort"
  >("none");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedSort, setSelectedSort] = useState<string>("");

  const handleFilterToggle = () => {
    setVisibleSection((prev) => (prev === "filter" ? "none" : "filter"));
  };

  const handleSortToggle = () => {
    setVisibleSection((prev) => (prev === "sort" ? "none" : "sort"));
  };
  const handleFilterChange = (value: string) => {
    setSelectedFilters((prevSelectedFilters) =>
      prevSelectedFilters.includes(value)
        ? prevSelectedFilters.filter((filter) => filter !== value)
        : [...prevSelectedFilters, value]
    );
  };

  const handleSortChange = (value: string) => {
    setSelectedSort(value);
  };
  const filteredOptions = options.map((option) => ({
    ...option,
    Item: option.Item
      ? option.Item.filter(
          (item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            selectedFilters.every((filter) => item.filters.includes(filter))
        )
      : [],
  }));
  const toggleLike = (e: React.MouseEvent, cardId: number) => {
    e.stopPropagation();
    e.preventDefault();
    setLikedCards((prevLikedCards) => {
      if (prevLikedCards.includes(cardId)) {
        return prevLikedCards.filter((id) => id !== cardId);
      } else {
        return [...prevLikedCards, cardId];
      }
    });

    setLikeCounts((prevLikeCounts) => ({
      ...prevLikeCounts,
      [cardId]:
        (prevLikeCounts[cardId] || 0) + (likedCards.includes(cardId) ? -1 : 1),
    }));
  };
  const sortItems = (items: Item[]) => {
    switch (selectedSort) {
      case "priceHighToLow":
        return [...items].sort((a, b) => b.price - a.price);
      case "priceLowToHigh":
        return [...items].sort((a, b) => a.price - b.price);
      case "recentlyReceived":
        return [...items].sort(
          (a, b) =>
            new Date(b.dateReceived).getTime() -
            new Date(a.dateReceived).getTime()
        );
      case "recentlyListed":
        return [...items].sort(
          (a, b) =>
            new Date(b.dateListed).getTime() - new Date(a.dateListed).getTime()
        );

      default:
        return items;
    }
  };
  const sortedAndFilteredItems = filteredOptions[0].Item
    ? sortItems(filteredOptions[0].Item)
    : [];
  const hasItems = sortedAndFilteredItems.length > 0;
  return (
    <div>
      {options[0].Item!.length === 0 ? (
        <div className="flex flex-col items-center">
          <EmptyStateComponent />
        </div>
      ) : (
        <div className="flex flex-col gap-14">
          {/* desktop laptop */}
          <div className="h-12 justify-start items-center gap-6 inline-flex mobile:hidden">
            <div className="justify-start items-center gap-2 flex">
              <div className="flex flex-col gap-2 relative">
                <button
                  aria-label="Filter items"
                  onClick={handleFilterToggle}
                  className="bg-Primary-500 rounded-lg flex gap-2 items-center justify-center text-white text-base w-[134px] h-12"
                >
                  {<BiFilterAlt className="w-3 h-3" />}Filter
                </button>
                {visibleSection === "filter" && (
                  <div className="absolute top-14 z-50">
                    {filterOptions.map((option) => (
                      <div className="px-4 h-11 flex flex-col w-[341px] bg-Primary-500">
                        <div
                          key={option.value}
                          className="grow shrink basis-0 h-[18px] justify-start items-center gap-1 flex"
                        >
                          <input
                            type="checkbox"
                            checked={selectedFilters.includes(option.value)}
                            onChange={() => handleFilterChange(option.value)}
                          />
                          <div className="grow shrink basis-0 h-[18px] flex-col justify-center items-end inline-flex">
                            <div className="self-stretch text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                              {option.label}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-[134px] h-12 justify-start items-start flex">
                <div className="flex flex-col gap-2 relative">
                  <button
                    aria-label="Sort items"
                    onClick={handleSortToggle}
                    className="bg-Primary-500 rounded-lg flex gap-2 items-center justify-center text-white text-base w-[134px] h-12"
                  >
                    {<BiSort className="w-3 h-3" />}Sort
                  </button>
                  {visibleSection === "sort" && (
                    <div className="absolute top-14 z-50">
                      {sortOptions.map((option) => (
                        <div className="  px-4 h-11 flex flex-col w-[341px] bg-Primary-500">
                          <div
                            key={option.value}
                            className="grow shrink basis-0 h-[18px] justify-start items-center gap-1 flex"
                          >
                            <input
                              type="radio"
                              checked={selectedSort === option.value}
                              onChange={() => handleSortChange(option.value)}
                            />
                            <div className="grow shrink basis-0 h-[18px] flex-col justify-center items-end inline-flex">
                              <div className="self-stretch text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                                {option.label}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="relative w-[489px] flex-col justify-center items-center gap-2 inline-flex">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`bg-[#7373731A] pl-8 pr-2 py-3 placeholder:text-neutral-400
           placeholder:text-[13px] placeholder:font-openSans
          text-white h-11 w-[489px] tablet:w-[260px] mobile:w-[310px] rounded text-base
            disabled:bg-muted disabled:border-muted dark:bg-dark-700
            focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20
             `}
                placeholder="Search"
              ></input>
              <BiSearch className="w-5 h-5 text-neutral-400 absolute left-2" />
            </div>
          </div>
          {/* Mobile */}
          <div className="flex flex-col gap-6 justify-center items-center px-6 desktop:hidden largeDesktop:hidden laptop:hidden largeLaptop:hidden tablet:hidden">
            <div className="relative flex-col justify-center items-center gap-2 flex">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`bg-[#7373731A] pl-8 pr-2 py-3 placeholder:text-neutral-400
           placeholder:text-[13px] placeholder:font-openSans
          text-white h-11 w-[489px] mobile:w-[310px] rounded text-base
            disabled:bg-muted disabled:border-muted dark:bg-dark-700
            focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20
             `}
                placeholder="Search"
              ></input>
              <BiSearch className="w-5 h-5 text-neutral-400 absolute left-2" />
            </div>
            <div className="justify-center items-center gap-2 flex">
              <div className="flex flex-col gap-2 relative">
                <button
                  aria-label="Filter items"
                  onClick={handleFilterToggle}
                  className="bg-Primary-500 rounded-lg flex gap-2 items-center justify-center text-white text-base w-[134px] h-12"
                >
                  {<BiFilterAlt className="w-3 h-3" />}Filter
                </button>
                {visibleSection === "filter" && (
                  <div className="absolute top-14 z-50">
                    {filterOptions.map((option) => (
                      <div className="px-4 h-11 flex flex-col w-[272px] bg-Primary-500">
                        <div
                          key={option.value}
                          className="grow shrink basis-0 h-[18px] justify-start items-center gap-1 flex"
                        >
                          <input
                            type="checkbox"
                            checked={selectedFilters.includes(option.value)}
                            onChange={() => handleFilterChange(option.value)}
                          />
                          <div className="grow shrink basis-0 h-[18px] flex-col justify-center items-end inline-flex">
                            <div className="self-stretch text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                              {option.label}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {visibleSection === "sort" && (
                  <div className="absolute top-14 z-50">
                    {sortOptions.map((option) => (
                      <div className="px-4 h-11 flex flex-col w-[272px] bg-Primary-500">
                        <div
                          key={option.value}
                          className="grow shrink basis-0 h-[18px] justify-start items-center gap-1 flex"
                        >
                          <input
                            type="radio"
                            checked={selectedSort === option.value}
                            onChange={() => handleSortChange(option.value)}
                          />
                          <div className="grow shrink basis-0 h-[18px] flex-col justify-center items-end inline-flex">
                            <div className="self-stretch text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                              {option.label}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-[134px] h-12 justify-start items-start flex">
                <div className="flex flex-col gap-2 relative">
                  <button
                    aria-label="Sort items"
                    onClick={handleSortToggle}
                    className="bg-Primary-500 rounded-lg flex gap-2 items-center justify-center text-white text-base w-[134px] h-12"
                  >
                    {<BiSort className="w-3 h-3" />}Sort
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            {hasItems ? (
              <div className="grid largeLaptop:grid-cols-3 gap-6 mobile:gap-2 desktop:grid-cols-3 grid-cols-2 mobile:grid-cols-2 tablet:grid-rows-3">
                {sortedAndFilteredItems.map((item) => (
                  <div
                    key={item.id}
                    className="w-[330px] h-[388px] tablet:w-[270px] mobile:w-[175px] mobile:h-[271px] bg-[#002A66] flex flex-col pt-[16px] mobile:pt-2 items-center rounded-lg mobile:opacity-100"
                  >
                    <NavLink
                      to={appRoutesObj.getNftsItemDetailsUrl(
                        item.id.toString()
                      )}
                    >
                      <div className="cursor-pointer">
                        <div className="flex justify-end">
                          <div
                            onClick={(e) => {
                              toggleLike(e, item.id);
                            }}
                            className="flex justify-between font-montserrat mobile:w-auto items-center px-2 text-[11px] mr-2 mt-2 absolute w-[65px] h-7 bg-blue-800 bg-opacity-20 rounded-lg backdrop-blur-[5px]"
                          >
                            <span className="mobile:hidden text-white">
                              {likeCounts[item.id] || 0}
                            </span>
                            {likedCards.includes(item.id) ? (
                              <FaHeart size={20} className="text-red-500" />
                            ) : (
                              <FaRegHeart className="text-white" size={20} />
                            )}
                          </div>
                          <img
                            src={item.img}
                            alt={item.name}
                            className="object-cover rounded-lg mobile:w-[159px] mobile:h-[198px] w-[298px] tablet:w-[220px] h-[286px]"
                          />
                        </div>
                        <div className="flex flex-col justify-between w-full pt-4 gap-3 mobile:gap-2 mobile:pt-0">
                          <div className="flex flex-row justify-between mobile:pt-4 w-full">
                            <p className="font-montserrat font-bold text-[19px] mobile:text-[13px] text-white">
                              {item.name}
                            </p>
                            <p className="font-montserrat font-bold text-[19px] mobile:text-[13px] text-white">
                              {item.price} SAR
                            </p>
                          </div>
                          <p className="text-[13px] mobile:text-[11px] opacity-50 text-[#D4D4D8]">
                            {item.username}
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            ) : (
              <NoMatchingStateComponent />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DealsComponent;
