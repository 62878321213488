import { GoArrowUpRight } from "react-icons/go";
import CountUp from "react-countup";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
function HeroSectionComponents() {
  return (
    <div className="z-20 mobile:bg-gradient-to-b tablet:bg-gradient-to-b mobile:from-Primary-950 tablet:from-Primary-950 moblie:to-Primary-950 tablet:to-Primary-950">
      <div className="flex justify-around w-[90%] laptop:w-[100%] pt-[100px] mobile:pt-10 mobile:flex-col tablet:flex-col tablet:justify-center tablet:items-center tablet:text-center mobile:w-full">
        <div className="z-10 tablet:flex tablet:flex-col tablet:justify-center tablet:items-center mobile:justify-center mobile:items-center">
          <p className="w-[626px] font-SemiBold font-bold mobile:pb-2 text-[80px] mobile:w-[358px] mobile:text-[45px] text-white leading-[80px] mobile:leading-[55px] mobile:px-[20px]">
            Discover Collect & Sell Extraordinary
          </p>
          <span className="font-Bold700 font-bold text-transparent text-8xl bg-clip-text bg-gradient-to-l to-[#0064F0] from-[#72C66C] mobile:text-[55px] mobile:px-[20px]">
            NFTs
          </span>
          <div className="pt-[40px] mobile:pt-[55px]">
            <div className="flex gap-2 tablet:mx-[54px] mobile:gap-4 mobile:items-center mobile:justify-center mobile:w-full tablet:w-full tablet:items-center tablet:justify-center">
              <NavLink
                className="w-[189px] h-[48px] mobile:w-[171px] rounded-lg border border-[#50B848] flex items-center text-white justify-center gap-2"
                to={appRoutesObj.getNftsPagePath()}
              >
                Explore More <GoArrowUpRight size={16} />{" "}
              </NavLink>
              <button
                onClick={() => {}}
                className="w-[189px] h-[48px] mobile:w-[171px] rounded-lg bg-[#0047AB] text-center flex items-center text-white justify-center"
              >
                Get your first NFT
              </button>
            </div>
          </div>
        </div>
        <div className="relative mx-[150px] rtl:mobile:w-full rtl:mobile:mx-20 mobile:mx-[140px] mobile:my-[80px] tablet:my-[80px] mobile:px-8">
          <div className="absolute skew-x-6 mobile:mx-[-80px] laptop:mx-[-120px] tablet:mx-[-80px] card-container perspective-1000 ">
            <div className="transition-transform duration-1000 ease-in-out card transform-style-preserve-3d ">
              <div className="w-full h-full transition-transform duration-1000 ease-in-out card-inner transform-style-preserve-3d ">
                <div className="w-full h-full card-face backface-hidden">
                  <img
                    src="assets/images/Property1.png"
                    alt=""
                    className="w-full h-full bg-gradient-to-r rounded-xl from-Primary-950 to-Primary-800"
                  />
                </div>
                <div className="absolute w-full h-full transform card-face backface-hidden rotate-y-180">
                  <img
                    src="assets/images/front.png"
                    alt=""
                    className="w-full h-full bg-gradient-to-r rounded-xl from-Primary-950 to-Primary-800"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute skew-y-12 ml-[-145px] rtl:mr-20 rtl:mobile:mr-0  mobile:mx-[-140px] laptop:mx-[-160px] mt-[110px] card-container perspective-1000">
            <div className="transition-transform duration-1000 ease-in-out card transform-style-preserve-3d">
              <div className="w-full h-full transition-transform duration-1000 ease-in-out card-inner transform-style-preserve-3d">
                <div className="absolute w-full h-full card-face backface-hidden">
                  <img
                    src="assets/images/front.png"
                    alt=""
                    className="w-full h-full bg-gradient-to-r rounded-xl from-Primary-950 to-Primary-800"
                  />
                </div>
                <div className="absolute w-full h-full transform card-face backface-hidden rotate-y-180">
                  <img
                    src="assets/images/Property1.png"
                    alt=""
                    className="w-full h-full bg-gradient-to-r rounded-xl from-Primary-950 to-Primary-800"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center pt-[350px] gap-[80px] mobile:mt-[330px] tablet:mt-[350px] mobile:flex-col">
        <div className="w-40 mobile:w-full absolute -right-0 rtl:left-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
        <div className="flex flex-col items-center z-20">
          <p className="text-white text-[48px]">
            <CountUp start={0} end={250} duration={3} suffix="K" />
          </p>
          <p className="text-[#CECCD6] text-[19px]">collections</p>
        </div>
        <div className="bg-gradient-to-t from-green-300 z-20 via-blue-500 to-[#2584C5] h-[102px] w-[2px] mobile:rotate-90"></div>
        <div className="flex flex-col items-center z-20">
          <p className="text-white text-[48px]">
            <CountUp start={0} end={250} duration={3} suffix="K" />
          </p>
          <p className="text-[#CECCD6] text-[19px]">Artworks</p>
        </div>
        <div className="bg-gradient-to-t z-20 from-green-300 via-blue-500 to-[#2584C5] h-[102px] w-[2px] mobile:rotate-90"></div>
        <div className="flex flex-col items-center z-20">
          <p className="text-white text-[48px]">
            <CountUp start={0} end={250} duration={3} suffix="K" />
          </p>
          <p className="text-[#CECCD6] text-[19px]">Teams</p>
        </div>
      </div>
    </div>
  );
}
export default HeroSectionComponents;
