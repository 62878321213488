import { DirectionEnum } from "src/core/enums/design-system.enum";
export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
}
export function InputFiled({
  hint,
  isValid,
  ...props
}: InputFieldProps): JSX.Element {
  const getValidationPaddingClass = () => {
    switch (isValid) {
      default:
        return "px-2";
    }
  };
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500  outline-none";
      default:
        return "";
    }
  };

  const getHintClass = () => {
    switch (isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500";
      default:
        return "text-muted";
    }
  };
  return (
    <div dir={props.dir} className="w-full mb-4 relative">
      {props["aria-label"] && (
        <label className="block px-2 mobile:px-7 mb-2 text-base text-white font-openSans">
          {props["aria-label"]}
        </label>
      )}
      <div className="relative mobile:px-6">
        <input
          {...props}
          className={`bg-[#7373731A] placeholder:text-neutral-400
           placeholder:text-[13px] placeholder:font-openSans
          text-white h-11 w-[385px] mobile:w-[342px] rounded text-base
            ${getValidationClass()}
            disabled:bg-muted disabled:border-muted dark:bg-dark-700
            focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 ${
              props.className ?? ""
            }${getValidationPaddingClass()}`}
        />
        <div
          className={`absolute top-5 flex items-center justify-center space-x-1 ${
            props.dir === DirectionEnum.RTL ? "left-3" : "right-3"
          }`}
        >
          {props.type !== "date"}
        </div>
      </div>
      {hint && (
        <p
          className={`w-[369px] mobile:px-8 pt-1 text-[13px] px-2 ${getHintClass()}`}
        >
          {hint}
        </p>
      )}
    </div>
  );
}

export default InputFiled;
