import { Layout } from "src/components/common/layout";
import ExploreSportsComponents from "src/components/user/explore-sports-components";
import HeroSectionComponents from "src/components/user/herosection-components";
import OurCommunityComponents from "src/components/user/our-community-components";
import OurServicesComponents from "src/components/user/our-services-components";
import PopularCollectionsComponents from "src/components/user/popular-collections-components";
import PopularItemsComponents from "src/components/user/popular-items-components";
import CommunityPoolsLandingContainer from "../community-pools-landing-container/community-pools-landing-container";

function LandingContainer() {
  return (
    <Layout>
      <div>
        <HeroSectionComponents />
        <OurServicesComponents />
        <ExploreSportsComponents />
        <PopularCollectionsComponents />
        <PopularItemsComponents />
        <CommunityPoolsLandingContainer />
        <OurCommunityComponents />
      </div>
    </Layout>
  );
}

export default LandingContainer;
