import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MouseEventHandler } from "react";
import { BsCaretDownFill } from "react-icons/bs";
import { IRoutesTitle } from "./layout-components/nav-bar-componentes";

export interface IMapRoutes {
  title: string;
  routes: IRoutesTitle[];
  isActive: boolean;
  onToggle: MouseEventHandler<HTMLUListElement>;
}

const scroll = (div_id: string) => {
  const element = document.getElementById(div_id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const DropDownButton: React.FC<IMapRoutes> = (props) => {
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex justify-center cursor-pointer">
      <div className="relative" data-te-dropdown-ref>
        <div
          className={`flex items-center gap-1 font-openSans ${
            props.isActive ? "text-green-400" : "text-white"
          } ${
            i18n.language === "ar"
              ? "font-ar text-base mobile:text-[10px] tablet:text-base laptop:text-[14px]"
              : "font-en text-base largeLaptop:text-[16px] laptop:text-[14px] tablet:text-xs"
          } `}
          id="dropdownMenuButton2"
          data-te-dropdown-toggle-ref
          aria-expanded={isDropdownOpen}
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={handleToggleDropdown}
        >
          {props.title}
          <BsCaretDownFill />
        </div>
        {isDropdownOpen && (
          <ul
            onClick={props.onToggle}
            className={`absolute z-10 mt-2 w-full whitespace-nowrap overflow-hidden rounded-b-lg border-none cursor-pointer ${
              i18n.language === "ar" ? "text-right" : "text-left"
            } bg-Primary-500 ${
              i18n.language === "ar" ? "font-arabic_normal" : "font-en"
            } ${
              i18n.language === "ar"
                ? "font-arabic_normal text-[20px] mobile:text-[10px] tablet:text-base"
                : "font-en desktop:text-[16px] largeLaptop:text-[16px] laptop:text-[16px] tablet:text-xs"
            } `}
            aria-labelledby="dropdownMenuButton2"
            data-te-dropdown-menu-ref
          >
            {props.routes.map((item, index) => (
              <li key={index}>
                <NavLink
                  className={`block w-full px-2 py-2 ${
                    index === 0 && "border-b border-opacity-10 border-white"
                  } ${
                    props.isActive ? "text-green-400" : "text-white"
                  } font-openSans`}
                  to={item.routePath}
                  onClick={() => scroll(props.title)}
                >
                  {item.routeTitle}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropDownButton;
