import { t } from "i18next";
import * as Yup from "yup";

const getPasswordRegex = () => /^(?=.*[A-Z])(?=.*\d)/;
export const validationSchemaSignUp = () =>
  Yup.object({
    username: Yup.string()
      .trim()
      .min(5, t("Please enter your username", { number: 3 }).toString())
      .required(t("Please enter your username").toString()),
    email: Yup.string()
      .trim()
      .min(3)
      .email(t("Please enter a valid email, halamint@example.com").toString())
      .required(t("Please enter your email").toString()),
    password: Yup.string()
      .min(8)
      .matches(
        getPasswordRegex(),
        "Please choose a strong password that includes at least 1 lowercase and uppercase letter, a number, as well as a special character (!@#$%^&*)"
      )
      .trim()
      .required(t("Please enter your Password").toString()),
    password2: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        "The password you entered does not match"
      )
      .min(8)
      .trim()
      .required(t("Please enter your Password").toString()),
    checkBox: Yup.boolean()
      .oneOf([true], "Checkbox must be checked")
      .required(t("ffffffff").toString()),
  });
