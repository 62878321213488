import LandingContainer from "src/containers/user/landing-page/landing-container";

function LandingPage() {
  return (
    <>
      <div className="">
        <LandingContainer />
      </div>
    </>
  );
}

export default LandingPage;
