import React from "react";
import { Pools } from "src/core/types/user.type"; // Adjust the import path as necessary

interface PoolsTableComponentProps {
  pools: Pools[];
  onJoin?: () => void;
}

const PoolsTableComponent: React.FC<PoolsTableComponentProps> = ({
  pools,
  onJoin,
}) => {
  return (
    <div className="overflow-x-auto mt-20">
      <table className="min-w-full mobile:w-[900px] tablet:w-[900px] bg-Primary-700 text-left rounded-lg mb-5">
        <thead>
          <tr>
            <th className="flex justify-between text-white font-openSans font-normal text-[13px] px-6 mobile:px-10 mb-2">
              <p className="py-2 px-12">Pool Name</p>
              <p className="py-2 px-0 mr-8">ROI Rate</p>
              <p className="py-2 px-0 ml-4">Members</p>
              <p className="py-2 px-6">Entrance Fees</p>
              <p className="py-2 px-5"></p>
            </th>
          </tr>
        </thead>
        <tbody>
          {pools.map((pool, index) => (
            <div className="mobile:px-4">
              <tr
                key={index}
                className="bg-Primary-700 flex text-center items-center text-white text-[13px] font-openSans justify-between even:bg-[#0047AB] even:bg-opacity-10 border-t border-opacity-10 border-white"
              >
                <td className="py-2 gap-4 px-4 flex items-center">
                  <img
                    src={pool.img}
                    alt="icon"
                    className="w-10 h-10 rounded"
                  />
                  {pool.name}
                </td>
                <td className="py-2 px-6">{pool.roiRate}</td>
                <td className="py-2 px-2">{pool.members} Members</td>
                <td className="py-2">{pool.entranceFee}</td>
                <td className="py-2 px-4">
                  <button
                    onClick={onJoin}
                    className="bg-green-500 w-[60px] h-8 text-white py-1 rounded-lg"
                  >
                    Join
                  </button>
                </td>
              </tr>
            </div>
          ))}
        </tbody>
        <div className="mobile:px-4">
          <hr className="opacity-10" />
        </div>
      </table>
    </div>
  );
};

export default PoolsTableComponent;
