import { TbCheck } from "react-icons/tb";
export interface CheckboxProps {
  label: string;
  name?: string;
  value?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  hint?: string;
}

const Checkbox = ({
  label,
  name,
  value,
  checked,
  onChange,
  ...props
}: CheckboxProps) => {
  const getValidationPaddingClass = () => {
    switch (props.isValid) {
      default:
        return "px-2";
    }
  };
  const getValidationClass = () => {
    switch (props.isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500  outline-none";
      default:
        return "";
    }
  };

  return (
    <label className="flex items-center text-white">
      <input
        {...props}
        id="checked-checkbox"
        type="checkbox"
        value={value}
        className={`absolute w-[18px] ${getValidationClass()} ${getValidationPaddingClass()} h-[18px] border checked:border-none  rounded-[5px] appearance-none checked:bg-secondary-500 checked:animate-fade checked:animate-duration-[300ms] checked:animate-ease-linear focus:ring-transparent`}
        checked={checked}
        onChange={onChange}
      />
      {checked ? (
        <TbCheck
          className="relative ml-[0.5px] rtl:mr-[0.9px] text-white text-center"
          size={16}
        />
      ) : (
        <div className="w-[16.5px] h-[14px] rounded-[5px]"></div>
      )}
    </label>
  );
};
export default Checkbox;
