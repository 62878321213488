import { t } from "i18next";
import * as Yup from "yup";

export const validationSchemaSetProfile = () =>
  Yup.object({
    firstname: Yup.string()
      .trim()
      .required(t("Please enter your first name").toString()),
    lastname: Yup.string()
      .trim()
      .required(t("Please enter your last name").toString()),
    mobilenum: Yup.string()
      .min(9, "Please enter your mobile number")
      .trim()
      .required(t("Please enter your mobile number").toString()),
    city: Yup.string().trim().required(t("Please choose your city").toString()),
    fulladdress: Yup.string()
      .trim()
      .required(t("Please enter your full address").toString()),
    postalcode: Yup.string()
      .trim()
      .required(t("Please enter your postal code").toString()),
    dateOfBirth: Yup.date()
      .nullable()
      .required(t("Please enter your date of birth").toString()),
  });
