import React from "react";
import { DirectionEnum } from "src/core/enums/design-system.enum";
import lists from "../../../core/statics/lists.json";

export interface MobileFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  handleMobileCodeChange?: (code: string) => void;
  selectedCode?: string;
  fixedPrefix?: string;
  error?: string;
  isValid?: boolean;
}
export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
}
function MobileFieldInput({
  handleMobileCodeChange,
  selectedCode,
  fixedPrefix,
  error,
  ...props
}: MobileFieldProps): JSX.Element {
  return (
    <div>
      <div className="flex relative gap-2">
        <div className="flex h-11">
          {fixedPrefix ? (
            <div className="flex h-11 w-[72px] items-center justify-center text-center text-[13px]">
              {fixedPrefix}
            </div>
          ) : (
            <div>
              <select
                title="phone_codes"
                dir={DirectionEnum.LTR}
                className="h-11 w-[72px] text-[13px] px-2 bg-[#7373731A] justify-center items-center rounded flex text-white focus:outline-none focus:border focus:border-Primary-500"
                onChange={(e) => {
                  if (handleMobileCodeChange) {
                    handleMobileCodeChange(e.target.value);
                  }
                }}
                value={selectedCode}
              >
                {lists.phone_codes.map((code: string, index: number) => {
                  return (
                    <option
                      className="text-[13px]"
                      value={code}
                      key={`code-${index}`}
                    >
                      {code}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
      </div>
      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  );
}

export default MobileFieldInput;
