import InputFiledProfileComponent from "src/components/user/Input-filed-profile/Input-filed-profile";
import CityDropDwonComponent from "src/components/user/city-drop-dwon/city-drop-dwon-component";
import MobileFieldInput from "src/components/user/drop-dwon-cuntry/drop-dwon-cuntry-component";
import UploadComponent from "src/components/user/upload-component";
import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate } from "react-router-dom";
import { Form, Formik, useFormik } from "formik";
import { appRoutesObj } from "src/app.paths";
import { initialValues } from "./setProfile-containers.data";
import { validationSchemaSetProfile } from "./setProfile-containers.validation";
import FormButton from "src/components/user/button-form";
import { getHintText } from "src/core/helpers/example.helper";
import { useState } from "react";

function SetProfileContainer() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [uploadedImage, setUploadedImage] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<any>(null);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      navigate(appRoutesObj.getIdVerificationPath());
    },
    validationSchema: validationSchemaSetProfile,
  });
  const handleImageChange = (imageUrl: string) => {
    setUploadedImage(imageUrl);
  };
  const handleDateChange = (newDate: any) => {
    const date = new Date(newDate?.startDate); // Extract and convert startDate to Date object
    if (!isNaN(date.getTime())) {
      formik.setFieldValue("dateOfBirth", date);
      setDateOfBirth(newDate); // Pass valid Date to Formik
    }
  };
  const cities = ["New York", "Los Angeles", "Chicago", "San Francisco"];
  return (
    <div className="bg-Primary-950 h-auto mobile:h-[1951px] tablet:h-[1951px]">
      <div className="w-[538px] mobile:w-full absolute mobile:left-0 -left-12 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
      <div className="items-start justify-start text-white p-4 relative">
        <img
          className="max-w-[175px] max-h-[67px]"
          src="/assets/images/Logo-White.png"
          alt=""
        />
      </div>
      <div className="flex justify-center px-36 items-center mobile:px-4 tablet:px-4 relative">
        <div className="rounded-lg z-30 bg-Primary-700 mb-12 w-full h-auto mobile:h-auto tablet:h-auto">
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            enableReinitialize
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <p
                onClick={() => navigate(appRoutesObj.getBasePath())}
                className="flex justify-end items-end mobile:flex-col tablet:flex-col text-white p-5 mobile:p-2 tablet:p-2 underline cursor-pointer"
              >
                Skip
              </p>

              <p className="text-center font-montserrat font-bold tracking-tight leading-loose text-white text-2xl">
                Set Profile
              </p>
              <div className="flex mobile:flex-col mobile:px-0 tablet:px-0 tablet:items-center tablet:justify-center mobile:items-center mobile:justify-center tablet:flex-col justify-evenly px-20 laptop:px-1 mobile:pt-6 tablet:pt-6">
                <div className="flex flex-col mobile:w-[342px] gap-4 mobile:gap-2 tablet:gap-2">
                  <p className="text-white font-openSans">
                    Your Profile Picture
                  </p>
                  <UploadComponent
                    className={""}
                    image={uploadedImage}
                    name="profileImage"
                    id="profileImage"
                    formik={undefined}
                    onClick={() => {}}
                    onChange={handleImageChange}
                    type="file"
                  />
                </div>
                <div className="flex flex-col gap-4 mobile:pt-[78px] tablet:pt-[78px]">
                  <p className="text-[19px] font-openSans text-white">
                    Personal information
                  </p>
                  <div className="flex mobile:flex-col tablet:flex-col gap-[16px]">
                    <InputFiledProfileComponent
                      name="firstname"
                      onChange={(e) => formik.handleChange(e)}
                      isValid={
                        formik.touched.firstname &&
                        Boolean(!formik.errors.firstname)
                      }
                      hint={getHintText(
                        formik.errors.firstname,
                        formik.touched.firstname,
                        formik.submitCount
                      )}
                      aria-label="First Name"
                      label="text-white"
                      className="w-[274px] bg-[#7373731A] placeholder:text-neutral-400
                  placeholder:text-[13px] placeholder:font-openSans
                 text-white h-11 px-2 mobile:w-[342px] tablet:w-[342px] rounded text-base focus:outline-none focus:border focus:border-Primary-500"
                      placeholder="Enter your first name"
                    />
                    <InputFiledProfileComponent
                      name="lastname"
                      onChange={(e) => formik.handleChange(e)}
                      isValid={
                        formik.touched.lastname &&
                        Boolean(!formik.errors.lastname)
                      }
                      hint={getHintText(
                        formik.errors.lastname,
                        formik.touched.lastname,
                        formik.submitCount
                      )}
                      aria-label="Last Name"
                      label="text-white"
                      className="w-[274px] bg-[#7373731A] placeholder:text-neutral-400
                  placeholder:text-[13px] placeholder:font-openSans
                 text-white h-11 px-2 mobile:w-[342px] tablet:w-[342px] rounded text-base focus:outline-none focus:border focus:border-Primary-500"
                      placeholder="Enter your last name"
                    />
                  </div>
                  <InputFiledProfileComponent
                    disabled
                    label="text-neutral-400"
                    aria-label="Username"
                    className="w-[564px] bg-[#7373731A] placeholder:text-neutral-400
                  placeholder:text-[13px] placeholder:font-openSans
                text-neutral-400 h-11 px-2 mobile:w-[342px] tablet:w-[342px] rounded text-base focus:outline-none focus:border focus:border-Primary-500"
                    placeholder="@Halamint"
                  />
                  <InputFiledProfileComponent
                    disabled
                    aria-label="Email"
                    label="text-neutral-400"
                    className="w-[564px] bg-[#7373731A] placeholder:text-neutral-400
                  placeholder:text-[13px] placeholder:font-openSans
                 text-neutral-400 h-11 px-2 mobile:w-[342px] tablet:w-[342px] rounded text-base focus:outline-none focus:border focus:border-Primary-500"
                    placeholder="Halamint@example.com"
                  />
                  <p className="text-white font-openSans px-2">
                    Mobile number{" "}
                  </p>

                  <div className="flex gap-2">
                    <MobileFieldInput />
                    <InputFiledProfileComponent
                      type="number"
                      name="mobilenum"
                      onChange={(e) => formik.handleChange(e)}
                      isValid={
                        formik.touched.mobilenum &&
                        Boolean(!formik.errors.mobilenum)
                      }
                      hint={getHintText(
                        formik.errors.mobilenum,
                        formik.touched.mobilenum,
                        formik.submitCount
                      )}
                      label="text-white"
                      className="w-[484px] bg-[#7373731A] placeholder:text-neutral-400 placeholder:text-[13px] placeholder:font-openSans
           text-white h-11 px-2 mobile:w-[262px] tablet:w-[262px] rounded text-base focus:outline-none focus:border focus:border-Primary-500"
                      placeholder="000 000 000"
                    />
                  </div>
                  <p className="text-white placeholder:text-neutral-400 px-2">
                    Date of birth{" "}
                  </p>
                  <div className="flex flex-col gap-1">
                    <Datepicker
                      useRange={false}
                      asSingle={true}
                      onChange={handleDateChange}
                      showShortcuts={false}
                      showFooter={false}
                      placeholder="Enter your Date of birth"
                      inputClassName={`bg-[#7373731A] w-[564px] mobile:w-[342px] tablet:w-[342px] h-11 rounded px-2 text-[13px] ${
                        formik.errors.dateOfBirth && formik.touched.dateOfBirth
                          ? "border border-red-500 outline-none"
                          : ""
                      } text-white font-openSnas focus:outline-none focus:border focus:border-Primary-500`}
                      value={dateOfBirth}
                    />
                    {formik.errors.dateOfBirth &&
                      formik.touched.dateOfBirth && (
                        <p className="text-red-500 text-[13px]">
                          {formik.errors.dateOfBirth}
                        </p>
                      )}
                  </div>
                  <p className="text-white font-openSans text-[19px]">
                    Address information
                  </p>
                  <div className="relative">
                    <CityDropDwonComponent
                      formik={formik}
                      name="city"
                      onChange={(e) => formik.handleChange(e)}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      isValid={
                        formik.touched.city &&
                        Boolean(!formik.errors.city) &&
                        searchTerm !== ""
                      }
                      hint={getHintText(
                        formik.errors.city,
                        formik.touched.city,
                        formik.submitCount
                      )}
                      cities={cities}
                    />
                  </div>
                  <InputFiledProfileComponent
                    name="fulladdress"
                    onChange={(e) => formik.handleChange(e)}
                    isValid={
                      formik.touched.fulladdress &&
                      Boolean(!formik.errors.fulladdress)
                    }
                    hint={getHintText(
                      formik.errors.fulladdress,
                      formik.touched.fulladdress,
                      formik.submitCount
                    )}
                    aria-label="Full Address"
                    label="text-white"
                    className="w-[564px] bg-[#7373731A] placeholder:text-neutral-400
                  placeholder:text-[13px] placeholder:font-openSans
                 text-white h-11 px-2 mobile:w-[342px] tablet:w-[342px] rounded text-base focus:outline-none focus:border focus:border-Primary-500"
                    placeholder="Enter your full address"
                  />
                  <InputFiledProfileComponent
                    name="postalcode"
                    onChange={(e) => formik.handleChange(e)}
                    isValid={
                      formik.touched.postalcode &&
                      Boolean(!formik.errors.postalcode)
                    }
                    hint={getHintText(
                      formik.errors.postalcode,
                      formik.touched.postalcode,
                      formik.submitCount
                    )}
                    type="number"
                    aria-label="Postal Code"
                    label="text-white"
                    className="w-[564px] bg-[#7373731A] placeholder:text-neutral-400
                  placeholder:text-[13px] placeholder:font-openSans
                 text-white h-11 px-2 mobile:w-[342px] tablet:w-[342px] rounded text-base focus:outline-none focus:border focus:border-Primary-500"
                    placeholder="Enter your postal code"
                  />
                  <div className="flex justify-end items-end mobile:items-center mobile:justify-center mb-4">
                    <FormButton
                      textButton="Next"
                      withd="w-[204px] mobile:w-[326px]"
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="w-60 mobile:w-full absolute -bottom-32 mobile:-bottom-0 -right-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
    </div>
  );
}

export default SetProfileContainer;
