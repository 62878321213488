import React, { useState } from "react";

interface ToggleListProps {
  onToggle: (itemName: string) => void;
}

const ToggleList: React.FC<ToggleListProps> = ({ onToggle }) => {
  const [selectedItem, setSelectedItem] = useState<string>("Offers");
  const handleToggle = (itemName: string) => {
    setSelectedItem(itemName);
    onToggle(itemName);
  };

  return (
    <div className=" flex flex-col gap-1 px-6 mt-10">
      <div className="flex gap-4 text-[19px] font-openSans">
        <button
          onClick={() => handleToggle("Offers")}
          className={`text-${
            selectedItem === "Offers" ? "secondary-500" : "white"
          }`}
        >
          Offers
        </button>

        <button
          onClick={() => handleToggle("Item Activity")}
          className={`text-${
            selectedItem === "Item Activity" ? "secondary-500" : "white"
          }`}
        >
          Item Activity
        </button>
      </div>
      <div className="flex">
        <div
          className={`w-[65px] mobile:w-[160px] h-[2px] bg-${
            selectedItem === "Item Activity"
              ? "white opacity-20"
              : "secondary-500"
          }`}
        ></div>
        <div className="w-[15px] h-[2px] bg-white opacity-20"></div>
        <div
          className={`w-[130px] mobile:px-12 h-[2px] bg-${
            selectedItem === "Item Activity"
              ? "secondary-500"
              : "white opacity-20"
          }`}
        ></div>
        <div className=" w-full h-[2px] opacity-20 bg-white"></div>
      </div>
    </div>
  );
};

export default ToggleList;
