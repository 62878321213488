import React, { ChangeEvent } from "react";
import { BiImageAdd } from "react-icons/bi";

export interface UploadComponentProps {
  className: string;
  image: string;
  name: string;
  disabled?: boolean;
  id: string;
  formik: any;
  onClick: React.MouseEventHandler;
  onChange: (val: string) => void;
  type: string;
}

function UploadComponent({
  onClick,
  onChange,
  name,
  image,
  disabled,
  id,
  formik,
  type,
  ...props
}: UploadComponentProps) {
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      onChange(imageUrl);
    }
  };

  return (
    <div>
      <label className={`w-[272px] mobile:w-[342px] h-[180px]`}>
        <span className="inline-flex items-baseline">
          <div className="flex flex-col items-center justify-center border-2 border-dashed cursor-pointer rounded-lg w-[272px] mobile:w-[342px] h-[180px]">
            {image ? (
              <img
                className={`cursor-pointer object-cover w-full h-full`}
                src={image}
                alt=""
              />
            ) : (
              <div className="flex flex-col items-center gap-4">
                <BiImageAdd className="w-10 h-10 text-white" />
                <p className="text-center text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                  Upload your photo
                </p>
              </div>
            )}
          </div>
          <input
            {...props}
            id={id}
            disabled={disabled}
            name={name}
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleChange}
            className={`${props.className} sr-only`}
          />
        </span>
      </label>
    </div>
  );
}

export default UploadComponent;
