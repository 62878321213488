import React, { useEffect, useRef, useState } from "react";
import PoolsTableComponent from "src/components/user/pools-table-component/pools-table-component";
import TitlePageComponent from "src/components/user/title-page-component";
import WallletPopupComponent from "src/components/user/wallet-popup-component";
import { pools } from "src/core/statics/community-pools-mock-data/community-pools-mock-data";

const CommunityPoolsContainer: React.FC = () => {
  const [visibleCount, setVisibleCount] = useState<number>(14);
  const [isWalletPopupVisible, setIsWalletPopupVisible] =
    useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 2); // Increase by 6 each time
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const currentPools = pools.slice(0, visibleCount);
  const handleJoinButtonClick = () => {
    setIsWalletPopupVisible(true);
    scrollToTop();
  };
  useEffect(() => {
    const handleClickOutSidePopup = ({ target }: MouseEvent) => {
      if (!popupRef?.current?.contains(target as Node)) {
        setIsWalletPopupVisible(false);
      }
    };
    document.body.addEventListener("click", handleClickOutSidePopup, true);

    return () => {
      document.body.removeEventListener("click", handleClickOutSidePopup);
    };
  }, []);
  return (
    <div className="pt-12">
      <TitlePageComponent home="Home" titlePage="Pools" />
      <div className="px-24 mobile:px-0 mt-12 mobile:mt-2">
        <div className="bg-Primary-700 rounded-lg pt-10 relative text-whit px-24 mobile:px-0 mb-40">
          <h2 className="mb-4 text-white text-[19px] font-montserrat font-bold mobile:px-4">
            Community Pools
          </h2>
          <p className="max max-w-[590px] text-neutral-300 font-openSans mobile:px-4">
            Community pools servers in investing in highly rare NFTs with other
            members, it is a sort of crowdfunding to trade in NFTs.
          </p>
          <PoolsTableComponent
            onJoin={handleJoinButtonClick}
            pools={currentPools}
          />
          {visibleCount < pools.length && (
            <div className="mt-4 flex justify-center">
              <button
                onClick={loadMore}
                className="border border-secondary-500 mt-10 mb-10 bg-transparent w-[170px] h-12 text-white py-2 px-6 rounded-lg"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
      {isWalletPopupVisible && <WallletPopupComponent popupRef={popupRef} />}
    </div>
  );
};

export default CommunityPoolsContainer;
