import {
  headersWalletHistory,
  walletHistoryData,
} from "src/core/statics/nfts-mock-data/nfts-mock-data";
import ActivityEmbtyStateComponent from "./portfolio-activity-embty-state-component";

function WalletHistoryComponent() {
  return (
    <div className="flex flex-col">
      {walletHistoryData.length === 0 ? (
        <div className="flex flex-col justify-center items-center">
          <ActivityEmbtyStateComponent />
        </div>
      ) : (
        <div className="flex flex-col bg-Primary-700">
          <div className="overflow-x-auto">
            <div className="min-w-[900px]">
              {/* Table Header */}
              <div className="h-[50px] border-b border-white/10 flex">
                {headersWalletHistory.map((header) => (
                  <div
                    key={header}
                    className="flex-1 h-[50px] p-4 flex items-center justify-center"
                  >
                    <div className="text-center text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      {header}
                    </div>
                  </div>
                ))}
              </div>
              {/* Table Body */}
              {walletHistoryData.map((row, index) => (
                <div
                  key={index}
                  className={`h-[50px] border-b border-white/10 flex ${
                    index % 2 === 0 ? "bg-Primary-700" : "bg-PrimaryOpacity12"
                  }`}
                >
                  <div className="flex-1 h-[50px] p-4 flex items-center justify-center">
                    <div className="text-center text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      {row.event}
                    </div>
                  </div>
                  <div className="flex-1 h-[50px] p-4 flex items-center justify-center">
                    <div className="text-center text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      {row.item}
                    </div>
                  </div>
                  <div className="flex-1 h-[50px] p-4 flex items-center justify-center">
                    <div className="text-center text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      {row.pool}
                    </div>
                  </div>
                  <div className="flex-1 h-[50px] p-4 flex items-center justify-center">
                    <div className="text-center text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      {row.price}
                    </div>
                  </div>
                  <div className="flex-1 h-[50px] p-4 flex items-center justify-center">
                    <div className="text-center text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      {row.date}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletHistoryComponent;
