import * as Yup from "yup";

export const validationSchemaLogin = () =>
  Yup.object({
    username: Yup.string()
      .min(5, "Please enter a correct email address")
      .required("Please enter a correct email address")
      .trim(),
    password: Yup.string()
      .trim()
      .required("Please enter a correct password ")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least 8 latter, 1 number, and 1 special character"
      ),
  });
