import LandingPage from "./pages/user/landig-page/landing-page";

function App() {
  return (
    <div className="w-full ">
      <LandingPage />
    </div>
  );
}

export default App;
