import { t } from "i18next";
import { useEffect, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import i18n from "src/core/configs/i18n";
import { options } from "src/core/statics/nfts-mock-data/nfts-mock-data";
import LoginPopupComponent from "./popup-login-component";
import BuyNftPopupComponent from "./buy-nft-popup-component";

function ExploreSportsCardComponents() {
  const [highlightIndex, setHighlightIndex] = useState<number>(1);
  const [highlightIndexMobile, setHighlightIndexMobile] = useState<number>(0);
  const cardsPerPage = 3;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const startIdx = currentPage * cardsPerPage;
  const endIdx = startIdx + cardsPerPage;
  const items = options.flatMap((option) => option.Item || []);
  const visibleCards = items.slice(startIdx, endIdx);
  const [isOpenPlaceAbidPopup, setIsOpenPlaceAbidPopup] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => (prevPage > 0 ? prevPage - 1 : prevPage));
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const handleNextClick = () => {
    setCurrentPage((prevPage) =>
      prevPage < Math.ceil(visibleCards.length / cardsPerPage) - 1
        ? prevPage + 1
        : prevPage
    );
  };
  const handlePrevClickMobile = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  const handleNextClickMobile = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === visibleCards.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };
  const handlePlaceBidClick = (item: any) => {
    setSelectedItem(item);
    if (loggedIn) {
      setIsOpenPlaceAbidPopup(true);
    } else {
      setShowLoginPopup(true);
    }
  };
  useEffect(() => {
    const initialValues = localStorage.getItem("user");
    if (initialValues) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);
  return (
    <div className="relative">
      <div
        dir="ltr"
        className="relative flex items-center gap-1 mt-[25px] mr-[110px] mobile:hidden tablet:hidden"
      >
        <div
          className="border-l border-[#50B848] w-full px-2 rounded-full cursor-pointer"
          onClick={handlePrevClick}
        >
          <MdArrowBackIos className="text-[#50B848] mobile:hidden" size={26} />
        </div>

        {visibleCards.map((item, index) => (
          <div
            className="mobile:w-[20px] mobile:ml-[-60px] w-[250px]"
            key={index}
          >
            <div
              className={`group mobile:w-[269px] relative ml-${
                (index - highlightIndex) * 400
              } w-[330px] h-[417px] hover:z-50 hover:bg-[#0047AB] bg-[#002A66] flex flex-col justify-center
                        items-center rounded-lg transition duration-300 ease-in-out transform
                        mobile:opacity-100
                        ${
                          index === highlightIndex
                            ? "scale-105 z-40 bg-[#0047AB]"
                            : "hover:scale-105 hover:z-50"
                        }
                        `}
              onMouseEnter={() => setHighlightIndex(index)}
              onMouseLeave={() => setHighlightIndex(1)}
            >
              <NavLink
                onClick={scrollToTop}
                to={appRoutesObj.getNftsItemDetailsUrl(item.id.toString())}
              >
                <div className={`flex flex-col`}>
                  <img
                    src={item.img}
                    alt=""
                    className="rounded-lg mobile:w-[248px] w-[298px] h-[307px] transform transition duration-300 ease-in-out group-hover:scale-y-[.80] group-hover:-my-[20px]"
                  />
                  <div
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    className="flex flex-row justify-between w-full mt-4"
                  >
                    <p className="font-montserrat font-bold text-[19px] text-white">
                      {item.name}
                    </p>
                    <p className="flex gap-3 font-montserrat font-bold text-[19px] text-white">
                      <p> {item.price}</p>
                      <p>{t("SAR")}</p>
                    </p>
                  </div>
                  <p
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    className="text-[13px] font-montserrat mt-2 group-hover:mb-[85px] opacity-50 text-[#D4D4D8]"
                  >
                    {item.username}
                  </p>
                </div>
              </NavLink>
              <div className="">
                <button
                  onClick={() => handlePlaceBidClick(item)}
                  className="absolute bottom-0 p-2 px-3 text-white transition duration-300 ease-in-out transform -translate-x-1/2 bg-[#50B848] hover:bg-white hover:text-[#50B848] rounded-md opacity-0 w-[250px] mb-5 group-hover:opacity-100"
                >
                  Collect Now
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className="absolute right-[-140px] border-r border-[#50B848] rounded-full cursor-pointer">
          <MdArrowForwardIos
            className="text-[#50B848] mobile:hidden"
            size={26}
            onClick={handleNextClick}
          />
        </div>
      </div>
      {/* mobile */}
      <div className="flex flex-col desktop:hidden laptop:hidden largeLaptop:hidden largeDesktop:hidden">
        {visibleCards.map((item, index) => (
          <div
            key={item.id}
            className={`
                        group w-[330px] h-[417px]
                        px-5 bg-[#0047AB] rounded-lg
                        hover:bg-[#0047AB]
                        transition duration-300
                        ease-in-out transform
                        ${index === highlightIndexMobile ? "" : "hidden"}`}
          >
            <div className={`flex flex-col group-hover:mt-[-20px]`}>
              <NavLink
                onClick={scrollToTop}
                to={appRoutesObj.getNftsItemDetailsUrl(item.id.toString())}
              >
                <img
                  src={item.img}
                  alt=""
                  className="rounded-lg pt-4 w-[298px] h-[307px] transform transition duration-300 ease-in-out group-hover:scale-y-75"
                />
              </NavLink>
              <div className="flex flex-row justify-between w-full group-hover:mt-[-30px] mt-6">
                <p className="font-montserrat text-[19px] text-white ">
                  {item.name}
                </p>
                <p className="font-montserrat text-[19px] text-white">
                  {item.price}
                </p>
              </div>
              <p className="text-[13px] mt-2 group-hover:mb-[100px] opacity-50">
                {item.username}
              </p>
            </div>
            <div className="">
              <button
                onClick={() => handlePlaceBidClick(item)}
                className="absolute bottom-0 left-[160px] p-2 px-3 text-white transition duration-300 ease-in-out transform -translate-x-1/2 bg-[#50B848] hover:bg-white hover:text-[#50B848] rounded-md opacity-0 w-[250px] mb-5 group-hover:opacity-100"
              >
                Collect Now
              </button>
            </div>
          </div>
        ))}
        <div dir="ltr" className="flex items-center justify-center gap-9 pt-9">
          <div
            className="flex items-center justify-center border-[#50B848] border-l-[0.5px] rounded-full px-2 cursor-pointer"
            onClick={handlePrevClickMobile}
          >
            <MdArrowBackIos className="text-[#50B848]" size={26} />
          </div>
          <div
            className="flex items-center justify-center border-[#50B848] border-r-[0.5px] rounded-full px-1 cursor-pointer"
            onClick={handleNextClickMobile}
          >
            <MdArrowForwardIos className="text-[#50B848]" size={26} />
          </div>
        </div>
      </div>
      {isOpenPlaceAbidPopup && (
        <BuyNftPopupComponent
          currentPrice={selectedItem.price}
          name={selectedItem.name}
          img={selectedItem.img}
          onClickToClose={() => setIsOpenPlaceAbidPopup(false)}
        />
      )}
      {showLoginPopup && (
        <LoginPopupComponent onClickToClose={() => setShowLoginPopup(false)} />
      )}
    </div>
  );
}
export default ExploreSportsCardComponents;
