// import { useTranslation } from "react-i18next";
function Footer() {
  // const { t, i18n } = useTranslation();

  return (
    <>
      <div className="flex h-[259px] gap-[69px] flex-col justify-center bg-gradient-to-t from-Primary-800 to-bg-cover mobile:hidden">
        <div className="flex items-center justify-around">
          <div className="">
            <img
              src="/assets/images/Logo-White.png"
              alt=""
              className="w-[156px] mobile:hidden"
            />
          </div>
          <div className="flex flex-col gap-4 text-">
            <p className="font-bold text-white cursor-pointer font-openSans">
              Marketplace
            </p>
            <p className="text-white cursor-pointer font-openSans">All NFT</p>
          </div>
          <div className="flex flex-col gap-4">
            <p className="font-bold text-white cursor-pointer font-openSans">
              Ressource
            </p>
            <p className="text-white cursor-pointer font-openSans">Partners</p>
          </div>
          <div className="flex flex-col gap-4">
            <p className="font-bold text-white cursor-pointer font-openSans">
              Company
            </p>
            <p className="text-white cursor-pointer font-openSans">
              Help Center
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-white font-openSans text-[13px]">
              Follow us on Social media
            </p>
            <div className="flex items-center justify-center gap-1">
              <a href="https://www.facebook.com">
                <img src="/assets/images/facebook.svg" alt="" />
              </a>
              <a href="https://www.facebook.com">
                <img src="/assets/images/X.svg" alt="" />
              </a>
              <a href="https://www.youtube.com">
                <img src="/assets/images/youtube.svg" alt="" />
              </a>
              <a href="https://www.facebook.com">
                <img src="/assets/images/instgram.svg" alt="" />
              </a>
              <a href="https://www.facebook.com">
                <img src="/assets/images/linkin.svg" alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-20">
          <div className=" bg-slate-500 h-[1px] px-10 flex"></div>
          <div className="flex justify-between gap-2 pt-4">
            <div className="w-[200px] tablet:hidden"></div>

            <div className="flex items-center justify-center">
              <p className="text-white text-[13px] font-openSans">
                Copyright © 2023. All rights reserved
              </p>
            </div>
            <div className="flex gap-6">
              <p className="text-white text-[13px] font-openSans">
                Terms Of Service
              </p>
              <p className="text-white text-[13px] font-openSans">
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="flex pb-3 flex-col items-center justify-center bg-gradient-to-t from-Primary-800 to-bg-cover w-screen desktop:hidden laptop:hidden largeLaptop:hidden largeDesktop:hidden tablet:hidden">
        <div className="flex flex-col items-center justify-center gap-8">
          <img
            src="/assets/images/Logo-White.png"
            alt=""
            className="w-[156px] h-11"
          />
          <div className="flex gap-9">
            <div className="flex flex-col gap-4 text-">
              <p className="font-bold text-white cursor-pointer font-openSans">
                Marketplace
              </p>
              <p className="text-white cursor-pointer font-openSans">All NFT</p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="font-bold text-white cursor-pointer font-openSans">
                Ressource
              </p>
              <p className="text-white cursor-pointer font-openSans">
                Partners
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <p className="font-bold text-white cursor-pointer font-openSans">
                Company
              </p>
              <p className="text-white cursor-pointer font-openSans">
                Help Center
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-white font-openSans text-[13px]">
              Follow us on Social media
            </p>
            <div className="flex items-center justify-center gap-2">
              <a href="https://www.facebook.com">
                <img src="/assets/images/facebook.svg" alt="" />
              </a>
              <a href="https://www.facebook.com">
                <img src="/assets/images/X.svg" alt="" />
              </a>
              <a href="https://www.youtube.com">
                <img src="/assets/images/youtube.svg" alt="" />
              </a>
              <a href="https://www.facebook.com">
                <img src="/assets/images/instgram.svg" alt="" />
              </a>
              <a href="https://www.facebook.com">
                <img src="/assets/images/linkin.svg" alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col pt-3">
          <div className="bg-white h-[1px] opacity-60 flex w-[350px]"></div>
          <div className="flex flex-col items-center justify-center gap-12 pt-4">
            <div className="flex items-center justify-center">
              <p className="text-white text-[13px] font-openSans">
                Copyright © 2023. All rights reserved
              </p>
            </div>
            <div className="flex gap-6">
              <p className="text-white text-[13px] font-openSans">
                Terms Of Service
              </p>
              <p className="text-white text-[13px] font-openSans">
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
