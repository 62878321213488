import { useEffect, useRef, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import PoolsTableComponent from "src/components/user/pools-table-component/pools-table-component";
import WallletPopupComponent from "src/components/user/wallet-popup-component";
import { pools } from "src/core/statics/community-pools-mock-data/community-pools-mock-data";

function CommunityPoolsLandingContainer() {
  const [visibleCount] = useState<number>(5);
  const currentPools = pools.slice(0, visibleCount);
  const [isWalletPopupVisible, setIsWalletPopupVisible] =
    useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const handleJoinButtonClick = () => {
    setIsWalletPopupVisible(true);
    scrollToTop();
  };
  useEffect(() => {
    const handleClickOutSidePopup = ({ target }: MouseEvent) => {
      if (!popupRef?.current?.contains(target as Node)) {
        setIsWalletPopupVisible(false);
      }
    };
    document.body.addEventListener("click", handleClickOutSidePopup, true);

    return () => {
      document.body.removeEventListener("click", handleClickOutSidePopup);
    };
  }, []);
  return (
    <div className="px-12 mobile:px-0 pt-44">
      <div className="bg-Primary-700 rounded-lg w-full pb-6">
        <p className="text-5xl mobile:text-4xl mobile:px-12 mobile:leading-[50.40px] font-montserrat font-bold text-center text-white pt-6">
          Community Pools
        </p>
        <div className="px-40 mobile:px-0">
          {" "}
          <PoolsTableComponent
            onJoin={handleJoinButtonClick}
            pools={currentPools}
          />
        </div>
        <div className="flex items-center justify-center pt-9">
          <Link onClick={scrollToTop} to={appRoutesObj.getCommunityPoolsPath()}>
            <button className="w-[189px] mobile:w-[358px] h-[48px] rounded-lg border border-[#50B848] flex items-center text-white justify-center gap-2">
              Explore More <GoArrowUpRight size={16} />{" "}
            </button>
          </Link>
        </div>
      </div>

      {isWalletPopupVisible && <WallletPopupComponent popupRef={popupRef} />}
    </div>
  );
}

export default CommunityPoolsLandingContainer;
