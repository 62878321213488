function NoMatchingStateComponent() {
  return (
    <div className="w-[486px] mobile:w-[356px] flex-col justify-center items-center gap-6 inline-flex">
      <img
        alt="No matching results"
        src="/assets/images/no-matching.png"
        className="mobile:hidden"
      />
      {/* Mobile Image */}
      <img
        alt="No matching results"
        src="/assets/images/no-matching-mobile.png"
        className="desktop:hidden largeDesktop:hidden laptop:hidden largeLaptop:hidden tablet:hidden"
      />
      <div className="text-center text-white text-2xl mobile:text-base font-bold font-['Montserrat'] leading-loose tracking-tight">
        We didn’t catch what you looking for
      </div>
    </div>
  );
}

export default NoMatchingStateComponent;
