import { useEffect, useRef, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { appRoutesObj } from "src/app.paths";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import DropDownButton from "../drop-down-button-component";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { BiWalletAlt } from "react-icons/bi";
import { DefaultTFuncReturn } from "i18next";
import { useBalanceStore } from "src/core/stores/balance-store";
import WallletPopupComponent from "../wallet-popup-component";
export interface IRoutesTitle {
  routeTitle: DefaultTFuncReturn;
  routePath: string;
}
export default function NavBar() {
  const { t, i18n } = useTranslation();
  const [searchInput, setSearchInput] = useState<string>("");
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [showWalletPopup, setShowWalletPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const { balance } = useBalanceStore();
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const changeLanguage = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };
  const News: IRoutesTitle[] = [
    {
      routeTitle: t("Team"),
      routePath: appRoutesObj.getNftsPagePath(),
    },
    {
      routeTitle: t("Team"),
      routePath: appRoutesObj.getBasePath(),
    },
  ];
  const [walletCurrency, setWalletCurrency] = useState<string>("SAR");

  const homepage: string = appRoutesObj.getBasePath();
  const login: string = appRoutesObj.getLoginPagePath();
  const NFTsPage: string = appRoutesObj.getNftsPagePath();
  const PoolsPage: string = appRoutesObj.getCommunityPoolsPath();
  const Portfolio: string = appRoutesObj.getPortfolioPath();
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [toggleSubMenuDis, setToggleSubMenuDis] = useState<boolean>(false);
  const [toggleSubMenu, setToggleSubMenu] = useState<boolean>(false);
  useEffect(() => {
    // Check localStorage for login status or other relevant data
    const initialValues = localStorage.getItem("user");
    if (initialValues) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }

    const handleClickOutSidePopup = ({ target }: MouseEvent) => {
      if (!popupRef?.current?.contains(target as Node)) {
        setShowWalletPopup(false);
      }
    };
    document.body.addEventListener("click", handleClickOutSidePopup, true);

    return () => {
      document.body.removeEventListener("click", handleClickOutSidePopup);
    };
  }, []);

  return (
    <div>
      <div className="flex items-center justify-center mobile:hidden tablet:hidden">
        <nav className="relative flex items-center justify-between w-full py-8 px-5 laptop:gap-1">
          <div className="w-[538px] mobile:w-full absolute -left-0 rtl:right-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
          <div className="flex items-center relative justify-center gap-6 laptop:gap-2 z-0">
            <Link to={appRoutesObj.getBasePath()}>
              <img
                className="max-w-[175px] max-h-[67px] laptop:w-[120px] cursor-pointer"
                src="/assets/images/Logo-White.png"
                alt="logo"
              />
            </Link>
          </div>
          <div className="flex relative items-center justify-center laptop:max-w-[316px] h-12 px-2 rounded bg-white/10 place-content-center mobile:hidden">
            <BiSearch size={30} className="text-white/10" />
            <input
              className="flex h-10 px-2 bg-transparent rounded-lg w-[316px] laptop:w-full focus:outline-none"
              type="text"
              placeholder={`${t("navbar.search.placeholder")}`}
              onChange={handleChange}
              value={searchInput}
            />
          </div>

          <div className="flex relative items-center justify-center gap-6">
            <NavLink
              className={({ isActive }) =>
                isActive ? " text-green-400" : "text-white"
              }
              to={homepage}
            >
              <div
                className={`cursor-pointer relative font-openSans  ${
                  i18n.language === "ar"
                    ? "text-base laptop:text-[14px]"
                    : "laptop:text-[14px]"
                }`}
              >
                {t("Home")}
              </div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? " text-green-400" : "text-white"
              }
              to={NFTsPage}
            >
              <div
                className={`cursor-pointer font-openSans  ${
                  i18n.language === "ar"
                    ? "text-base laptop:text-[14px]"
                    : "laptop:text-[14px]"
                }`}
              >
                {t("NFTs")}
              </div>
            </NavLink>
            {loggedIn && (
              <NavLink
                className={({ isActive }) =>
                  isActive ? " text-green-400" : "text-white"
                }
                to={Portfolio}
              >
                <div
                  className={`cursor-pointer font-openSans  ${
                    i18n.language === "ar"
                      ? "text-base laptop:text-[14px]"
                      : "laptop:text-[14px]"
                  }`}
                >
                  {t("Portfolio")}
                </div>
              </NavLink>
            )}

            <DropDownButton
              title={t("Discorver")}
              routes={News}
              isActive={toggleSubMenuDis}
              onToggle={() => setToggleSubMenuDis(!toggleSubMenuDis)}
            />
            <NavLink
              className={({ isActive }) =>
                isActive ? " text-green-400" : "text-white"
              }
              to={"a"}
            >
              <div
                className={`cursor-pointer font-openSans  ${
                  i18n.language === "ar"
                    ? "text-base laptop:text-[14px]"
                    : "laptop:text-[14px]"
                }`}
              >
                {t("Activity")}
              </div>
            </NavLink>
            {loggedIn && (
              <NavLink
                className={({ isActive }) =>
                  isActive ? " text-green-400" : "text-white"
                }
                to={PoolsPage}
              >
                <div
                  className={`cursor-pointer font-openSans  ${
                    i18n.language === "ar"
                      ? "text-base laptop:text-[14px]"
                      : "laptop:text-[14px]"
                  }`}
                >
                  {t("Pools")}
                </div>
              </NavLink>
            )}
            {loggedIn && (
              <div
                onClick={() => setShowWalletPopup(true)}
                className={`${
                  showWalletPopup ? "text-green-400" : "text-white"
                }`}
              >
                <div
                  className={` flex gap-1 cursor-pointer font-openSans  ${
                    i18n.language === "ar"
                      ? "text-base laptop:text-[14px]"
                      : "laptop:text-[14px]"
                  }`}
                >
                  {balance}
                  <p
                    className={`text-[11px] ${
                      i18n.language === "ar" ? "mt-0.5" : "mt-1.5"
                    }`}
                  >
                    SAR
                  </p>
                  <BiWalletAlt className="w-6 h-6 text-secondary-500" />
                </div>
              </div>
            )}
            <div className="flex flex-col items-center">
              <div
                onClick={() => setToggleSubMenu(!toggleSubMenu)}
                className="flex flex-row gap-1 items-center cursor-pointer"
              >
                <div className="bg-transparent text-white font-openSans text-base border-gray-300 focus:outline-none">
                  {walletCurrency === "SAR" && <div>SAR</div>}
                  {walletCurrency === "USD" && <div>USD</div>}
                </div>
                <BsCaretDownFill className="text-white" />
              </div>
              {toggleSubMenu && (
                <div className="flex flex-col absolute mt-8 mr-2">
                  <div
                    onClick={() => {
                      setWalletCurrency("SAR");
                      setToggleSubMenu(false);
                    }}
                    className="w-[93px] h-[35px] cursor-pointer p-2 bg-Primary-500 border-b border-white border-opacity-10 justify-start items-center gap-1 inline-flex"
                  >
                    <div
                      className={`self-stretch font-openSans ${
                        walletCurrency === "SAR"
                          ? "text-green-500"
                          : "text-neutral-200"
                      }  text-[13px] font-normal font-['Open Sans'] leading-[18.20px]`}
                    >
                      SAR
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setWalletCurrency("USD");
                      setToggleSubMenu(false);
                    }}
                    className="w-[93px] h-[35px] p-2 cursor-pointer bg-Primary-500 rounded-bl rounded-br justify-start items-center gap-1 inline-flex"
                  >
                    <div
                      className={`self-stretch font-openSans ${
                        walletCurrency === "USD"
                          ? "text-green-500"
                          : "text-neutral-200"
                      } text-[13px] font-normal font-['Open Sans'] leading-[18.20px]`}
                    >
                      USD
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center justify-center text-green-400 font-openSans">
              {i18n.language === "en" ? (
                <h1
                  className="text-base cursor-pointer"
                  onClick={() => changeLanguage()}
                >
                  AR
                </h1>
              ) : (
                <h1
                  className="text-base cursor-pointer tablet:px-2"
                  onClick={() => changeLanguage()}
                >
                  EN
                </h1>
              )}
            </div>
          </div>
          {!loggedIn && (
            <NavLink to={login} className="relative">
              <button className="rounded-lg text-white font-openSans mobile:hidden border text-mosratte border-secondary-500 w-[171px] h-10 laptop:w-[130px]">
                {t("navbar.button.login")}
              </button>
            </NavLink>
          )}
        </nav>
      </div>
      {/* mobile */}
      <div className="largeLaptop:hidden desktop:hidden laptop:hidden largeDesktop:hidden">
        <div className="w-[538px] mobile:w-full absolute -left-0 rtl:right-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
        <div className="relative">
          <div
            className={` ${
              toggleMenu ? "bg-[#002A66] flex" : ""
            }flex items-center justify-between w-screen px-6 py-6 text-white`}
          >
            <div onClick={() => setToggleMenu(!toggleMenu)}>
              {" "}
              {toggleMenu ? (
                <RxHamburgerMenu className="text-[30px] text-white" />
              ) : (
                <RxHamburgerMenu className="text-[30px] text-white" />
              )}
            </div>
            {toggleMenu ? (
              <div></div>
            ) : (
              <Link to={appRoutesObj.getBasePath()}>
                <img
                  className=""
                  src="/assets/images/Logo-mobile.svg"
                  alt="logo"
                />
              </Link>
            )}
          </div>
          {
            <div
              className={`${
                toggleMenu ? "flex" : "hidden"
              } flex-col bg-[#002A66] rounded-b-lg gap-3 px-3 text-text-white h-auto`}
            >
              <div
                className={`${
                  toggleMenu ? "flex" : "hidden"
                } justify-between flex`}
              >
                <div className="flex flex-col items-start gap-6 px-2">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-green-400" : "text-white"
                    }
                    to={homepage}
                  >
                    <div className="font-openSans text-[14px]">{t("Home")}</div>
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-green-400" : "text-white"
                    }
                    to={NFTsPage}
                  >
                    <div className="font-openSans text-[14px]">{t("NFTs")}</div>
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "text-green-400" : "text-white"
                    }
                    to={"a"}
                  >
                    <div className="font-openSans text-[14px]">
                      {t("Activity")}
                    </div>
                  </NavLink>
                  <div className="flex flex-col items-center">
                    <div
                      onClick={() => setToggleSubMenu(!toggleSubMenu)}
                      className="flex flex-row gap-1 items-center cursor-pointer"
                    >
                      <div className="bg-transparent text-white font-openSans text-[13px] border-gray-300 focus:outline-none">
                        {walletCurrency === "SAR" && <div>SAR</div>}
                        {walletCurrency === "USD" && <div>USD</div>}
                      </div>
                      <BsCaretDownFill className="text-white" />
                    </div>
                    {toggleSubMenu && (
                      <div className="flex flex-col gap-2 items-center mr-5 mt-2">
                        <div
                          onClick={() => {
                            setWalletCurrency("SAR");
                            setToggleSubMenu(false);
                          }}
                          className="cursor-pointer justify-start items-center gap-1 inline-flex"
                        >
                          <div
                            className={`self-stretch font-openSans ${
                              walletCurrency === "SAR"
                                ? "text-green-500"
                                : "text-neutral-200"
                            }  text-[13px] font-normal font-['Open Sans'] leading-[18.20px]`}
                          >
                            SAR
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setWalletCurrency("USD");
                            setToggleSubMenu(false);
                          }}
                          className="cursor-pointer rounded-bl rounded-br justify-start items-center gap-1 inline-flex"
                        >
                          <div
                            className={`self-stretch font-openSans ${
                              walletCurrency === "USD"
                                ? "text-green-500"
                                : "text-neutral-200"
                            } text-[13px] font-normal font-['Open Sans'] leading-[18.20px]`}
                          >
                            USD
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {i18n.language === "en" ? (
                    <h1
                      className="text-green-500 text-[13px] cursor-pointer text-secondaryGreen400"
                      onClick={() => changeLanguage()}
                    >
                      AR
                    </h1>
                  ) : (
                    <h1
                      className="text-green-500 text-cneter text-[13px] cursor-pointer font-ar text-secondaryGreen400"
                      onClick={() => changeLanguage()}
                    >
                      EN
                    </h1>
                  )}
                </div>
                <div className="flex flex-col items-end gap-6 px-2">
                  {loggedIn && (
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "text-green-400" : "text-white"
                      }
                      to={Portfolio}
                    >
                      <div className="px-7 w-[109px] font-openSans text-[14px]">
                        {t("Portfolio")}
                      </div>
                    </NavLink>
                  )}
                  <div
                    className={`flex flex-col items-center font-openSans text-[14px] ${
                      i18n.language === "ar" ? "" : ""
                    }`}
                  >
                    <RouteMenu
                      check={toggleSubMenuDis}
                      routesTitle="Discorver"
                      toggleSubMenu={() =>
                        setToggleSubMenuDis(!toggleSubMenuDis)
                      }
                      mapRoutes={News}
                    />
                  </div>
                  {loggedIn && (
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "text-green-400" : "text-white"
                      }
                      to={PoolsPage}
                    >
                      <div className="px-7 w-[109px] font-openSans">
                        {t("Pools")}
                      </div>
                    </NavLink>
                  )}
                  {loggedIn && (
                    <div
                      onClick={() => setShowWalletPopup(true)}
                      className={`${
                        showWalletPopup ? "text-green-400" : "text-white"
                      }`}
                    >
                      <div
                        className={` flex gap-1 cursor-pointer font-openSans  ${
                          i18n.language === "ar"
                            ? "text-base laptop:text-[14px]"
                            : "laptop:text-[14px]"
                        }`}
                      >
                        {balance}
                        <p
                          className={`text-[11px] ${
                            i18n.language === "ar" ? "mt-0.5" : "mt-1.5"
                          }`}
                        >
                          SAR
                        </p>
                        <BiWalletAlt className="w-6 h-6 text-secondary-500" />
                      </div>
                    </div>
                  )}
                  <div
                    className={`flex flex-col items-center font-openSans text-[14px] ${
                      i18n.language === "ar" ? "" : "w-[120px]"
                    }`}
                  ></div>
                </div>
              </div>
              {!loggedIn ? (
                <Link to={login}>
                  <button className="h-10 w-full font-openSans mb-4 rounded-lg text-white  border border-secondary-500">
                    {t("navbar.button.login")}
                  </button>
                </Link>
              ) : (
                <div></div>
              )}
            </div>
          }
        </div>
      </div>
      {showWalletPopup && <WallletPopupComponent popupRef={popupRef} />}
    </div>
  );
}
interface IProps {
  check: boolean;
  routesTitle?: string;
  toggleSubMenu: React.MouseEventHandler<HTMLDivElement>;
  mapRoutes: IRoutesTitle[];
}
function RouteMenu(props: IProps) {
  const { t, i18n } = useTranslation();
  return (
    <div className="">
      <div
        onClick={props.toggleSubMenu}
        className={`text-text-white flex items-center gap-2 cursor-pointer text-white font-openSans ${
          i18n.language === "ar"
            ? "font-arabic_normal text-[14px]"
            : "font-en text-[14px]"
        }`}
      >
        {t(`${props.routesTitle}`)}
        {props.check ? <BsCaretUpFill /> : <BsCaretDownFill />}
      </div>
      {
        <div
          className={`px-1 ${props.check ? "flex" : "hidden"} flex-col gap-2  ${
            i18n.language === "ar"
              ? "font-ar text-[12px]"
              : "font-en text-[12px]"
          }`}
        >
          {props.mapRoutes.map((item, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-green-500" : "text-white"
              }
              key={index}
              to={item.routePath}
            >
              <div key={index}> {item.routeTitle}</div>
            </NavLink>
          ))}
        </div>
      }
    </div>
  );
}
