import { Layout } from "src/components/common/layout";
import NftsDetailsItemContainer from "src/containers/user/nfts-details-item-container/nfts-details-item-container";

function NftsDetailsItemPage() {
  return (
    <Layout>
      <div>
        <NftsDetailsItemContainer />
      </div>
    </Layout>
  );
}

export default NftsDetailsItemPage;
