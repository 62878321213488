import { BiInfoCircle } from "react-icons/bi";
import { BiCamera } from "react-icons/bi";

function PhotoIdComponent() {
  return (
    <div className="pt-[35px]">
      <label className="flex px-11 mobile:px-0 items-center gap-4 text-[19px] font-openSans text-white">
        Document Verification
        <p>
          <BiInfoCircle size={24} />
        </p>
      </label>
      <div className="flex flex-col px-11 mobile:px-0">
        <div className="flex w-[344px] h-[50px] mt-[16px] text-[13px] gap-2 font-openSans text-white text-center items-center justify-center bg-clip-border bg-transparent border rounded-lg border-dashed">
          <BiCamera size={24} className=" text-white" />
          Take Photo
        </div>
        <p className="text-[11px] text-white py-2">
          Note: We will only review the document without saving it.
        </p>
      </div>
    </div>
  );
}

export default PhotoIdComponent;
