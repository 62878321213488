import { Layout } from "src/components/common/layout";
import PaymentContainer from "src/containers/user/payment-container/payment-container";

function PaymentPage() {
  return (
    <Layout>
      <PaymentContainer />
    </Layout>
  );
}

export default PaymentPage;
