import { useTranslation } from "react-i18next";

export interface inputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder: string;
  suffix?: JSX.Element;
  isValid?: boolean;
  hint?: string;
}
function InputPasswordComponent({
  placeholder,
  suffix,
  label,
  ...props
}: inputProps) {
  const getValidationPaddingClass = () => {
    switch (props.isValid) {
      default:
        return "px-2";
    }
  };
  const getValidationClass = () => {
    switch (props.isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500  outline-none";
      default:
        return "";
    }
  };

  const getHintClass = () => {
    switch (props.isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500";
      default:
        return "text-muted";
    }
  };
  const { i18n } = useTranslation();
  return (
    <div className="flex flex-col mb-4 relative">
      <label
        htmlFor="email"
        className="font-openSans mobile:px-7 text-white pb-[5px] pl-1"
      >
        {label}
      </label>
      <div className="relative flex mobile:px-6">
        <input
          type="text"
          placeholder={placeholder}
          className={`bg-[#7373731A]  placeholder:text-neutral-400
          placeholder:text-[13px] placeholder:font-openSans
         text-white h-11 w-[385px] mobile:w-[342px] rounded text-base
           ${getValidationClass()}
           disabled:bg-muted disabled:border-muted dark:bg-dark-700
           focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 ${
             props.className ?? ""
           }${getValidationPaddingClass()}`}
          {...props}
        />
        <div
          className={`absolute px-2 top-3 ${
            i18n.language === "ar" ? "left-0" : "right-0"
          }`}
        >
          {suffix}
        </div>
      </div>
      {props.hint && (
        <p
          className={`w-[369px] mobile:px-8 pt-1 text-[13px] px-2 ${getHintClass()}`}
        >
          {props.hint}
        </p>
      )}
    </div>
  );
}
export default InputPasswordComponent;
