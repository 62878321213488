import { useState } from "react";

interface DocumentSelectProps {
  documentTypes: string[];
}
function RadioButtonIdComponent(props: DocumentSelectProps) {
  const [selectedDocument, setSelectedDocument] = useState<string>("");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDocument(event.target.value);
  };
  return (
    <div className="w-[344px] pt-[35px] mobile:px-1">
      <div>
        <label className="block text-[19px] font-openSans text-white">
          Document Type
        </label>
        <div className="flex flex-col justify-center gap-4 pt-[16px]">
          {props.documentTypes.map((documentType) => (
            <div className="flex items-center" key={documentType}>
              <input
                type="radio"
                id={documentType}
                name="documentType"
                value={documentType}
                checked={selectedDocument === documentType}
                onChange={handleRadioChange}
                className="mr-2"
              />
              <label
                htmlFor={documentType}
                className="text-[13px] text-white font-openSans"
              >
                {documentType}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RadioButtonIdComponent;
