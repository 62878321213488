import { Form, Formik, useFormik } from "formik";
import { LegacyRef, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { useBalanceStore } from "src/core/stores/balance-store";
import { WalletSchema } from "./validation/wallet-validation";

interface Iprops {
  popupRef: LegacyRef<HTMLDivElement>;
}
function WallletPopupComponent(props: Iprops) {
  const [action, setAction] = useState<"add" | "withdraw">("add");
  const { balance, setBalance } = useBalanceStore();
  const [poolBalance, setPoolBalance] = useState<number>(0);
  const navigate = useNavigate();

  type InitialValuesType = {
    addAmount: number;
    withdrawAmount: number;
  };
  const initialValues: InitialValuesType = {
    addAmount: 0,
    withdrawAmount: 0,
  };
  const handleToggle = (selectedAction: "add" | "withdraw") => {
    setAction(selectedAction);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: WalletSchema(balance),
    onSubmit: async () => {
      if (action === "withdraw") {
        const withdrawAmount = formik.values.withdrawAmount;
        if (withdrawAmount > 0 && withdrawAmount <= balance) {
          setBalance(balance - withdrawAmount);
          setPoolBalance(poolBalance + withdrawAmount);
        }
      } else if (action === "add") {
        const addAmount = formik.values.addAmount;
        if (addAmount > 0) {
          setBalance(balance + addAmount);
          navigate(appRoutesObj.getPaymentPath());
        }
      }
    },
  });
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validationSchema={WalletSchema(balance)}
      onSubmit={() => formik.submitForm()}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div
          ref={props.popupRef}
          className={`absolute top-24 right-[7.5%] mobile:right-0 mobile:inset-x-0 mobile:mx-auto z-50 px-6 py-8 mobile:px-2 h-[560px] mobile:py-8  my-auto bg-Primary-700 shadow-[rgba(0,_0,_0,_0.10)_0px_0px_20px_0px] w-[525px] p-4 laptop:w-[500px] mobile:w-[358px] mobile:h-[635px] tablet:w-[500px] tablet:h-[550x] rounded-2xl`}
        >
          <div className="flex flex-col mobile:justify-start mobile:items-start gap-6 items-center">
            <p className="text-white text-[19px] font-bold font-['Montserrat'] leading-relaxed">
              Wallet
            </p>
            <div className="w-[477px] mobile:w-[342px] mobile:h-[184px] mobile:flex-col p-6 bg-Primary-600 rounded-lg justify-center items-center gap-6 inline-flex">
              <div className=" w-[202.50px] mobile:w-[326px] grow shrink basis-0 px-4 py-2 bg-Primary-700 rounded-lg justify-start items-center gap-[23px] flex">
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <p className="text-white text-base mobile:text-xs font-bold font-['Montserrat'] leading-snug">
                    {balance.toFixed(2)}
                  </p>
                  <p className="w-[264px] text-neutral-300 text-[13px] mobile:text-xs font-normal font-['Open Sans'] leading-[18.20px]">
                    Wallet Balance
                  </p>
                </div>
              </div>
              <div className="w-[202.50px] mobile:w-[326px] grow shrink basis-0 px-4 py-2 bg-Primary-700 rounded-lg justify-start items-center gap-[23px] flex">
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                  <p className="text-white text-base mobile:text-xs font-bold font-['Montserrat'] leading-snug">
                    {poolBalance.toFixed(2)}
                  </p>
                  <p className="w-[264px] text-neutral-300 text-[13px] mobile:text-xs font-normal font-['Open Sans'] leading-[18.20px]">
                    Pool Balance
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[477px] mobile:w-[342px] h-[72px] flex-col justify-start items-start gap-4 inline-flex">
              <p className="self-stretch text-neutral-300 text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                Would you like to add funds to your wallet balance?
              </p>
              <div className="self-stretch h-[38px] bg-Primary-600 rounded-lg justify-between items-center inline-flex">
                <div
                  onClick={() => handleToggle("add")}
                  className={`w-[238.50px] mobile:w-[171px] h-[38px] px-2 rounded-tl-lg rounded-bl-lg border-b ${
                    action === "add"
                      ? " border-green-500 text-white"
                      : "border-transparent text-neutral-300"
                  } justify-center items-center gap-2 flex cursor-pointer`}
                >
                  <p className="text-base font-normal font-['Open Sans'] leading-snug">
                    Add funds to pool
                  </p>
                </div>
                <div
                  onClick={() => handleToggle("withdraw")}
                  className={`grow shrink basis-0 self-stretch rounded-br-lg border-b ${
                    action === "withdraw"
                      ? "border-green-500 text-white"
                      : "border-transparent text-neutral-300"
                  } justify-center items-center flex cursor-pointer`}
                >
                  <p className=" text-base font-normal font-['Open Sans'] leading-snug">
                    Withdraw from pool
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <label className="w-[461px] text-white text-base font-normal font-['Open Sans'] leading-snug">
                  {action === "add"
                    ? "Amount to Contribute"
                    : "Amount to Withdraw"}
                </label>
                <div className="flex flex-col h-14">
                  <input
                    type="number"
                    name={action === "add" ? "addAmount" : "withdrawAmount"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
           placeholder:text-[13px] placeholder:font-openSans
          text-white h-11 w-[477px] mobile:w-[342px] rounded text-base
            disabled:bg-muted disabled:border-muted dark:bg-dark-700
            focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20  ${
              formik.errors[
                action === "add" ? "addAmount" : "withdrawAmount"
              ] &&
              formik.touched[action === "add" ? "addAmount" : "withdrawAmount"]
                ? "border border-red-500"
                : ""
            }
             `}
                    placeholder="Enter amount"
                  ></input>
                  {action === "withdraw" &&
                    formik.errors.withdrawAmount &&
                    formik.touched.withdrawAmount && (
                      <p className="text-red-500 text-[13px] mt-1 font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                        {formik.errors.withdrawAmount}
                      </p>
                    )}
                  {action === "add" &&
                    formik.errors.addAmount &&
                    formik.touched.addAmount && (
                      <p className="text-red-500 text-[13px] mt-1 font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                        {formik.errors.addAmount}
                      </p>
                    )}
                </div>
              </div>
              <div className="w-[477px] mobile:w-[342px] h-9 justify-start items-center gap-2 inline-flex">
                <BiInfoCircle className="text-green-500 w-5 h-5" />
                <div className="grow shrink basis-0 text-neutral-300 text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                  {action === "add"
                    ? "Enter the amount you wish to contribute to the pool. Minimum contribution is 1 SAR."
                    : "Enter the amount you wish to withdraw from the pool. Minimum withdrawal is 1 SAR."}
                </div>
              </div>
            </div>
            <button
              disabled={
                !formik.values.addAmount ||
                !!formik.errors.addAmount ||
                !formik.touched.addAmount
              }
              onClick={() => navigate(appRoutesObj.getPaymentPath())}
              className={`order-2 mobile:order-1 w-[170px]  ${
                !formik.errors.addAmount && !formik.touched.addAmount
                  ? "opacity-50"
                  : ""
              } mobile:w-[342px] ${
                action === "withdraw" ? "hidden" : ""
              } h-12 bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug`}
            >
              Contribute
            </button>
            <button
              type="submit"
              className={`order-2 ${
                action === "add" ? "hidden" : ""
              } mobile:order-1 w-[170px]  ${
                !formik.errors.withdrawAmount && !formik.touched.withdrawAmount
                  ? "opacity-50"
                  : ""
              } mobile:w-[342px] h-12 bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug`}
            >
              Withdraw
            </button>
          </div>
        </div>
        t
      </Form>
    </Formik>
  );
}

export default WallletPopupComponent;
