import { Layout } from "src/components/common/layout";
import PortfolioContainer from "src/containers/user/portfolio-container/portfolio-container";

function PortfolioPage() {
  return (
    <Layout>
      <PortfolioContainer />
    </Layout>
  );
}

export default PortfolioPage;
