import { Form, Formik, useFormik } from "formik";
import { BiCheckCircle } from "react-icons/bi";
import { paymentValidationSchema } from "../validation/payment-validation";

function PaymentComponent() {
  type InitialValuesType = {
    cardNumber: string;
    CVV: string;
    expirationDate: string;
  };
  const initialValues: InitialValuesType = {
    cardNumber: "",
    CVV: "",
    expirationDate: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: paymentValidationSchema,
    onSubmit: async () => {},
  });
  const removeSymbolsFromInput = (
    evt: React.KeyboardEvent<HTMLInputElement>
  ) => {
    ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();
  };
  const handleExpirationDateChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = evt.target.value.replace(/\D/g, "");
    let formattedValue = value;

    if (value.length >= 2) {
      formattedValue = value.slice(0, 2) + "/" + value.slice(2, 4);
    }
    formik.setFieldValue("expirationDate", formattedValue);
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validationSchema={paymentValidationSchema}
      onSubmit={() => formik.submitForm()}
    >
      <Form
        className={`w-[1204px] laptop:w-[1000px] tablet:w-[800px] mobile:w-[358px] mobile:px-2 mobile:py-8 ${
          Object.keys(formik.touched).some(
            (key) =>
              formik.touched[key as keyof InitialValuesType] &&
              formik.errors[key as keyof InitialValuesType]
          )
            ? "mobile:h-[720px]"
            : "mobile:h-[681px]"
        }  p-10 bg-blue-950 rounded-lg flex-col justify-center mobile:justify-start items-center gap-6  inline-flex`}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className="flex-col justify-start items-start gap-6 flex">
          <div className="w-[314px] h-[65px] flex-col justify-start items-start gap-4 flex">
            <p className="text-white text-[19px] font-bold font-['Montserrat'] leading-relaxed">
              Payment
            </p>
            <div className="justify-start items-start gap-2 inline-flex">
              <p className="text-neutral-300 text-base font-normal font-['Open Sans'] leading-snug">
                All transactions are secure and encrypted.
              </p>
            </div>
          </div>
          <div className="w-[496px] mobile:w-[342px] h-[84px] px-4 py-6 bg-Primary-600 mobile:pr-6 rounded-lg justify-start mobile:justify-center items-center gap-[23px] inline-flex">
            <div className="grow shrink basis-0 h-9 mobile:h-[30px] justify-between items-center flex">
              <div className="h-9 mobile:h-[30px] justify-start items-start gap-4 mobile:gap-2 flex">
                <div className="justify-start items-center gap-2 flex">
                  <input type="radio" />
                  <div className="flex-col justify-start items-start gap-1 inline-flex">
                    <p className="text-white text-base mobile:text-xs font-bold font-['Montserrat'] leading-snug">
                      PayPal
                    </p>
                  </div>
                </div>
                <div className="justify-start items-start gap-1 flex">
                  <p className="w-[264px] mobile:w-[196.5px] text-neutral-300 text-[13px] mobile:text-[11px] font-normal font-['Open Sans'] leading-[18.20px]">
                    You will be redirected to the PayPal website after
                    submitting your order
                  </p>
                </div>
              </div>
              <div className="w-[42.50px] h-[30px] relative">
                <div className="w-[42.50px] h-[30px] left-[-20.50px] mobile:left-0 top-0 absolute">
                  <div className="w-[42.50px] h-[30px] left-0 top-0 absolute bg-white rounded border border-slate-300" />
                </div>
                <img
                  alt=""
                  src="assets/icons/PayPal.svg"
                  className="w-[13.49px] h-[16.46px] left-[-6.07px] top-[7.16px] mobile:top-[7.16px] mobile:left-[14.43px] absolute"
                ></img>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div
            className={` ${
              Object.keys(formik.touched).some(
                (key) =>
                  formik.touched[key as keyof InitialValuesType] &&
                  formik.errors[key as keyof InitialValuesType]
              )
                ? "h-[450px] gap-[35px]"
                : "h-[426px] gap-[23px]"
            } w-[342px] px-2 py-6 bg-Primary-600 rounded-lg flex-col justify-start items-start  inline-flex laptop:hidden largeLaptop:hidden desktop:hidden largeDesktop:hidden tablet:hidden`}
          >
            <div className="w-auto justify-start items-center gap-2 flex">
              <input type="radio" />
              <div className="grow shrink basis-0 h-[30px] mobile:justify-start mobile:gap-2 items-center flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <p className="text-white text-base mobile:text-xs font-bold font-['Montserrat'] leading-snug">
                    Pay with Credit Card
                  </p>
                </div>
                <div className="justify-start items-start gap-2 flex">
                  <div className="w-[42.50px] h-[30px] relative">
                    <div className="w-[42.50px] h-[30px] left-[-20.50px] mobile:left-0 top-0 absolute">
                      <div className="w-[42.50px] h-[30px] left-0 top-0 absolute bg-white rounded border border-slate-300" />
                    </div>
                    <img
                      alt=""
                      className="w-[29.75px] h-[9.77px] left-[-14.50px] top-[10.12px] mobile:left-[6px] mobile:top-[10.12px] absolute"
                      src="assets/icons/visa-logo.svg"
                    />
                  </div>
                  <div className="w-[42.50px] h-[30px] relative">
                    <div className="w-[42.50px] h-[30px] left-[-20.50px] mobile:top-0 mobile:left-0 top-0 absolute">
                      <div className="w-[42.50px] h-[30px] left-0 top-0 absolute bg-white rounded border border-slate-300" />
                    </div>
                    <div className="w-[38.25px] h-[17.75px] left-[-16.75px] top-[11.25px] mobile:left-[3.75px] mobile:top-[11.25px] absolute">
                      <img
                        alt=""
                        className="left-0 top-0 absolute"
                        src="assets/icons/Discover.svg"
                      />
                    </div>
                  </div>
                  <div className="w-[42.50px] h-[30px] relative">
                    <div className="w-[42.50px] h-[30px] left-[-20.50px] mobile:left-0 top-0 absolute">
                      <div className="w-[42.50px] h-[30px] left-0 top-0 absolute bg-white rounded border border-slate-300" />
                    </div>
                    <div className="w-[27.95px] h-[16.75px] left-[-13px] top-[6.29px] mobile:left-[7.50px] mobile:top-[6.29px] absolute">
                      <img
                        alt=""
                        className="w-[27.95px] h-[16.75px] left-0 top-0 absolute"
                        src="assets/icons/Mastercard.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="h-[254px] flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch  flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch h-[80px] flex-col justify-start items-start flex">
                  <div className="self-stretch flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <div className=" self-stretch px-2 justify-start items-end gap-1 inline-flex">
                        <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                          Card number
                        </p>
                      </div>
                      <div className="relative grow shrink basis-0 justify-start items-center flex">
                        <input
                          type="number"
                          name="cardNumber"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onKeyDown={removeSymbolsFromInput}
                          placeholder="1234 5678 9101 3456"
                          className={` bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
                              placeholder:text-[13px] placeholder:font-openSans
                             text-white h-11 w-[326px] rounded text-base
                               disabled:bg-muted disabled:border-muted dark:bg-dark-700 ${
                                 formik.errors.cardNumber &&
                                 formik.touched.cardNumber &&
                                 "border border-red-500  outline-none"
                               }
                               focus:ring-primary leading-7  focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 
                                `}
                        ></input>
                        {!formik.errors.cardNumber &&
                          formik.touched.cardNumber && (
                            <BiCheckCircle className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500" />
                          )}
                      </div>
                    </div>
                  </div>
                  {formik.errors.cardNumber && formik.touched.cardNumber && (
                    <p className="text-red-500 text-[13px] mt-1 font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                      {formik.errors.cardNumber}
                    </p>
                  )}
                </div>
                <div className="self-stretch h-[80px] flex-col justify-start items-start flex">
                  <div className="self-stretch flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch  flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch  px-2 justify-start items-end gap-1 inline-flex">
                        <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                          Expiration Date
                        </p>
                      </div>
                      <input
                        type="text"
                        // onKeyDown={removeSymbolsFromInput}
                        onChange={handleExpirationDateChange}
                        onBlur={formik.handleBlur}
                        name="expirationDate"
                        value={formik.values.expirationDate}
                        placeholder="MM/YY"
                        className={`bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
                              placeholder:text-[13px] placeholder:font-openSans
                             text-white ${
                               formik.errors.expirationDate &&
                               formik.touched.expirationDate &&
                               "border border-red-500  outline-none"
                             } h-11 w-[326px] rounded text-base
                               disabled:bg-muted disabled:border-muted dark:bg-dark-700
                               focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 
                                `}
                      ></input>
                    </div>
                  </div>
                  {formik.errors.expirationDate &&
                    formik.touched.expirationDate && (
                      <p className="text-red-500 text-[13px] mt-1 font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                        {formik.errors.expirationDate}
                      </p>
                    )}
                </div>
                <div className="self-stretch h-[80px] flex-col justify-start items-start flex">
                  <div className="self-stretch flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch  px-2 justify-start items-end gap-1 inline-flex">
                        <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                          CVV
                        </p>
                      </div>
                      <input
                        type="number"
                        placeholder="***"
                        // onKeyDown={removeSymbolsFromInput}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="CVV"
                        value={formik.values.CVV}
                        className={`bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
                              placeholder:text-[13px] placeholder:font-openSans
                             text-white h-11 ${
                               formik.errors.CVV &&
                               formik.touched.CVV &&
                               "border border-red-500  outline-none"
                             } w-[326px] rounded text-base
                               disabled:bg-muted disabled:border-muted dark:bg-dark-700
                               focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 
                                `}
                      ></input>
                    </div>
                  </div>
                  {formik.errors.CVV && formik.touched.CVV && (
                    <p className="text-red-500 text-[13px] mt-1 font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                      {formik.errors.CVV}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <button
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
              className={`w-[326px] ${
                !formik.isValid || !formik.dirty ? "opacity-50" : ""
              } h-12 bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug`}
            >
              Payment
            </button>
          </div>

          {/* desktop */}
          <div
            className={`w-[496px] mobile:w-[342px] mobile:h-[426px] mobile:hidden  ${
              Object.keys(formik.touched).some(
                (key) =>
                  formik.touched[key as keyof InitialValuesType] &&
                  formik.errors[key as keyof InitialValuesType]
              )
                ? "h-[309px]"
                : "h-[265px]"
            } px-4 py-6 bg-Primary-600 rounded-lg flex-col justify-start items-start mobile:justify-center mobile:items-center gap-[23px] flex`}
          >
            <div className=" w-[464px] mobile:w-auto justify-start items-center gap-2 flex">
              <input type="radio" />
              <div className="grow shrink basis-0 h-[30px] justify-between mobile:justify-start mobile:gap-2 items-center flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <p className="text-white text-base mobile:text-xs font-bold font-['Montserrat'] leading-snug">
                    Pay with Credit Card
                  </p>
                </div>
                <div className="justify-start items-start gap-2 flex">
                  <div className="w-[42.50px] h-[30px] relative">
                    <div className="w-[42.50px] h-[30px] left-[-20.50px] mobile:left-0 top-0 absolute">
                      <div className="w-[42.50px] h-[30px] left-0 top-0 absolute bg-white rounded border border-slate-300" />
                    </div>
                    <img
                      alt=""
                      className="w-[29.75px] h-[9.77px] left-[-14.50px] top-[10.12px] mobile:left-[6px] mobile:top-[10.12px] absolute"
                      src="assets/icons/visa-logo.svg"
                    />
                  </div>
                  <div className="w-[42.50px] h-[30px] relative">
                    <div className="w-[42.50px] h-[30px] left-[-20.50px] mobile:top-0 mobile:left-0 top-0 absolute">
                      <div className="w-[42.50px] h-[30px] left-0 top-0 absolute bg-white rounded border border-slate-300" />
                    </div>
                    <div className="w-[38.25px] h-[17.75px] left-[-16.75px] top-[11.25px] mobile:left-[3.75px] mobile:top-[11.25px] absolute">
                      <img
                        alt=""
                        className="left-0 top-0 absolute"
                        src="assets/icons/Discover.svg"
                      />
                    </div>
                  </div>
                  <div className="w-[42.50px] h-[30px] relative">
                    <div className="w-[42.50px] h-[30px] left-[-20.50px] mobile:left-0 top-0 absolute">
                      <div className="w-[42.50px] h-[30px] left-0 top-0 absolute bg-white rounded border border-slate-300" />
                    </div>
                    <div className="w-[27.95px] h-[16.75px] left-[-13px] top-[6.29px] mobile:left-[7.50px] mobile:top-[6.29px] absolute">
                      <img
                        alt=""
                        className="w-[27.95px] h-[16.75px] left-0 top-0 absolute"
                        src="assets/icons/Mastercard.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[464px] mobile:w-[342px] h-[164px] mobile:h-[254px] flex-col justify-start items-start gap-4 flex">
              <div className="w-[464px] mobile:w-[342px] mobile:flex-col mobile:gap-4 mobile:self-stretch  mobile:justify-start justify-between items-start inline-flex">
                <div className="w-[228px] flex-col justify-start items-start inline-flex">
                  <div className="self-stretch flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch px-2 justify-start items-end gap-1 inline-flex">
                        <div className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                          Card number
                        </div>
                      </div>
                      <div className="self-stretch h-11 flex-col justify-center items-end mobile:items-start flex">
                        <div className="relative grow shrink basis-0 h-6 justify-start items-center gap-px flex">
                          <input
                            type="number"
                            name="cardNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyDown={removeSymbolsFromInput}
                            placeholder="1234 5678 9101 3456"
                            className={`bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
                              placeholder:text-[13px] placeholder:font-openSans
                             text-white h-11 w-[228px] mobile:w-[326px] rounded text-base
                               disabled:bg-muted disabled:border-muted dark:bg-dark-700 ${
                                 formik.errors.cardNumber &&
                                 formik.touched.cardNumber &&
                                 "border border-red-500  outline-none"
                               }
                               focus:ring-primary leading-7  focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 
                                `}
                          ></input>
                          {!formik.errors.cardNumber &&
                            formik.touched.cardNumber && (
                              <BiCheckCircle className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500" />
                            )}
                        </div>
                      </div>
                      {formik.errors.cardNumber &&
                        formik.touched.cardNumber && (
                          <p className="text-red-500 text-[13px] font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                            {formik.errors.cardNumber}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="w-[228px] flex-col justify-start items-start inline-flex">
                  <div className="self-stretch flex-col justify-center items-center gap-2 flex">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch px-2 justify-start items-end gap-1 inline-flex">
                        <div className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                          Expiration Date
                        </div>
                      </div>
                      <div className="self-stretch h-11 flex-col justify-center items-end mobile:items-start flex">
                        <div className="grow shrink basis-0 h-6 justify-start items-center gap-px flex">
                          <input
                            type="text"
                            // onKeyDown={removeSymbolsFromInput}
                            onChange={handleExpirationDateChange}
                            onBlur={formik.handleBlur}
                            name="expirationDate"
                            value={formik.values.expirationDate}
                            placeholder="MM/YY"
                            className={`bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
                              placeholder:text-[13px] placeholder:font-openSans
                             text-white ${
                               formik.errors.expirationDate &&
                               formik.touched.expirationDate &&
                               "border border-red-500  outline-none"
                             } h-11 w-[228px] mobile:w-[326px] rounded text-base
                               disabled:bg-muted disabled:border-muted dark:bg-dark-700
                               focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 
                                `}
                          ></input>
                        </div>
                      </div>
                      {formik.errors.expirationDate &&
                        formik.touched.expirationDate && (
                          <p className="text-red-500 text-[13px] font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                            {formik.errors.expirationDate}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[464px] mobile:w-[342px] flex-col justify-center items-center gap-2 inline-flex">
                <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch px-2 justify-start items-end gap-1 inline-flex">
                    <div className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                      CVV
                    </div>
                  </div>
                  <div className="self-stretch h-11 flex-col justify-center items-end mobile:items-start flex">
                    <div className="self-stretch grow shrink basis-0 py-3 rounded-lg justify-end items-center gap-2 inline-flex">
                      <input
                        type="number"
                        placeholder="***"
                        // onKeyDown={removeSymbolsFromInput}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="CVV"
                        value={formik.values.CVV}
                        className={`bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
                              placeholder:text-[13px] placeholder:font-openSans
                             text-white h-11 ${
                               formik.errors.CVV &&
                               formik.touched.CVV &&
                               "border border-red-500  outline-none"
                             } w-[464px] mobile:w-[326px] rounded text-base
                               disabled:bg-muted disabled:border-muted dark:bg-dark-700
                               focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 
                                `}
                      ></input>
                    </div>
                  </div>
                  {formik.errors.CVV && formik.touched.CVV && (
                    <p className="text-red-500 text-[13px] font-normal font-['Open Sans'] leading-[18.20px] mobile:text-xs">
                      {formik.errors.CVV}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          disabled={!formik.isValid || !formik.dirty}
          type="submit"
          className={`w-[170px] ${
            !formik.isValid || !formik.dirty ? "opacity-50" : ""
          } mobile:hidden h-12 bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug`}
        >
          Payment
        </button>
      </Form>
    </Formik>
  );
}

export default PaymentComponent;
