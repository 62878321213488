import { Layout } from "src/components/common/layout";
import CommunityPoolsContainer from "src/containers/user/community-pools-container/community-pools-container";

function CommunityPoolsPage() {
  return (
    <Layout>
      <CommunityPoolsContainer />
    </Layout>
  );
}

export default CommunityPoolsPage;
