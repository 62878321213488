import { Form, Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { BsXLg } from "react-icons/bs";
import { useBalanceStore } from "src/core/stores/balance-store";
import { BidAPriceValuesType } from "src/core/types/place-a-bid-type";
import { BidSchema } from "./validation/place-a-bid-price-validation";

interface Iprops {
  img: string | undefined;
  name: string | undefined;
  currentPrice: number | undefined;
  onClickToClose: () => void;
}
function PlaceABidPopupComponent(props: Iprops) {
  const { balance } = useBalanceStore();
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(true);
  const initialValues: BidAPriceValuesType = {
    bidPrice: 0,
    totalPrice: 0,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: BidSchema(balance, props.currentPrice!),
    onSubmit: async () => {
      props.onClickToClose();
    },
  });
  const handleConfirmBidClick = () => {
    setIsOpenPopup(false);
    setIsOpenConfirmPopup(true);
  };
  useEffect(() => {
    const bidPrice = formik.values.bidPrice;
    const totalPrice = bidPrice + bidPrice * 0.015;
    formik.setFieldValue("totalPrice", isNaN(totalPrice) ? 0 : totalPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bidPrice]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BidSchema(balance, props.currentPrice!)}
      validateOnBlur
      validateOnChange
      onSubmit={() => formik.submitForm()}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {isOpenPopup && (
          <div
            className={`fixed top-0 left-0 z-50 inset-y-0 inset-x-0 mx-auto my-auto bg-Primary-600 shadow-[rgba(0,_0,_0,_0.10)_0px_0px_20px_0px] w-[400px]  ${
              formik.errors.bidPrice && formik.touched.bidPrice
                ? "h-[640px]"
                : "h-[610px]"
            } p-4 laptop:w-[400px] mobile:w-[358px] mobile:h-[707px] tablet:w-[400px] tablet:h-[550x] rounded-2xl`}
          >
            <div className="flex flex-col gap-6">
              <div className="self-stretch h-10 p-4 rounded-lg justify-end items-center inline-flex">
                <BsXLg
                  onClick={props.onClickToClose}
                  className="h-6 w-6 text-white cursor-pointer"
                />
              </div>
              <div className="w-[368px] mobile:w-[326px] flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch text-white text-[19px] font-bold font-['Montserrat'] leading-relaxed">
                  Place a bid
                </div>
                <div className="self-stretch text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                  You are to place a bid for cryptoPork #485 from cryptoPork
                  collection
                </div>
              </div>
              <div className="w-[368px] mobile:w-[326px] p-4 bg-Primary-600 rounded-xl border border-white border-opacity-10 justify-between items-center inline-flex">
                <div className="grow shrink basis-0 h-10 justify-start items-start gap-4 flex">
                  <img
                    alt=""
                    src={props.img}
                    className="w-10 h-10 relative bg-gradient-to-b from-green-300 to-green-500 rounded-[28px]"
                  />
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="justify-start items-start gap-1 inline-flex">
                      <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                        0x313…1054
                      </div>
                    </div>
                    <div className="self-stretch text-white text-sm font-normal font-['Inter'] leading-tight">
                      {props.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-2">
                    <label className="w-[352px] mobile:w-[326px] text-white text-base font-normal font-['Open Sans'] leading-snug">
                      {" "}
                      Bid price
                    </label>
                    <input
                      step="any"
                      type="number"
                      disabled={balance === 0}
                      name="bidPrice"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`bg-[#7373731A] px-2 py-3 placeholder:text-neutral-400
           placeholder:text-[13px] placeholder:font-openSans
          text-white h-11 w-[368px] mobile:w-[326px] rounded text-base
            disabled:bg-muted disabled:border-muted dark:bg-dark-700
            focus:ring-primary leading-7 focus:border-transparent focus:outline-none focus:ring-2 disabled:border-opacity-40 disabled:bg-opacity-20 ${
              formik.errors.bidPrice &&
              formik.touched.bidPrice &&
              "border border-red-500  outline-none"
            }`}
                      placeholder={`${
                        balance === 0 ? "has no balance" : "Enter bid price"
                      }`}
                    ></input>
                    {formik.errors.bidPrice && formik.touched.bidPrice && (
                      <div className="text-red-500 text-[13px] font-['Open Sans'] leading-[18.20px]">
                        {formik.errors.bidPrice}
                      </div>
                    )}
                  </div>
                  <div className="w-[368px] mobile:w-[326px] flex-col justify-center items-start gap-4 inline-flex">
                    <div className="self-stretch justify-between items-start inline-flex">
                      <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                        Your bidding balance
                      </p>
                      <p className="grow shrink basis-0 text-right text-white text-base font-normal font-['Open Sans'] leading-snug">
                        {formik.values.bidPrice} halamint
                      </p>
                    </div>
                    <div className="self-stretch justify-between items-start inline-flex">
                      <p
                        className={`grow shrink basis-0 ${
                          balance === 0 ? "text-red-500" : "text-green-500"
                        }  text-base font-normal font-['Open Sans'] leading-snug`}
                      >
                        Your balance{" "}
                      </p>
                      <p
                        className={`grow shrink basis-0 text-right ${
                          balance === 0 ? "text-red-500" : "text-green-500"
                        } text-base font-normal font-['Open Sans'] leading-snug`}
                      >
                        {balance} halamint
                      </p>
                    </div>
                    <div className="self-stretch justify-between items-start inline-flex">
                      <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                        Rarible fee
                      </p>
                      <p className="grow shrink basis-0 text-right text-white text-base font-normal font-['Open Sans'] leading-snug">
                        1.5%
                      </p>
                    </div>
                    <div className="w-[368px] mobile:w-[326px] h-[38px] py-2 border-t border-whiteOpacity10 justify-between items-start inline-flex">
                      <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                        Total price
                      </p>
                      <p className="grow shrink basis-0 text-right text-white text-base font-normal font-['Open Sans'] leading-snug">
                        {(typeof formik.values.totalPrice === "number"
                          ? formik.values.totalPrice
                          : 0
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row mobile:flex-col gap-3.5 justify-center items-center">
                <button
                  onClick={props.onClickToClose}
                  className="order-1 mobile:order-2 w-[170px] mobile:w-[326px] h-12 rounded-lg border border-green-500 text-white text-base font-normal font-['Open Sans'] leading-snug"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmBidClick}
                  disabled={
                    formik.values.bidPrice <= 0 ||
                    formik.values.bidPrice * 1.015 > balance ||
                    formik.values.bidPrice <= props.currentPrice!
                  }
                  className={`order-2 mobile:order-1 w-[170px] mobile:w-[326px] h-12 ${
                    formik.values.bidPrice <= 0 ||
                    formik.values.bidPrice * 1.015 > balance ||
                    formik.values.bidPrice <= props.currentPrice!
                      ? "opacity-50"
                      : ""
                  } bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug`}
                >
                  Place a bid
                </button>
              </div>
            </div>
          </div>
        )}
        {isOpenConfirmPopup && (
          <div className="fixed top-0 left-0 inset-y-0 inset-x-0 mx-auto my-auto bg-Primary-600 shadow-[rgba(0,_0,_0,_0.10)_0px_0px_20px_0px] w-[444px] h-[266px] p-4 laptop:w-[400px] mobile:w-[358px] mobile:h-[360px] tablet:w-[480px] tablet:h-[550x] rounded-2xl">
            <div className="flex flex-col">
              <div className="self-stretch h-10 p-4 rounded-lg justify-end items-center inline-flex">
                <BsXLg
                  onClick={props.onClickToClose}
                  className="h-6 w-6 cursor-pointer text-white"
                />
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex-col justify-center items-center gap-4 inline-flex">
                  <div className="self-stretch text-center text-white text-[19px] font-bold font-['Montserrat'] leading-relaxed">
                    Are you sure you want to bid at the following price?
                  </div>
                  <div className="self-stretch text-center">
                    <span className="text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      Click 'Confirm Bid' to verify your bid Price
                    </span>
                    <span className="text-white text-[13px] font-bold font-['Open Sans'] leading-[18.20px]">
                      {" "}
                    </span>
                    <span className="text-green-500 text-[13px] font-bold font-['Open Sans'] leading-[18.20px]">
                      {formik.values.totalPrice.toFixed(2)} SAR
                    </span>
                    <span className="text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                      . <br />
                      Ensure it's correct before proceeding.
                    </span>
                  </div>
                </div>
                <div className="flex flex-row mobile:flex-col gap-3.5 items-center justify-center">
                  <button
                    onClick={props.onClickToClose}
                    className="order-1 mobile:order-2 w-[170px] mobile:w-[326px] h-12 rounded-lg border border-green-500 text-white text-base font-normal font-['Open Sans'] leading-snug"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="order-2 mobile:order-1 w-[170px] mobile:w-[326px] h-12 bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug"
                  >
                    Confirm Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </Formik>
  );
}

export default PlaceABidPopupComponent;
