import { useState } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { DirectionEnum } from "src/core/enums/design-system.enum";
import { FormikProps } from "formik";
import { SetProfile } from "src/core/types/user.type";

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
  label?: string;
  searchTerm: string;
  formik: FormikProps<SetProfile>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  cities: string[];
}
function CityDropDwonComponent({
  hint,
  isValid,
  searchTerm,
  setSearchTerm,
  formik,
  ...props
}: InputFieldProps): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const [filteredCities, setFilteredCities] = useState<string[]>(props.cities);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (city: string) => {
    setIsOpen(false);
    setSelectedCity(city);
    setSearchTerm(city);
    formik.setFieldValue("city", city);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    formik.setFieldValue("city", term);
    const filtered = props.cities.filter((city) =>
      city.toLowerCase().includes(term)
    );
    setFilteredCities(filtered);
  };
  const getValidationPaddingClass = () => {
    switch (isValid) {
      case true:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      case false:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      default:
        return "px-4";
    }
  };
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500  outline-none";
      default:
        return "";
    }
  };
  const getHintClass = () => {
    switch (isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500 pt-1 text-[13px]";
      default:
        return "text-muted";
    }
  };

  return (
    <div className="relative">
      <p className="mb-2 px-2 font-openSans text-white">City</p>
      <div>
        <span className="relative">
          <input
            type="text"
            onClick={handleToggle}
            value={searchTerm || selectedCity || ""}
            onChange={handleSearchChange}
            placeholder={selectedCity || "Choose your city"}
            className={`justify-center w-[564px] ${getValidationClass()} ${getValidationPaddingClass()} mobile:w-[342px] tablet:w-[342px] h-11 px-2 font-openSans bg-[#7373731A] text-[13px] text-gray-700 focus:outline-none
            ${isOpen ? "rounded-t" : "rounded"}
            `}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            {isOpen ? (
              <FiChevronUp size={19} className="text-neutral-400" />
            ) : (
              <FiChevronDown size={19} className="text-neutral-400" />
            )}
          </div>
        </span>
      </div>
      {isOpen && (
        <div className="absolute z-30 w-[564px] mobile:w-[342px] shadow-lg bg-Primary-500">
          <div role="menu">
            {filteredCities.map((city) => (
              <button
                key={city}
                onClick={() => handleSelect(city)}
                className="flex flex-col p-3.5 text-white px-4 text-[13px]"
                role="menuitem"
              >
                {city}
              </button>
            ))}
          </div>
        </div>
      )}
      {hint && <p className={getHintClass()}>{hint}</p>}
    </div>
  );
}

export default CityDropDwonComponent;
