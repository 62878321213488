import { useState } from "react";
import NftsHistoryComponent from "./nfts-history-component";
import WalletHistoryComponent from "./wallet-history-component";

function ActivityComponent() {
  const [activeTab, setActiveTab] = useState<string>("NftsHistory");
  return (
    <div className="flex flex-col gap-4 ">
      <div className="h-[46px] flex gap-6 border-b border-whiteOpacity10">
        <p
          onClick={() => setActiveTab("NftsHistory")}
          className={`cursor-pointer ${
            activeTab === "NftsHistory"
              ? "text-secondary-500 border-b border-secondary-500"
              : "text-white"
          }  `}
        >
          NFTs History
        </p>
        <p
          onClick={() => setActiveTab("WalletHistory")}
          className={`cursor-pointer ${
            activeTab === "WalletHistory"
              ? "text-secondary-500 border-b border-secondary-500"
              : "text-white"
          }  `}
        >
          Wallet History
        </p>
      </div>
      {activeTab === "NftsHistory" && <NftsHistoryComponent />}
      {activeTab === "WalletHistory" && <WalletHistoryComponent />}
    </div>
  );
}

export default ActivityComponent;
