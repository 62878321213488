import * as yup from "yup";

export const BidSchema = (balance: number, currentPrice: number) =>
  yup.object().shape({
    bidPrice: yup
      .number()
      .required("Bid price is required")
      .min(
        currentPrice + 1,
        `Enter bid price more than the currently price`
      )
      .test(
        "is-bid-price-valid",
        "Your balance is insufficient, charge your wallet to continue",
        function (value) {
          if (value === undefined || value === null || value <= 0) return false;

          const fee = value * 0.015;
          const totalCost = value + fee;

          return totalCost <= balance;
        }
      )
      .typeError("Bid price must be a number"),
  });
