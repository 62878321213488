import { BiUser } from "react-icons/bi";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { PiUsersThreeLight } from "react-icons/pi";
import { BiFootball } from "react-icons/bi";
import { useTranslation } from "react-i18next";

function TypeOfAccountYoudBeOpeningContainer() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const handlePageLogIn = () => {
    navigate(appRoutesObj.getLoginPagePath());
  };

  return (
    <div
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className="h-screen bg-Primary-950"
    >
      <div className="w-[538px] mobile:w-full absolute mobile:left-0 -left-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
      <div className="relative items-start justify-start text-white p-7">
        <img
          className="max-w-[175px] max-h-[67px]"
          src="/assets/images/Logo-White.png"
          alt=""
        />
      </div>
      <div className="relative flex px-40 mobile:px-4">
        <div className="rounded-lg bg-Primary-700 z-30 w-full h-[756px] mobile:h-auto">
          <div className="flex flex-col justify-start items-center pt-[60px] mobile:pt-2">
            <p className="text-2xl font-bold leading-loose tracking-tight text-center text-white font-montserrat">
              Join Us!
            </p>
            <div className="flex flex-col pt-[62px] mobile:pt-[35px]">
              <span className="text-[13px] w-[385px] mobile:w-[342px] text-neutral-300 font-openSans">
                To begin this journey, tell us what type of account you’d be
                opening.
              </span>
              <Link
                className="h-[78px] mobile:w-[342px] px-4 mt-[16px] bg-Primary-500 rounded-lg justify-start items-center gap-[23px] inline-flex"
                to={appRoutesObj.getSignUpPagePath()}
              >
                <BiUser size={24} className="text-white" />
                <span className="text-white mobile:text-[13px] font-bold w-[264px] font-montserrat">
                  General
                  <p className="text-neutral-300 font-openSans font-normal text-[13px] mobile:text-[11px]">
                    Buy and sell digital assets effortlessly on our versatile
                    platform.
                  </p>
                </span>
                {i18n.language === "ar" ? (
                  <BsArrowLeftShort size={26} className="text-white" />
                ) : (
                  <BsArrowRightShort size={26} className="text-white" />
                )}
              </Link>
              <Link to={appRoutesObj.getSignUpPagePath()}>
                <div className="h-[78px] px-4 mobile:w-[342px] mt-[16px] bg-Primary-500 rounded-lg justify-start items-center gap-[23px] inline-flex">
                  <PiUsersThreeLight size={24} className="text-white" />
                  <span className="text-white mobile:text-[13px] font-bold w-[264px] font-montserrat">
                    Team
                    <p className="text-neutral-300 font-openSans font-normal text-[13px] mobile:text-[11px]">
                      sell and bid on exclusive digital collectibles capturing
                      Team highlights.
                    </p>
                  </span>
                  {i18n.language === "ar" ? (
                    <BsArrowLeftShort size={26} className="text-white" />
                  ) : (
                    <BsArrowRightShort size={26} className="text-white" />
                  )}{" "}
                </div>{" "}
              </Link>
              <Link to={appRoutesObj.getSignUpPagePath()}>
                <div className="h-[78px] px-4 mobile:w-[342px] mt-[16px] bg-Primary-500 rounded-lg justify-start items-center gap-[23px] inline-flex">
                  <BiFootball size={24} className="text-white" />
                  <span className="text-white mobile:text-[13px] font-bold w-[264px] font-montserrat">
                    Athlete
                    <p className="text-neutral-300 font-openSans font-normal text-[13px] mobile:text-[11px]">
                      Monetize your journey; sell and bid on exclusive digital
                      memorabilia.
                    </p>
                  </span>
                  {i18n.language === "ar" ? (
                    <BsArrowLeftShort size={26} className="text-white" />
                  ) : (
                    <BsArrowRightShort size={26} className="text-white" />
                  )}
                </div>
              </Link>
              <span className="text-[13px] text-center text-white font-openSans pt-[32px]">
                Already have an account
                <span
                  onClick={handlePageLogIn}
                  className="px-2 underline cursor-pointer text-secondary-500"
                >
                  {" "}
                  LogIn
                </span>
              </span>
              <div className="h-[1px] bg-white opacity-30 mt-[32px] w-full"></div>
              <p className="text-[11px] text-center pt-5 mb-20 mobile:mb-4 gap-1 font-openSans text-white">
                <span className="underline cursor-pointer text-secondary-500">
                  {" "}
                  Terms of Service
                </span>{" "}
                &
                <span className="underline cursor-pointer text-secondary-500">
                  {" "}
                  Privacy Policy
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-60 mobile:w-full absolute -bottom-0 mobile:-bottom-0 -right-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
    </div>
  );
}

export default TypeOfAccountYoudBeOpeningContainer;
