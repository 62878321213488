import { useTranslation } from "react-i18next";

interface IProps {
  disabled: boolean;
  isChallenge?: boolean;
  withd?: string;
  textButton: string;
}
function FormButton({ isChallenge = false, ...props }: IProps) {
  const backgroundColorClass = isChallenge ? "bg-orangeColor" : "bg-yellow";
  const textColor = isChallenge ? "text-white" : "text-darkColor";
  const { t, i18n } = useTranslation();
  return (
    <div className="flex justify-center items-center mobile:pt-[15px]">
      <button
        className={`disabled:cursor-not-allowed ${backgroundColorClass} ${props.withd} h-12 text-white font-openSans bg-secondary-500 mobile:h-14 rounded-lg`}
        disabled={props.disabled}
        type="submit"
      >
        <span
          className={`${
            i18n.language === "en"
              ? `text-base ${textColor} mobile:text-[16px]`
              : `text-base ${textColor} mobile:text-[16px]`
          }`}
        >
          {t(props.textButton)}
        </span>
      </button>
    </div>
  );
}
export default FormButton;
