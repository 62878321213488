import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../user/layout-components/footer-components";
import NavBar from "../user/layout-components/nav-bar-componentes";
interface LayoutProps {
  children: ReactElement;
}
export function Layout({ children }: LayoutProps) {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("language") || "ar");
  }, [i18n]);
  return (
    <div
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className="bg-Primary-950"
    >
      <NavBar />
      {children}
      <Footer />
    </div>
  );
}
