import InputFiled from "src/components/user/inputFiled-component/inputFiled-component";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import Checkbox from "src/components/user/check-box-component/checkbox-component";
import { useState } from "react";
import { Form, Formik, useFormik } from "formik";
import { initialValues } from "./signUp-containers.data";
import { validationSchemaSignUp } from "./signUp-containers.validation";
import FormButton from "src/components/user/button-form";
import { getHintText } from "src/core/helpers/example.helper";
import { appRoutesObj } from "src/app.paths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function SignUpContainer() {
  const [isChecked, setChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const handleCheckboxChange = () => {
    setChecked(!isChecked);
    formik.setFieldValue("checkBox", isChecked);
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      navigate(appRoutesObj.getVerificationCodePath());
    },
    validationSchema: validationSchemaSignUp,
  });
  const handlePageLogIn = () => {
    navigate(appRoutesObj.getLoginPagePath());
  };
  const [type] = useState<string>("password");

  return (
    <div
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className="h-screen bg-Primary-950"
    >
      <div className="w-[538px] mobile:w-full absolute mobile:left-0 -left-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
      <div className="relative items-start justify-start text-white p-7">
        <img
          className="max-w-[175px] max-h-[67px]"
          src="/assets/images/Logo-White.png"
          alt=""
        />
      </div>
      <div className="relative flex items-center justify-center px-40 mobile:px-4">
        <div className="w-full h-auto pb-4 rounded-lg z-30 bg-Primary-700">
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            enableReinitialize
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="flex flex-col items-center pt-[60px] mobile:pt-4">
                <p className="text-2xl font-bold leading-loose tracking-tight text-center text-white font-montserrat">
                  Welcome To HalaMint
                </p>
                <div className="flex gap-4 pt-4">
                  <FcGoogle size={25} />
                  <FaApple className="text-white" size={25} />
                  <FaXTwitter className="text-white" size={25} />
                  <FaFacebook size={25} className="text-blue-700" />
                </div>
                <div className="text-neutral-400 font-montserrat pt-[24px]">
                  -OR-
                </div>
                <div className="pt-[24px]">
                  <div className="flex flex-col">
                    <InputFiled
                      onChange={(e) => formik.handleChange(e)}
                      name="username"
                      aria-label="Username"
                      placeholder="Enter your username"
                      isValid={
                        formik.touched.username &&
                        Boolean(!formik.errors.username)
                      }
                      hint={getHintText(
                        formik.errors.username,
                        formik.touched.username,
                        formik.submitCount
                      )}
                    />
                    <InputFiled
                      onChange={(e) => formik.handleChange(e)}
                      name="email"
                      aria-label="Email"
                      placeholder="Enter your email address"
                      isValid={
                        formik.touched.email && Boolean(!formik.errors.email)
                      }
                      hint={getHintText(
                        formik.errors.email,
                        formik.touched.email,
                        formik.submitCount
                      )}
                    />
                    <InputFiled
                      onChange={(e) => formik.handleChange(e)}
                      name="password"
                      aria-label="Password"
                      placeholder="Enter your password"
                      type={type}
                      isValid={
                        formik.touched.password &&
                        Boolean(!formik.errors.password)
                      }
                      hint={getHintText(
                        formik.errors.password,
                        formik.touched.password,
                        formik.submitCount
                      )}
                    />
                    <InputFiled
                      onChange={(e) => formik.handleChange(e)}
                      name="password2"
                      type={type}
                      aria-label="Confirm Password"
                      placeholder="Enter your password"
                      isValid={
                        formik.touched.password2 &&
                        Boolean(!formik.errors.password2)
                      }
                      hint={getHintText(
                        formik.errors.password2,
                        formik.touched.password2,
                        formik.submitCount
                      )}
                    />
                    <div className="flex gap-2 mobile:ml-6 mobile:rtl:mr-6">
                      <Checkbox
                        name="checkBox"
                        label={""}
                        checked={formik.values.checkBox}
                        onChange={handleCheckboxChange}
                        isValid={
                          formik.touched.checkBox &&
                          Boolean(!formik.errors.checkBox)
                        }
                        hint={getHintText(
                          formik.errors.checkBox,
                          formik.touched.checkBox,
                          formik.submitCount
                        )}
                      />
                      <p className="text-[11px] mobile:w-[260px] gap-1 font-openSans text-white">
                        {" "}
                        By signing up, you agree to the
                        <span className="underline cursor-pointer text-secondary-500">
                          {" "}
                          Terms of Service
                        </span>{" "}
                        &
                        <span className="underline cursor-pointer text-secondary-500">
                          {" "}
                          Privacy Policy
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <FormButton
                  textButton="submit"
                  withd="w-[385px] mobile:w-[342px] mt-6"
                  disabled={false}
                />
                <span className="text-[13px] text-white font-openSans pt-5">
                  Already have an account
                  <span
                    onClick={handlePageLogIn}
                    className="px-2 underline cursor-pointer text-secondary-500"
                  >
                    {" "}
                    LogIn
                  </span>
                </span>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="w-60 mobile:w-full absolute -bottom-0 mobile:-bottom-0 -right-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
    </div>
  );
}

export default SignUpContainer;
