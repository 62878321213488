import { useState } from "react";
import { BiExport, BiPencil } from "react-icons/bi";
import CollectedComponent from "./portfolio-collected-component";
import DealsComponent from "./portfolio-deals-component";
import LikedComponent from "./portfolio-liked-component";
import ActivityComponent from "./portfolio-activity-component";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

function PortfolioComponent() {
  const [activeTab, setActiveTab] = useState<string>("Collected");
  const [showShareOption, setShowShareOption] = useState<boolean>(false);
  const [headerImage, setHeaderImage] = useState<string>(
    "/assets/images/header-1.png"
  );
  const [avatarImage, setAvatarImage] = useState<string>(
    "/assets/images/avatar-1.png"
  );
  const navigate = useNavigate();
  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target?.result as string;
        setImage(imageDataUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (inputId: string) => {
    const fileInput = document.getElementById(inputId) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  return (
    <div className="w-[1204px] laptop:w-[1000px] tablet:w-[700px] mobile:w-full p-10 mobile:p-4 bg-[#002a66] mobile:bg-transparent rounded-lg flex-col justify-start items-center gap-6 inline-flex">
      <div className="h-[342px] mobile:h-full flex-col justify-start items-start gap-10 mobile:gap-0 inline-flex">
        <div className="self-stretch h-[270px] mobile:h-[460px] flex-col justify-start items-start flex">
          <div className="self-stretch h-[180px] w-[1124px] laptop:w-[920px] tablet:w-[620px] mobile:w-[360px] flex-col justify-start items-start flex">
            <img
              alt=""
              className="absolute object-cover w-[1124px] h-[180px] rounded-lg laptop:w-[920px] tablet:w-[620px] mobile:w-[359px] mobile:h-[180px]"
              src={headerImage}
            ></img>
            <div className="self-stretch h-[180px] p-6 rounded-lg flex-col justify-end items-end gap-1 flex">
              <div className="h-[22px] relative justify-start items-center gap-2 inline-flex">
                <BiPencil
                  onClick={() => triggerFileInput("headerImageUpload")}
                  className="text-white w-6 h-6 cursor-pointer relative z-20"
                />
                <BiExport
                  onClick={() => setShowShareOption(!showShareOption)}
                  className="text-white w-6 h-6 cursor-pointer relative z-20"
                />
              </div>
              <div className="relative flex flex-col justify-end items-end">
                {showShareOption && (
                  <div className="absolute top-2 z-50">
                    <div className="h-11 flex flex-col items-center w-[111px] border-b border-white/10 rounded-t-lg cursor-pointer bg-Primary-500">
                      <div className="grow shrink basis-0 justify-start items-center gap-1 flex">
                        <div className="grow shrink basis-0  flex-col justify-center items-end inline-flex">
                          <div className="self-stretch text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                            Copy link
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-11 flex flex-col items-center w-[111px] rounded-b-lg cursor-pointer bg-Primary-500">
                      <div className="grow shrink basis-0  justify-start items-center gap-1 flex">
                        <div className="grow shrink basis-0  flex-col justify-center items-end inline-flex">
                          <img src="/assets/images/X.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <input
                type="file"
                id="headerImageUpload"
                className="hidden"
                accept="image/*"
                onChange={(e) => handleImageChange(e, setHeaderImage)}
              />
            </div>
          </div>
          <div className="self-stretch relative pl-6 mobile:pl-0 mt-[-60px] justify-start items-end gap-10 mobile:gap-2.5 inline-flex mobile:flex-col mobile:items-start">
            <img
              alt=""
              className="w-40 h-40 rounded-full mobile:ml-6 cursor-pointer"
              src={avatarImage}
              onClick={() => triggerFileInput("avatarImageUpload")}
            />
            <input
              type="file"
              id="avatarImageUpload"
              className="hidden"
              accept="image/*"
              onChange={(e) => handleImageChange(e, setAvatarImage)}
            />
            <div className="grow shrink basis-0 h-[60px] mobile:h-[132px] justify-between items-center flex mobile:flex-col mobile:gap-6 mobile:items-start mobile:justify-start">
              <div className="flex flex-col">
                <div className="text-white text-[23px] font-bold font-['Montserrat'] leading-loose">
                  Abdullah Ahmed
                </div>
                <div className="w-[138px] text-[#c1c1c1] text-base font-normal font-['Open Sans'] leading-snug">
                  @AADD
                </div>
              </div>
             <div className="flex gap-x-2">
             <button
                onClick={() => navigate(appRoutesObj.getSetProfilePageUrl())}
                className="w-[170px] h-12 rounded-lg bg-[#50b848] hover:bg-white hover:text-[#50b848] text-white text-base font-normal font-['Open Sans'] leading-snug"
              >
                Edit Profile
              </button>
              <button
                onClick={()=>{
                  try {
                    localStorage.removeItem("user");
                  navigate(appRoutesObj.getBasePath());
                } catch (error) {
                    console.error("Error removing user data:", error);
                    
                }
                 
                }}
                className="w-[170px] h-12 rounded-lg border border-secondary-100 text-white text-base font-normal font-['Open Sans'] leading-snug"
              >
                Log out
              </button>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-10 inline-flex">
          <div
            onClick={() => setActiveTab("Collected")}
            className={`text-center py-2 text-[#a7a7a7] cursor-pointer ${
              activeTab === "Collected" && "border-b border-white text-white"
            }  text-[13px] font-bold font-['Montserrat'] uppercase leading-[18.20px]`}
          >
            Collected
          </div>

          <div
            onClick={() => setActiveTab("Deals")}
            className={`text-center py-2 text-[#a7a7a7] ${
              activeTab === "Deals" && "border-b border-white text-white"
            } text-[13px] font-bold font-['Montserrat'] uppercase leading-[18.20px] cursor-pointer`}
          >
            Deals
          </div>
          <div
            onClick={() => setActiveTab("Liked")}
            className={`text-center py-2 text-[#a7a7a7] ${
              activeTab === "Liked" && "border-b border-white text-white"
            } text-[13px] font-bold font-['Montserrat'] uppercase leading-[18.20px] cursor-pointer`}
          >
            Liked
          </div>
          <div
            onClick={() => setActiveTab("Activity")}
            className={`text-center py-2 text-[#a7a7a7] ${
              activeTab === "Activity" && "border-b border-white text-white"
            } text-[13px] font-bold font-['Montserrat'] uppercase leading-[18.20px] cursor-pointer`}
          >
            Activity
          </div>
        </div>
      </div>
      <div
        className={`bg-Primary-600 ${
          activeTab === "Activity"
            ? "mobile:bg-Primary-600 mobile:px-6"
            : "mobile:bg-transparent"
        }  rounded-lg flex flex-col w-[1124px] laptop:w-[920px] tablet:w-[620px] mobile:w-full p-6 mobile:py-6 mobile:px-0`}
      >
        {activeTab === "Collected" && <CollectedComponent />}
        {activeTab === "Deals" && <DealsComponent />}
        {activeTab === "Liked" && <LikedComponent />}
        {activeTab === "Activity" && <ActivityComponent />}
      </div>
    </div>
  );
}

export default PortfolioComponent;
