import { useEffect, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { options } from "src/core/statics/nfts-mock-data/nfts-mock-data";
import LoginPopupComponent from "./popup-login-component";
import BuyNftPopupComponent from "./buy-nft-popup-component";

function PopularItemsComponents() {
  const [highlightIndex, setHighlightIndex] = useState<number>(2);
  const [highlightIndexMobile, setHighlightIndexMobile] = useState<number>(0);
  const [isOpenPlaceAbidPopup, setIsOpenPlaceAbidPopup] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const highlightIndex0 = 0;
  const highlightIndex1 = 1;
  const highlightIndex3 = 3;
  const highlightIndex4 = 4;
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();
  const handlePrevClickMobile = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const Items = options.flatMap((option) => option.Item || []);

  const handleNextClickMobile = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === Items.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };
  const handlePlaceBidClick = (item: any) => {
    setSelectedItem(item);
    if (loggedIn) {
      setIsOpenPlaceAbidPopup(true);
    } else {
      setShowLoginPopup(true);
    }
  };
  useEffect(() => {
    const initialValues = localStorage.getItem("user");
    if (initialValues) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);
  return (
    <div className="relative">
      <div
        dir="ltr"
        className="flex flex-col items-center justify-center pt-10 mobile:pt-8 z-0"
      >
        <div className="w-full flex justify-center items-center mobile:hidden tablet:hidden text-center text-[80px]">
          <img
            src="/assets/images/LIVE-AUCITION-BG.svg"
            alt=""
            className="absolute w-full"
          />
          <div className="relative overflow-clip scale-[.99] -rotate-6">
            <div className="w-full animate-marquee whitespace-nowrap flex justify-center items-center text-[80px]">
              <div className="flex items-center justify-center gap-[50px]">
                <img
                  src="assets/images/LIVE AUCITION.svg"
                  alt="Live Auction"
                  className="rotate-6 w-full laptop:w-[390px]"
                />
                <img
                  src="assets/images/Star 3.svg"
                  alt="Live Auction"
                  className="rotate-6 pr-12 w-full laptop:w-[60px]"
                />
                <img
                  src="assets/images/LIVE AUCITION.svg"
                  alt="Live Auction"
                  className="rotate-6 w-full laptop:w-[390px]"
                />
                <img
                  src="assets/images/Star 3.svg"
                  alt="Live Auction"
                  className="rotate-6 pr-12 w-full laptop:w-[60px]"
                />
                <img
                  src="assets/images/LIVE AUCITION.svg"
                  alt="Live Auction"
                  className="rotate-6 w-full laptop:w-[390px]"
                />
                <img
                  src="assets/images/Star 3.svg"
                  alt="Live Auction"
                  className="rotate-6 pr-12 w-full laptop:w-[60px]"
                />
                <img
                  src="assets/images/LIVE AUCITION.svg"
                  alt="Live Auction"
                  className="rotate-6 w-full laptop:w-[390px]"
                />
                <img
                  src="assets/images/Star 3.svg"
                  alt="Live Auction"
                  className="rotate-6 pr-12 w-full laptop:w-[60px]"
                />
                <img
                  src="assets/images/LIVE AUCITION.svg"
                  alt="Live Auction"
                  className="rotate-6 w-full laptop:w-[390px]"
                />
                <img
                  src="assets/images/Star 3.svg"
                  alt="Live Auction"
                  className="rotate-6 pr-12 w-full laptop:w-[60px]"
                />
                <img
                  src="assets/images/LIVE AUCITION.svg"
                  alt="Live Auction"
                  className="rotate-6 w-full laptop:w-[390px]"
                />
                <img
                  src="assets/images/Star 3.svg"
                  alt="Live Auction"
                  className="rotate-6 pr-12 w-full laptop:w-[60px]"
                />
              </div>
            </div>
          </div>
        </div>
        <p className="text-white text-[48px] pt-3 font-montserrat font-bold">
          Popular Items
        </p>
        <div className="relative z-0 flex items-center gap-1 mt-[80px] mr-[95px] justify-center mobile:hidden tablet:hidden">
          {Items.slice(0, 5).map((item, index) => (
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  appRoutesObj.getNftsItemDetailsUrl(item.id.toString())
                );
                scrollToTop();
              }}
              className="mobile:w-[85px] mobile:ml-[-120px] w-[250px] largeLaptop:w-[200px] laptop:w-[170px]"
              key={item.id}
            >
              <div
                className={`group mobile:w-[269px] largeLaptop:w-[300px] laptop:w-[270px] relative ml-${
                  index * 400
                } w-[330px] h-[417px] hover:z-50 hover:bg-[#0047AB] bg-[#002A66] flex flex-col justify-center
                            items-center rounded-lg transition duration-300 ease-in-out transform

                            ${
                              index === highlightIndex
                                ? "scale-105 z-40 bg-[#0047AB]"
                                : "hover:scale-105 hover:z-50"
                            }
                            ${
                              index === highlightIndex3
                                ? "scale-100 shadow-lg z-30 mt-9"
                                : "hover:scale-105 hover:z-50"
                            }
                            ${
                              index === highlightIndex1
                                ? "scale-100 shadow-lg z-30 mt-9"
                                : "hover:scale-105 hover:z-50"
                            }
                            ${
                              index === highlightIndex0
                                ? "scale-100 z-20 mt-[50px] bg-[#002A66]"
                                : "hover:scale-105 hover:z-50"
                            }
                            ${
                              index === highlightIndex4
                                ? "scale-100 z-0 mt-[50px]"
                                : "hover:scale-105 hover:z-50"
                            }
                             `}
                onMouseEnter={() => setHighlightIndex(index)}
                onMouseLeave={() => setHighlightIndex(2)}
              >
                <div className={`flex flex-col`}>
                  <img
                    src={item.img}
                    alt=""
                    className="rounded-lg mobile:w-[248px] w-[298px] laptop:w-[250px] largeLaptop:w-[280px] h-[307px] transform transition duration-300 ease-in-out group-hover:scale-y-75"
                  />
                  <div className="flex flex-row justify-between w-full pt-6">
                    <p className="font-montserrat text-[19px] text-white">
                      {item.name}
                    </p>
                    <p className="font-montserrat text-[19px] text-white">
                      {item.price} SAR
                    </p>
                  </div>
                  <p className="text-[13px] mt-2 group-hover:mb-[100px] opacity-50 text-[#D4D4D8]">
                    {item.username}
                  </p>
                </div>
                <div className="">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handlePlaceBidClick(item);
                    }}
                    className="absolute bottom-0 laptop:w-[200px] p-2 px-3 text-white transition duration-300 ease-in-out transform -translate-x-1/2 bg-[#50B848] hover:bg-white hover:text-[#50B848] rounded-md opacity-0 w-[250px] mb-5 group-hover:opacity-100"
                  >
                    Collect Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-[538px] mobile:w-full absolute -left-0 rtl:right-0 mobile:left-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>

      {/* mobile */}
      <div className="flex flex-col z-0 items-center justify-center pt-8 desktop:hidden laptop:hidden largeLaptop:hidden largeDesktop:hidden">
        {Items.map((item, index) => (
          <NavLink
            onClick={scrollToTop}
            to={appRoutesObj.getNftsItemDetailsUrl(item.id.toString())}
            key={index}
            className={`
                        group w-[330px] h-[417px]
                        px-5 bg-[#0047AB] rounded-lg
                        hover:bg-[#0047AB]
                        transition duration-300
                        ease-in-out transform
                        ${index === highlightIndexMobile ? "" : "hidden"}`}
          >
            <div className={`flex flex-col group-hover:mt-[-20px]`}>
              <img
                src={item.img}
                alt=""
                className="rounded-lg pt-4 w-[298px] h-[307px] transform transition duration-300 ease-in-out group-hover:scale-y-75"
              />
              <div className="flex flex-row justify-between w-full group-hover:mt-[-30px] mt-6">
                <p className="font-montserrat text-[19px] text-white ">
                  {item.name}
                </p>
                <p className="font-montserrat text-[19px] text-white">
                  {item.price}
                </p>
              </div>
              <p className="text-[13px] mt-2 group-hover:mb-[100px] opacity-50">
                {item.username}
              </p>
            </div>
            <div className="">
              <button className="absolute bottom-0 left-[160px] p-2 px-3 text-white transition duration-300 ease-in-out transform -translate-x-1/2 bg-[#50B848] hover:bg-white hover:text-[#50B848] rounded-md opacity-0 w-[250px] mb-5 group-hover:opacity-100">
                Follow
              </button>
            </div>
          </NavLink>
        ))}
        <div dir="ltr" className="flex items-center justify-center gap-9 pt-9">
          <div
            className="flex items-center justify-center border-[#50B848] border-l-[0.5px] rounded-full px-2 cursor-pointer"
            onClick={handlePrevClickMobile}
          >
            <MdArrowBackIos className="text-[#50B848]" size={26} />
          </div>
          <div
            className="flex items-center justify-center border-[#50B848] border-r-[0.5px] rounded-full px-1 cursor-pointer"
            onClick={handleNextClickMobile}
          >
            <MdArrowForwardIos className="text-[#50B848]" size={26} />
          </div>
        </div>
      </div>
      <div className="pt-[100px] tablet:pt-[50px] flex justify-center">
        <NavLink
          onClick={scrollToTop}
          to={appRoutesObj.getNftsPagePath()}
          className="w-[189px] mobile:w-[357px] h-[48px] rounded-lg border border-[#50B848] flex items-center text-white justify-center gap-2"
        >
          Explore More <GoArrowUpRight size={16} />{" "}
        </NavLink>
      </div>
      {isOpenPlaceAbidPopup && (
        <BuyNftPopupComponent
          currentPrice={selectedItem.price}
          name={selectedItem.name}
          img={selectedItem.img}
          onClickToClose={() => setIsOpenPlaceAbidPopup(false)}
        />
      )}
      {showLoginPopup && (
        <LoginPopupComponent onClickToClose={() => setShowLoginPopup(false)} />
      )}
    </div>
  );
}
export default PopularItemsComponents;
