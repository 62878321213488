import { Form, Formik, useFormik } from "formik";
import { FaApple, FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import FormButton from "src/components/user/button-form";
import Checkbox from "src/components/user/check-box-component/checkbox-component";
import InputFiled from "src/components/user/inputFiled-component/inputFiled-component";
import { getHintText } from "src/core/helpers/example.helper";
import { appRoutesObj } from "src/app.paths";
import { Link } from "react-router-dom";
import { useState } from "react";
import { BiLowVision } from "react-icons/bi";
import InputPasswordComponent from "src/components/user/input-password-component/input-password-component";
import { FaXTwitter } from "react-icons/fa6";
import { initialValues } from "src/containers/user/login-container/login-containers.data";
import { validationSchemaLogin } from "src/containers/user/login-container/login-containers.validation";
import { BsXLg } from "react-icons/bs";

interface Iprops {
  onClickToClose: () => void;
}
function LoginPopupComponent(props: Iprops) {
  const [isChecked, setChecked] = useState<boolean>(true);
  const [type, setType] = useState<string>("password");

  const handlePasswordType = () => {
    setType(type === "password" ? "text" : "password");
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {},
    validationSchema: validationSchemaLogin(),
  });
  const ShinUp: string = appRoutesObj.getTypeOfAccountPagePath();
  return (
    <div
      className={`fixed top-0 left-0 z-50 inset-y-0 inset-x-0 mx-auto my-auto bg-Primary-600 shadow-[rgba(0,_0,_0,_0.10)_0px_0px_20px_0px] w-[1095px] h-[676px] p-4 laptop:w-[1000px] mobile:w-[400px] mobile:h-[707px] tablet:w-[800px] tablet:h-[700px] rounded-2xl`}
    >
      <div className="relative flex flex-col items-center justify-start">
        <div className="absolute top-4 right-4 z-40">
          <BsXLg
            onClick={props.onClickToClose}
            className="w-6 h-6 text-white cursor-pointer"
          />
        </div>
        <div className="flex mobile:flex-col mt-6 tablet:flex-col mobile:items-center justify-between h-auto rounded-lg z-30">
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={validationSchemaLogin()}
            enableReinitialize
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                if (
                  formik.values.username === "test@test.com" &&
                  formik.values.password === "Test@123"
                ) {
                  window.location.reload();
                  localStorage.setItem("user", formik.values.username);
                }
              }}
            >
              <div className="flex flex-col items-center">
                <p className="text-2xl font-bold leading-loose tracking-tight text-center text-white font-montserrat">
                  Welcome back
                </p>
                {/* Social login buttons */}
                <div className="flex gap-4 pt-4">
                  <FcGoogle size={25} />
                  <FaApple className="text-white" size={25} />
                  <FaXTwitter className="text-white" size={25} />
                  <FaFacebook size={25} className="text-blue-700" />
                </div>

                {/* OR separator */}
                <div className="text-neutral-400 font-montserrat pt-[24px]">
                  -OR-
                </div>

                {/* Username and password inputs */}
                <div className="pt-[24px]">
                  <div className="flex flex-col">
                    <InputFiled
                      onChange={(e) => formik.handleChange(e)}
                      name="username"
                      aria-label="Username/Email"
                      placeholder="Enter your username/email"
                      isValid={
                        formik.touched.username &&
                        Boolean(!formik.errors.username)
                      }
                      hint={getHintText(
                        formik.errors.username,
                        formik.touched.username,
                        formik.submitCount
                      )}
                    />
                    <InputPasswordComponent
                      name="password"
                      onChange={(e) => formik.handleChange(e)}
                      label="Password"
                      type={type}
                      isValid={
                        formik.touched.password &&
                        Boolean(!formik.errors.password)
                      }
                      hint={getHintText(
                        formik.errors.password,
                        formik.touched.password,
                        formik.submitCount
                      )}
                      placeholder="Enter your password"
                      suffix={
                        <div className="flex mobile:px-6">
                          <BiLowVision
                            size={19}
                            className="text-neutral-400"
                            onClick={() => handlePasswordType()}
                          />
                        </div>
                      }
                    />

                    {/* Remember me checkbox and forgot password link */}
                    <div className="flex justify-between">
                      <div className="flex gap-2 mobile:ml-6 mobile:rtl:mr-6">
                        <Checkbox
                          name=""
                          label={""}
                          checked={!isChecked}
                          onChange={() => setChecked(!isChecked)}
                        />
                        <p className="text-[11px] gap-1 font-openSans text-white">
                          Remember me
                        </p>
                      </div>
                      <p className="text-[11px] gap-1 mobile:px-6 font-openSans text-white cursor-pointer">
                        Forget your password?
                      </p>
                    </div>
                  </div>
                </div>

                {/* Login button */}
                <FormButton
                  textButton="Login"
                  withd="w-[204px] mobile:w-[342px] mt-6"
                  disabled={false}
                />

                {/* Signup link */}
                <span className="text-[13px] text-white font-openSans pt-5">
                  Don’t have an account?
                  <Link to={ShinUp}>
                    <span className="px-2 underline cursor-pointer text-secondary-500">
                      Signup
                    </span>
                  </Link>
                </span>

                {/* Terms of service and privacy policy links */}
                <div className="h-[1px] mobile:w-[342px] bg-white opacity-30 mt-[32px] w-full"></div>
                <p className="text-[11px] pt-5 mb-20 laptop:mb-12 mobile:w-[260px] mobile:text-center mobile:mb-0 gap-1 font-openSans text-white">
                  <span className="underline cursor-pointer text-secondary-500">
                    Terms of Service
                  </span>{" "}
                  &
                  <span className="underline cursor-pointer text-secondary-500">
                    Privacy Policy
                  </span>
                  .
                </p>
              </div>
            </Form>
          </Formik>
          {/* Background images */}
          <div className="relative tablet:hidden mobile:hidden mobile:pt-0 tablet:pt-0 laptop:pt-0 flex justify-center items-center mx-[150px] mobile:mx-[140px] mobile:my-[80px] ">
            <div className="mobile:mx-[-80px] laptop:mx-[-50px]  card-container perspective-1000 ">
              <div className="transition-transform duration-1000 ease-in-out card transform-style-preserve-3d ">
                <div className="w-full h-full transition-transform duration-1000 ease-in-out card-inner transform-style-preserve-3d ">
                  <div className="w-full h-full card-face backface-hidden">
                    <img
                      src="/assets/images/front-login.png"
                      alt=""
                      className="w-full h-full bg-gradient-to-r rounded-xl"
                    />
                  </div>
                  <div className="absolute w-full h-full transform card-face backface-hidden rotate-y-180">
                    <img
                      src="/assets/images/front.png"
                      alt=""
                      className="w-full h-full bg-gradient-to-r rounded-xl from-Primary-950 to-Primary-800"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute rotate-[25deg] mobile:rotate-12 ml-24 pt-[76px] mobile:pt-[50px] mobile:mx-[-140px] laptop:mx-[-160px] card-container">
              <div className="transition-transform duration-1000 ease-in-out card transform-style-preserve-3d">
                <div className="w-full h-full transition-transform duration-1000 ease-in-out card-inner transform-style-preserve-3d">
                  <div className="absolute w-full h-full card-face backface-hidden">
                    <img
                      src="/assets/images/front-login.png"
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                  <div className="absolute w-full h-full transform card-face backface-hidden rotate-y-180">
                    <img
                      src="/assets/images/Property1.png"
                      alt=""
                      className="w-full h-full bg-gradient-to-r rounded-xl from-Primary-950 to-Primary-800"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPopupComponent;
