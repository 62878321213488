export function OurCommunityComponents() {
  return (
    <div className="px-[50px] mobile:px-4 py-[100px] mobile:py-[210px] tablet:flex-col tablet:flex">
      <div className="flex mobile:justify-center mb-24 tablet:w-0 tablet:mt-[-50px] tablet:ml-[130px] mobile:ml-[-430px] left-[-25px] laptop:hidden largeLaptop:hidden largeDesktop:hidden">
        <div className="relative tablet:hidden flex justify-start items-start ltr:left-0 left-[380px] rotate-12 group">
          <div className="absolute w-[270px] rounded-lg shadow-md h-[405px] left-[140px] tablet:left-[-40px] group-hover:left-[120px] tablet:group-hover:left-[22px] tablet:group-hover:w-[250px] transition-all group-hover:scale-[1.2] group-hover:w-[250px] duration-300 ease-in-out bg-[#2b4d7f]">
            <img
              src="assets/images/front.png"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div className="absolute w-[270px] rounded-lg shadow-md h-[405px] left-[130px] tablet:left-[-30px] group-hover:left-[110px] tablet:group-hover:left-[17px] tablet:group-hover:w-[250px] transition-all group-hover:scale-[1.2] group-hover:w-[240px] duration-300 ease-in-out bg-[#2b4d7f]">
            <img
              src="assets/images/front.png"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div className="absolute w-[270px] rounded-lg shadow-md h-[405px] left-[120px] tablet:left-[-20px] group-hover:left-[100px] tablet:group-hover:left-[12px] tablet:group-hover:w-[250px] transition-all group-hover:scale-[1.2] group-hover:w-[230px] duration-300 ease-in-out bg-[#2b4d7f]">
            <img
              src="assets/images/front.png"
              alt=""
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className="bg-[#0047AB] mobile:w-full text-start h-[326px] mobile:h-[758px] mobile:justify-start justify-between items-center flex rounded-[20px]">
        <div className="text-white mobile:pt-80 px-36 mobile:px-4 gap-6 flex flex-col items-start justify-start">
          <p className="text-[33px] text-start font-bold mobile:w-[326px] font-montserrat">
            Beyond the World, Into the Blockchain{" "}
          </p>
          <p className="flex gap-1 text-start">
            Join Our <p className="text-secondary-500">NFTs</p> Sports Universe!
          </p>
          <button className="w-[189px] h-[48px] bg-secondary-500 rounded-lg font-openSans">
            Join our community
          </button>
        </div>

        <div className="flex w-[24%] rtl:w-[10%] mt-[-510px] laptop:mt-[-400px] mobile:mt-3 mobile:hidden tablet:hidden">
          <div className="relative rotate-[20deg] group">
            <div className="absolute w-[270px] rounded-lg shadow-md h-[405px] left-[120px] laptop:left-[-70px] largeLaptop:left-[30px]   laptop:group-hover:left-[25px] largeLaptop:group-hover:left-[25px] transition-all group-hover:scale-[1.2] group-hover:w-[250px] laptop:group-hover:w-[230px] duration-300 ease-in-out bg-[#2b4d7f]">
              <img
                src="assets/images/front.png"
                alt=""
                className="w-full h-full"
              />
            </div>
            <div className="absolute w-[270px] rounded-lg shadow-md h-[405px] left-[110px] laptop:left-[-60px] largeLaptop:left-[20px]  laptop:group-hover:left-[15px] largeLaptop:group-hover:left-[15px] transition-all group-hover:scale-[1.2] group-hover:w-[240px] laptop:group-hover:w-[230px] duration-300 ease-in-out bg-[#2b4d7f]">
              <img
                src="assets/images/front.png"
                alt=""
                className="w-full h-full"
              />
            </div>
            <div className="absolute w-[270px] rounded-lg shadow-md h-[405px] left-[100px] laptop:left-[-50px] largeLaptop:left-[10px]  laptop:group-hover:left-[1px] largeLaptop:group-hover:left-[1px] transition-all group-hover:scale-[1.2] group-hover:w-[230px] laptop:group-hover:w-[230px] duration-300 ease-in-out bg-[#2b4d7f]">
              <img
                src="assets/images/front.png"
                alt=""
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurCommunityComponents;
