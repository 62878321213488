import { DirectionEnum } from "src/core/enums/design-system.enum";
export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
  label?: string;
}
export function InputFiledProfileComponent({
  hint,
  isValid,
  ...props
}: InputFieldProps): JSX.Element {
  const getValidationPaddingClass = () => {
    switch (isValid) {
      case true:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      case false:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      default:
        return "px-4";
    }
  };
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "";
      case false:
        return "border border-red-500 outline-none";
      default:
        return "";
    }
  };
  const getHintClass = () => {
    switch (isValid) {
      case true:
        return "text-success";
      case false:
        return "text-red-500 pt-1 text-[13px]";
      default:
        return "text-muted";
    }
  };
  return (
    <div dir={props.dir} className="w-full">
      {props["aria-label"] && (
        <label
          className={` ${props.label} px-2 font-openSans mb-2 block text-base`}
        >
          {props["aria-label"]}
        </label>
      )}
      <div className="relative">
        <input
          {...props}
          className={`${props.className}
          ${getValidationClass()} ${getValidationPaddingClass()}`}
        />
        <div
          className={`absolute top-5 flex items-center justify-center space-x-1 ${
            props.dir === DirectionEnum.RTL ? "left-3" : "right-3"
          }`}
        ></div>
      </div>
      {hint && <p className={getHintClass()}>{hint}</p>}
    </div>
  );
}

export default InputFiledProfileComponent;
