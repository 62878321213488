import { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  cities: string[];
}
function DropdwonIdVerificationComponent(props: InputFieldProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const [filteredCities] = useState<string[]>(props.cities);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (city: string) => {
    setIsOpen(false);
    setSelectedCity(city);
  };
  return (
    <div className="relative">
      <p className="mb-4 px-2 text-[19px] font-openSans text-white">
        Coutry of residency
      </p>
      <div>
        <button
          type="button"
          className={`flex justify-between items-center w-[344px] mobile:w-[342px] tablet:w-[342px] h-11 px-4 font-openSans bg-Primary-500 text-[13px] text-gray-700 focus:outline-none
        ${isOpen ? "rounded-t" : "rounded"}
        `}
          onClick={handleToggle}
        >
          <span className="text-white font-openSans">
            {selectedCity || "Choose your city"}
          </span>
          {isOpen ? (
            <FiChevronUp size={24} className="text-white" />
          ) : (
            <FiChevronDown size={24} className="text-white" />
          )}
        </button>
      </div>
      {isOpen && (
        <div className="absolute z-30 w-[344px] mobile:w-[342px] shadow-lg bg-Primary-500">
          <div role="menu">
            {filteredCities.map((city) => (
              <button
                key={city}
                onClick={() => handleSelect(city)}
                className="flex flex-col p-3.5 text-white px-4 text-[13px]"
                role="menuitem"
              >
                {city}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdwonIdVerificationComponent;
