import { GoArrowUpRight } from "react-icons/go";
import TabBar from "./tab-bar";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

function ExploreSportsComponents() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="flex flex-col pt-[150px] mobile:pt-[840px] justify-center items-center tablet:pt-[800px]">
      <div className="w-[538px] mobile:w-full absolute -left-0 rtl:right-0 h-[420.33px] mobile:bg-Primary-500 bg-Primary-300 rounded-full blur-[700px]"></div>
      <div className="flex flex-col relative justify-center items-center z-20">
        <p className="text-white text-[48px]">Explore Sports</p>
        <TabBar />
        <div className="pt-[50px] mobile:pt-[90px]">
          <NavLink
            to={appRoutesObj.getNftsPagePath()}
            onClick={scrollToTop}
            className="w-[189px] mobile:w-[357px] h-[48px] rounded-lg border border-[#50B848] flex items-center text-white justify-center gap-2"
          >
            Explore More <GoArrowUpRight size={16} />{" "}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
export default ExploreSportsComponents;
