import { useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { options } from "src/core/statics/nfts-mock-data/nfts-mock-data";

export function PopularCollectionsComponents() {
  const [highlightIndexMobile, setHighlightIndexMobile] = useState<number>(0);
  const [visibleCount] = useState<number>(3);
  const collection = options.flatMap((option) => option.Collections || []);

  const handlePrevClick = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const handleNextClick = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === currentCollection.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };

  const currentCollection = collection.slice(0, visibleCount);
  const handlePrevClickMobile = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  const handleNextClickMobile = () => {
    setHighlightIndexMobile((prevIndex) => {
      if (prevIndex === collection.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  };
  return (
    <div className="relative z-10">
      <div className="flex flex-col items-center justify-center pt-[170px] gap-[70px] mobile:pt-[90px] mobile:gap-0">
        <div className="w-40 mobile:w-full absolute -right-0 rtl:left-0 h-[720.33px] mobile:bg-Primary-500 bg-Primary-300 rounded-full blur-[700px]"></div>
        <p className="text-white z-20 text-[48px] text-center w-full font-montserrat">
          Popular Collections
        </p>

        <div
          dir="ltr"
          className="flex items-center relative justify-center gap-7 laptop:gap-3 mobile:mt-[30px] mobile:gap-0"
        >
          <div className="border-l border-[#50B848] w-full px-2 laptop:px-1 rounded-full cursor-pointer mobile:hidden tablet:hidden">
            <MdArrowBackIos
              className="text-[#50B848]"
              size={26}
              onClick={highlightIndexMobile > 0 ? handlePrevClick : undefined}
            />
          </div>
          {currentCollection.map((item, index) => (
            <div className="w-[300px] flex" key={index}>
              <div className="w-[330px] px-[6px] mobile:ml-[60px] h-[392px] tablet:hidden mobile:hidden bg-[#002A66] flex flex-col justify-center items-center rounded-lg">
                <NavLink
                  onClick={scrollToTop}
                  to={appRoutesObj.getNftsCollectionsDetailsUrl(
                    item.id.toString()
                  )}
                >
                  <div className="flex flex-col">
                    <div className="flex justify-between">
                      <div className="w-[158px] h-[305px] mb-4">
                        <img
                          src={item.img[0]}
                          alt=""
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="w-[132px] h-[144px] mb-4">
                          <img
                            src={item.img[1]}
                            alt=""
                            className="object-cover w-full h-full rounded-lg"
                          />
                        </div>
                        <div className="w-[132px] h-[144px]">
                          <img
                            src={item.img[2]}
                            alt=""
                            className="object-cover w-full h-full rounded-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="flex flex-col w-[203px]">
                        <p className="font-montserrat text-[19px] text-white">
                          {item.name}
                        </p>
                        <p className="text-[13px] mt-2 opacity-50">
                          {item.username}
                        </p>
                      </div>
                      <div className="">
                        <div className="w-[95px] p-[3px] rounded-2xl bg-gradient-to-l from-[#50B848] to-[#0047AB]">
                          <div className="flex items-center rounded-2xl justify-center w-full h-full bg-[#002A66] back">
                            <h1 className="text-sm text-white">
                              {item.img.length} Items
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          ))}
          <div className="pl-4 border-r laptop:pl-0 border-[#50B848] rounded-full cursor-pointer mobile:hidden tablet:hidden">
            <MdArrowForwardIos
              className="text-[#50B848]  mobile:hidden"
              size={26}
              onClick={handleNextClick}
            />
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="flex items-center justify-center w-full largeLaptop:hidden largeDesktop:hidden laptop:hidden desktop:hidden">
          <div className="h-[392px] px-3 z-0 bg-[#002A66] flex flex-col justify-center items-center rounded-lg">
            {collection.map((item, index) => (
              <NavLink
                onClick={scrollToTop}
                className={`${index === highlightIndexMobile ? "" : "hidden"}`}
                to={appRoutesObj.getNftsCollectionsDetailsUrl(
                  item.id.toString()
                )}
              >
                <div key={index} className="flex flex-col">
                  <div className="flex justify-between">
                    <div className="w-[158px] h-[305px] mb-4">
                      <img
                        src={item.img[0]}
                        alt=""
                        className="object-cover w-full h-full rounded-lg"
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="w-[132px] h-[144px] mb-4">
                        <img
                          src={item.img[1]}
                          alt=""
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                      <div className="w-[132px] h-[144px]">
                        <img
                          src={item.img[2]}
                          alt=""
                          className="object-cover w-full h-full rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex flex-col w-[203px]">
                      <p className="font-montserrat text-[19px] text-white">
                        {item.name}
                      </p>
                      <p className="text-[13px] mt-2 opacity-50">
                        {item.username}
                      </p>
                    </div>
                    <div className="">
                      <div className="w-[95px] p-[3px] rounded-2xl bg-gradient-to-l from-[#50B848] to-[#0047AB]">
                        <div className="flex items-center rounded-2xl justify-center w-full h-full bg-[#002A66] back">
                          <h1 className="text-sm text-white">
                            {item.img.length} items
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        </div>

        <div className="laptop:hidden desktop:hidden largeLaptop:hidden largeDesktop:hidden flex mt-[50px] justify-center items-center">
          <div dir="ltr" className="flex items-center justify-center gap-9">
            <div className="flex items-center justify-center border-[#50B848] border-l-[0.5px] rounded-full px-2 cursor-pointer">
              <MdArrowBackIos
                className="text-[#50B848]"
                size={26}
                onClick={handlePrevClickMobile}
              />
            </div>
            <div className="flex items-center justify-center border-[#50B848] border-r-[0.5px] rounded-full px-1 cursor-pointer">
              <MdArrowForwardIos
                className="text-[#50B848]"
                size={26}
                onClick={handleNextClickMobile}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center pt-9">
          <NavLink
            onClick={scrollToTop}
            to={appRoutesObj.getNftsPagePath()}
            className="w-[189px] mobile:w-[357px] h-[48px] rounded-lg border border-[#50B848] flex items-center text-white justify-center gap-2"
          >
            Explore More <GoArrowUpRight size={16} />{" "}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default PopularCollectionsComponents;
