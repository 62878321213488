import React from "react";

interface DataTableNftsProps {
  data: {
    event?: string;
    price?: string;
    form?: string;
    to?: string;
    date?: string;
    sarPrices?: string;
    usdPrice?: string;
    quantity?: string;
    floorDifference?: string;
    expiration?: string;
  }[];
  title: string[];
}

const DataTableNfts: React.FC<DataTableNftsProps> = ({ data, title }) => {
  return (
    <div className="overflow-x-auto px-6 pt-[24px]">
      <div className="px-0 bg-Primary-700 mobile:overflow-x-scroll mobile:w-full h-[298px] mobile:h-auto mobile:pb-6 rounded-lg">
        <div className="flex justify-between mobile:w-[600px] text-center pt-[30px] px-6">
          {title.map((heading, index) => (
            <div key={index} className="w-full text-center">
              <p className="text-white font-openSans text-[13px]">{heading}</p>
            </div>
          ))}
        </div>
        <div className="px-6 mobile:w-[600px]">
          <div className="border-[0.4px] opacity-20 mt-4" />
        </div>

        <div className="flex flex-col gap-4 mt-4 mobile:w-[600px]">
          {data.map((row, rowIndex) => (
            <div key={rowIndex} className="flex flex-col gap-4">
              <div className="flex px-6 mt-0 font-openSans text-[13px] text-white justify-between items-center">
                {Object.entries(row).map(([key, value]) => (
                  <div key={key} className="w-full text-center">
                    {value}
                  </div>
                ))}
              </div>
              <div className="px-6">
                <div className="border-[0.4px] opacity-20" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataTableNfts;
