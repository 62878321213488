import * as yup from "yup";

export const paymentValidationSchema = () => {
  return yup.object().shape({
    cardNumber: yup
      .string()
      .min(16, "The card number is invalid")
      .max(16, "The card number is invalid")
      .required("required"),
    CVV: yup
      .string()
      .min(3, "The CVV is invalid")
      .max(3, "The CVV is invalid")
      .required("required"),
    expirationDate: yup
      .string()
      .matches(/([0-9]{2})\/([0-9]{2})/, "The expiration date is invalid")
      .required("required"),
  });
};
