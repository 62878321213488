import React from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { appRoutesObj } from "./app.paths";
import SignUpPage from "./pages/user/signUp-page/signup-page";
import VerificationCodePage from "./pages/user/verification-code-page/verification-code-page";
import SetProfilePage from "./pages/user/set-profile-page/set-profile-page";
import LogInPage from "./pages/user/login-page/login-page";
import TypeOfAccountYoudBeOpeningPage from "./pages/user/type-of-account-youd-be-opening./type-of-account-youd-be-opening";
import IdVerificationPage from "./pages/user/id-verification-page/id-verification-page";
import NftsDetailsItemPage from "./pages/user/nfts-details-item-page/nfts-details-item-page";
import NftsDetailsCollectionsPage from "./pages/user/nfts-details-collections-page/nfts-details-collections-page";
import CommunityPoolsPage from "./pages/user/community-pools-page/community-pools-page";
import PortfolioPage from "./pages/user/portfolio-page/portfolio-page";
import PaymentPage from "./pages/user/payment-page/payment-page";

const ExamplePage = React.lazy(() => import("./pages/user/example-page"));
const NotFoundPage = React.lazy(() => import("./pages/404"));
const NFTsPage = React.lazy(() => import("./pages/user/nfts-page"));

const withSuspense = (WrappedComponent: JSX.Element) => {
  return (
    <Suspense
      fallback={<div className="text-primary-200 pt-10">Loading...</div>}
    >
      {WrappedComponent}
    </Suspense>
  );
};

export function AppRouting() {
  return (
    <Suspense
      fallback={<div className="text-primary-200 pt-10">Loading...</div>}
    >
      <BrowserRouter>
        <Routes>
          <Route
            key="homepage"
            path={appRoutesObj.getBasePath()}
            element={withSuspense(<App />)}
          />

          <Route
            key="examplePage"
            path={appRoutesObj.getExamplePagePath()}
            element={withSuspense(<ExamplePage />)}
          />

          <Route
            key="notDefined"
            path="*"
            element={withSuspense(<NotFoundPage />)}
          />
          <Route
            key="NFTsPage"
            path={appRoutesObj.getNftsPagePath()}
            element={withSuspense(<NFTsPage />)}
          />
          <Route
            key="login"
            path={appRoutesObj.getLoginPagePath()}
            element={withSuspense(<LogInPage />)}
          />
          <Route
            key="TypeOfAccount"
            path={appRoutesObj.getTypeOfAccountPagePath()}
            element={withSuspense(<TypeOfAccountYoudBeOpeningPage />)}
          />
          <Route
            key="signUp"
            path={appRoutesObj.getSignUpPagePath()}
            element={withSuspense(<SignUpPage />)}
          />
          <Route
            key="verificationCode"
            path={appRoutesObj.getVerificationCodePath()}
            element={withSuspense(<VerificationCodePage />)}
          />
          <Route
            key="SetProfile"
            path={appRoutesObj.getSetProfilePath()}
            element={withSuspense(<SetProfilePage />)}
          />
          <Route
            key="IdVerification"
            path={appRoutesObj.getIdVerificationPath()}
            element={withSuspense(<IdVerificationPage />)}
          />
          <Route
            key="NftsDetailsItem"
            path={appRoutesObj.getNftsItemDetailsPath()}
            element={withSuspense(<NftsDetailsItemPage />)}
          />
          <Route
            key="NftsDetailsCollections"
            path={appRoutesObj.getNftsCollectionsDetailsPath()}
            element={withSuspense(<NftsDetailsCollectionsPage />)}
          />
          <Route
            key="CommunityPools"
            path={appRoutesObj.getCommunityPoolsPath()}
            element={withSuspense(<CommunityPoolsPage />)}
          />
          <Route
            key="Payment"
            path={appRoutesObj.getPaymentPath()}
            element={withSuspense(<PaymentPage />)}
          />
          <Route
            key="Portfolio"
            path={appRoutesObj.getPortfolioPath()}
            element={withSuspense(<PortfolioPage />)}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
