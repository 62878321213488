import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

interface TitleDetailsPages {
  home: "Home";
  titlePage: string;
  titleDetailsPage: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

function TitleDetailsPageComponent(props: TitleDetailsPages) {
  return (
    <div className="flex relative px-6 font-montserrat gap-4 mobile:hidden tablet:hidden">
      <Link to={appRoutesObj.getBasePath()} className="text-neutral-300">
        {props.home}
      </Link>
      <MdOutlineKeyboardArrowRight size={24} className="text-neutral-300" />
      <div onClick={props.onClick} className="text-neutral-300 cursor-pointer">
        {props.titlePage}
      </div>
      <MdOutlineKeyboardArrowRight size={24} className="text-neutral-300" />
      <div className="text-green-500">{props.titleDetailsPage}</div>
    </div>
  );
}

export default TitleDetailsPageComponent;
