function OurServicesComponents() {
  const text = [
    {
      icon: (
        <img className="w-10 h10" src="/assets/icons/Wallet-icon.svg" alt="" />
      ),
      title: "Setup your wallet",
      subtitle: "we provide you to choose between multiple wallet services",
    },
    {
      icon: (
        <img className="w-10 h10" src="/assets/icons/show-icon.svg" alt="" />
      ),
      title: "Discover your NFTs",
      subtitle:
        "Choose from 15k NFT collections , 275k Artwork , 40Teams and 2300 Athlete",
    },
    {
      icon: (
        <img className="w-10 h10" src="/assets/icons/Dollar-icon.svg" alt="" />
      ),
      title: "Sell your NFTs",
      subtitle: "Sell your NFT and get your return on investment from ",
    },
  ];

  return (
    <div className="h-[465px] flex flex-col pt-[70px] mobile:pt-[880px] tablet:pt-[850px] justify-center items-center">
      <p className="text-white text-[48px] pb-5 text-center w-full">
        Our Services
      </p>
      <div className="flex gap-[24px] laptop:gap-0 mobile:flex-col tablet:flex-col justify-center items-center">
        <div className="flex px-5 mobile:px-4 largeLaptop:pr-0 mobile:pr-4 mobile:flex-col mobile:flex">
          <div className="w-[328px] laptop:w-[245px] largeLaptop:w-[270px] tablet:w-[100%] h-[334px] rounded-lg bg-[#0047AB] flex justify-center items-center">
            <div className="flex flex-col w-[280px] h-[286px] px-2">
              <img
                className="w-10 h10"
                src="/assets/icons/account-icon.png"
                alt=""
              />
              <p className="text-[33px] text-white w-[240px] pb-2 leading-[40px] laptop:text-[26px] font-openSans">
                Setup your account
              </p>
              <p className="text-[16px] text-white w-[240px] pb-[50px] laptop:text-[13px]">
                Start by Creating your halamint account
              </p>
              <button className="h-[48px] text-white bg-[#50B848] rounded-lg hover:bg-white hover:text-[#50B848]">
                Let’s get started
              </button>
            </div>
          </div>
        </div>
        {text.map((val, index) => (
          <div className="laptop:w-[245px]">
            <div
              className="flex mobile:flex-col tablet:flex mobile:flex laptop:px-0 mobile:px-0"
              key={index}
            >
              <div className="w-[328px] largeLaptop:w-[270px] laptop:w-[90%] tablet:w-[100%] px-2 h-[334px] bg-[#0047AB1F] flex justify-center items-center">
                <div className="flex flex-col w-[280px] h-[286px]">
                  {val.icon}
                  <p className="text-[33px] text-white w-[] font-openSans pb-4 laptop:text-[30px]">
                    {val.title}
                  </p>
                  <p className="text-[16px] text-white w-[] pb-[50px] font-openSans laptop:text-[13px]">
                    {val.subtitle}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    // <div className="flex items-center justify-center pt-[70px]">
    // <div className="w-[100%] mobile:w-[90%] flex px-9  mobile:flex-col justify-center">
    //   <div className="w-[19%] bg-[#0047AB] px-2 mobile:w-full  rounded-lg py-3">
    //     <BiUser className="text-white laptop:text-start" size={40} />
    //     <p className="text-[33px] text-white leading-[35px] laptop:text-[26px] font-openSans">Setup your account</p>
    //     <p className="text-[16px] text-white pb-[50px] laptop:text-[13px]">Start by Creating your halamint account</p>
    //     <button className="h-[48px] text-white bg-[#50B848]  w-[100%] rounded-lg hover:bg-white hover:text-[#50B848]">Let’s get started</button>
    //   </div>
    //   <div className="flex pl-6 mobile:flex-col">
    //     {text.map((item, index) => (
    //       <div className="flex flex-row" key={index}>
    //         <div className="w-[90%] flex flex-col bg-[#0047AB1F] py-3 px-2">
    //           <div>{item.icon}</div>
    //           <p className="text-[33px] text-white  font-openSans pb-4">{item.title}</p>
    //           <p className="text-[16px] text-white  w-[50%] pb-[50px] font-openSans laptop:text-[13px]">{item.subtitle}</p>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    //     </div>
    // </div>
  );
}
export default OurServicesComponents;
