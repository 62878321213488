import PortfolioComponent from "src/components/user/portfolio-component/portfolio-component";
import TitlePageComponent from "src/components/user/title-page-component";

function PortfolioContainer() {
  return (
    <div className="flex flex-col relative justify-start gap-16 my-16">
      <TitlePageComponent home={"Home"} titlePage={"Portfolio"} />
      <div className="flex justify-center z-20">
        <PortfolioComponent />
      </div>
      <div className="w-52 absolute -right-0 h-[587.33px] mobile:hidden tablet:hidden mt-72 bg-Primary-300 rounded-full blur-[700px]"></div>
    </div>
  );
}

export default PortfolioContainer;
