import { Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { BsXLg } from "react-icons/bs";
import { useBalanceStore } from "src/core/stores/balance-store";
import { BidAPriceValuesType } from "src/core/types/place-a-bid-type";
import { BidSchema } from "./validation/place-a-bid-price-validation";

interface Iprops {
  img: string | undefined;
  name: string | undefined;
  currentPrice: number | undefined;
  onClickToClose: () => void;
}
function BuyNftPopupComponent(props: Iprops) {
  const { balance } = useBalanceStore();
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(true);
  const initialValues: BidAPriceValuesType = {
    bidPrice: props.currentPrice || 0,
    totalPrice: (props.currentPrice || 0) * 1.015,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: BidSchema(balance, props.currentPrice!),
    onSubmit: async () => {
      props.onClickToClose();
    },
  });
  const handleConfirmBidClick = () => {
    setIsOpenPopup(false);
    setIsOpenConfirmPopup(true);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={BidSchema(balance, props.currentPrice!)}
      validateOnBlur
      onSubmit={() => formik.submitForm()}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        {isOpenPopup && (
          <div
            className={`fixed top-0 z-50 left-0 inset-y-0 inset-x-0 mx-auto my-auto bg-Primary-600 shadow-[rgba(0,_0,_0,_0.10)_0px_0px_20px_0px] w-[400px] h-[515px] p-4 laptop:w-[400px] mobile:w-[358px] mobile:h-[569px] tablet:w-[400px] tablet:h-[550x] rounded-2xl`}
          >
            <div className="flex flex-col gap-6">
              <div className="self-stretch h-10 p-4 rounded-lg justify-end items-center inline-flex">
                <BsXLg
                  onClick={props.onClickToClose}
                  className="h-6 w-6 text-white cursor-pointer"
                />
              </div>
              <div className="w-[368px] mobile:w-[326px] flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch text-white text-[19px] font-bold font-['Montserrat'] leading-relaxed">
                  Buy NFT
                </div>
                <div className="self-stretch text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                  You are to place a bid for cryptoPork #485 from cryptoPork
                  collection
                </div>
              </div>
              <div className="w-[368px] mobile:w-[326px] p-4 bg-Primary-600 rounded-xl border border-white border-opacity-10 justify-between items-center inline-flex">
                <div className="grow shrink basis-0 h-10 justify-start items-start gap-4 flex">
                  <img
                    alt=""
                    src={props.img}
                    className="w-10 h-10 relative bg-gradient-to-b from-green-300 to-green-500 rounded-[28px]"
                  />
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="justify-start items-start gap-1 inline-flex">
                      <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                        0x313…1054
                      </div>
                    </div>
                    <div className="self-stretch text-white text-sm font-normal font-['Inter'] leading-tight">
                      {props.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-5">
                  <div className="w-[368px] mobile:w-[326px] flex-col justify-center items-start gap-4 inline-flex">
                    <div className="self-stretch justify-between items-start inline-flex">
                      <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                        Price
                      </p>
                      <p className="grow shrink basis-0 text-right text-white text-base font-normal font-['Open Sans'] leading-snug">
                        {formik.values.bidPrice} halamint
                      </p>
                    </div>
                    <div className="self-stretch justify-between items-start inline-flex">
                      <p
                        className={`grow shrink basis-0 ${
                          balance === 0 ||
                          balance < formik.values.bidPrice * 1.015
                            ? "text-red-500"
                            : "text-green-500"
                        }  text-base font-normal font-['Open Sans'] leading-snug`}
                      >
                        Your balance{" "}
                      </p>
                      <p
                        className={`grow shrink basis-0 text-right ${
                          balance === 0 ||
                          balance < formik.values.bidPrice * 1.015
                            ? "text-red-500"
                            : "text-green-500"
                        } text-base font-normal font-['Open Sans'] leading-snug`}
                      >
                        {balance} halamint
                      </p>
                    </div>
                    <div className="self-stretch justify-between items-start inline-flex">
                      <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                        Rarible fee
                      </p>
                      <p className="grow shrink basis-0 text-right text-white text-base font-normal font-['Open Sans'] leading-snug">
                        1.5%
                      </p>
                    </div>
                    <div className="w-[368px] mobile:w-[326px] h-[38px] py-2 border-t border-whiteOpacity10 justify-between items-start inline-flex">
                      <p className="grow shrink basis-0 text-white text-base font-normal font-['Open Sans'] leading-snug">
                        Total price
                      </p>
                      <p className="grow shrink basis-0 text-right text-white text-base font-normal font-['Open Sans'] leading-snug">
                        {formik.values.totalPrice.toFixed(1)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row mobile:flex-col gap-3.5 justify-center items-center">
                <button
                  onClick={props.onClickToClose}
                  className="order-1 mobile:order-2 w-[170px] mobile:w-[326px] h-12 rounded-lg border border-green-500 text-white text-base font-normal font-['Open Sans'] leading-snug"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmBidClick}
                  disabled={formik.values.bidPrice * 1.015 > balance}
                  className={`order-2 mobile:order-1 w-[170px] mobile:w-[326px] h-12 ${
                    formik.values.bidPrice * 1.015 <= balance
                      ? ""
                      : "opacity-50"
                  } bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug`}
                >
                  Buy
                </button>
              </div>
            </div>
          </div>
        )}
        {isOpenConfirmPopup && (
          <div className="fixed top-0 left-0 inset-y-0 inset-x-0 mx-auto my-auto bg-Primary-600 shadow-[rgba(0,_0,_0,_0.10)_0px_0px_20px_0px] w-[444px] h-[266px] p-4 laptop:w-[400px] mobile:w-[358px] mobile:h-[328px] tablet:w-[480px] tablet:h-[550x] rounded-2xl">
            <div className="flex flex-col">
              <div className="self-stretch h-10 p-4 rounded-lg justify-end items-center inline-flex">
                <BsXLg
                  onClick={props.onClickToClose}
                  className="h-6 w-6 cursor-pointer text-white"
                />
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex-col justify-center items-center gap-4 inline-flex">
                  <div className="self-stretch flex-col justify-center items-center gap-4 flex">
                    <div className="self-stretch text-center text-white text-[19px] font-bold font-['Montserrat'] leading-relaxed">
                      Are you sure you want to pay the following price?
                    </div>
                    <div className="self-stretch text-center">
                      <span className="text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                        Click 'Confirm Payment' to verify your payment Price{" "}
                      </span>
                      <span className="text-green-500 text-[13px] font-bold font-['Open Sans'] leading-[18.20px]">
                        {formik.values.totalPrice.toFixed(2)} SAR.{" "}
                      </span>
                      <span className="text-white text-[13px] font-normal font-['Open Sans'] leading-[18.20px]">
                        Ensure it's correct before proceeding.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row mobile:flex-col gap-3.5 items-center justify-center">
                  <button
                    onClick={props.onClickToClose}
                    className="order-1 mobile:order-2 w-[170px] mobile:w-[326px] h-12 rounded-lg border border-green-500 text-white text-base font-normal font-['Open Sans'] leading-snug"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="order-2 mobile:order-1 w-[170px] mobile:w-[326px] h-12 bg-green-500 rounded-lg text-white text-base font-normal font-['Montserrat'] leading-snug"
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </Formik>
  );
}

export default BuyNftPopupComponent;
