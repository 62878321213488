import { SetProfile } from "src/core/types/user.type";

export const initialValues: SetProfile = {
  firstname: "",
  lastname: "",
  mobilenum: "",
  dateOfBirth: "",
  city: "",
  fulladdress: "",
  postalcode: "",
};
