import { Pools } from "src/core/types/user.type";

export const pools: Pools[] = [
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    {
        name: "NFTNexus",
        img: "assets/images/Frame 33.png",
        roiRate: "25%",
        members: 20,
        entranceFee: "25 SAR",
    },
    
]
