import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

interface TitlePages {
  home: "Home";
  titlePage: string;
}

function TitlePageComponent(props: TitlePages) {
  return (
    <div className="flex relative px-6 font-montserrat gap-4 mobile:hidden tablet:hidden">
      <Link to={appRoutesObj.getBasePath()} className="text-neutral-300">
        {props.home}
      </Link>
      <MdOutlineKeyboardArrowRight size={24} className="text-neutral-300" />
      <div className="text-green-500">{props.titlePage}</div>
    </div>
  );
}

export default TitlePageComponent;
