import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
let currentOTPIndex: number = 0;
function VerificationCodeContainer() {
  const { i18n } = useTranslation();
  const isResendDisabled = false;
  const [Seconds, setSeconds] = useState<number>(59);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [activeOtp, setActiveOtp] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleCodeChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    const newOTP: string[] = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);
    if (!value) setActiveOtp(currentOTPIndex - 1);
    else setActiveOtp(currentOTPIndex + 1);
    setOtp(newOTP);
  };
  const handleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index;
    if (key === "Backspace") setActiveOtp(currentOTPIndex - 1);
  };
  const handleResendCode = () => {
    setSeconds(59);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (Seconds > 0) {
        setSeconds(Seconds - 1);
      }
      if (Seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [Seconds]);
  const profile = appRoutesObj.getSetProfilePath();

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtp]);
  return (
    <div
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className="h-screen bg-Primary-950"
    >
      <div className="w-[538px] mobile:w-full absolute mobile:left-0 -left-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
      <div className="relative items-start justify-start text-white p-7">
        <img
          className="max-w-[175px] max-h-[67px]"
          src="/assets/images/Logo-White.png"
          alt=""
        />
      </div>
      <div className="relative flex px-40 mobile:px-4">
        <div className="rounded-lg bg-Primary-700 z-30 w-full h-[756px] mobile:h-[429px]">
          <div className="flex flex-col justify-start items-center pt-[60px] mobile:pt-4">
            <p className="text-2xl font-bold leading-loose tracking-tight text-center text-white font-montserrat">
              Welcome To HalaMint
            </p>
            <div className="flex flex-col pt-[62px] gap-1">
              <span className="text-[19px] text-white font-montserrat">
                Enter verification code
              </span>
              <span className="w-[400px] mobile:w-[330px] text-[13px] font-openSans opacity-80 text-neutral-300 leading-[18.20px]">
                The verification code has been sent to your email
                Jason0123@gmail.com
              </span>
            </div>
            <div className="flex gap-2 pt-10 mobile:gap-1">
              {otp.map((_, index) => {
                return (
                  <React.Fragment key={index}>
                    <input
                      ref={index === activeOtp ? inputRef : null}
                      onChange={handleCodeChange}
                      onKeyDown={(e) => handleOnKeyDown(e, index)}
                      value={otp[index]}
                      type="number"
                      className="w-11 h-11 border rounded-lg bg-transparent text-center border-stone-300 focus:border-secondary-500 focus:outline-none text-white text-[13px]"
                    />
                    {index === otp.length - 1 ? null : (
                      <span className="w-2 py-0.5" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <button
              onClick={handleResendCode}
              className={`text-white flex text-[13px] w-[380px] mobile:w-[350px] pt-[24px] font-openSans ${
                isResendDisabled && "cursor-not-allowed"
              }`}
              style={{
                color: Seconds > 0 ? "text-white" : "text-red-500",
              }}
              disabled={Seconds > 0}
            >
              {Seconds === 0 ? (
                <p className=" text-secondary-500">Resend verification code</p>
              ) : (
                <p>{`Resend after ${Seconds} seconds`}</p>
              )}
            </button>
            <Link to={profile}>
              <button className="flex justify-center items-center w-[385px] mobile:w-[342px] h-12 rounded-lg font-openSans text-white hover:bg-white hover:text-secondary-500 bg-secondary-500 mt-[47px]">
                submit
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-60 mobile:w-full absolute -bottom-0 mobile:-bottom-0 -right-0 h-[320.33px] bg-Primary-300 rounded-full blur-[700px]"></div>
    </div>
  );
}

export default VerificationCodeContainer;
