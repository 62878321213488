class AppRoutes {
  getCommonQuestionsPagePath(): string {
    throw new Error("Method not implemented.");
  }
  getGaragePartnerPagePath(): string {
    throw new Error("Method not implemented.");
  }
  getAboutUsPagePath(): string {
    throw new Error("Method not implemented.");
  }
  /**
   * The AppRoutes class defines the `getInstance` method that lets clients access
   * the unique AppRoutes instance.
   */
  private static instance: AppRoutes;
  /**
   * The AppRoutes's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}
  /**
   * The static method that controls the access to the singleton instance.
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }

  // paths
  getBasePath(): string {
    return "/";
  }

  getExamplePagePath(): string {
    return `/address/:addressId/view`;
  }

  getExamplePageUrl(): string {
    return this.getExamplePagePath();
  }

  // Example on a path with dynamic variables
  getExampleByIdPagePath(): string {
    return `/example/:id`;
  }
  getNftsPagePath(): string {
    return `/nfts`;
  }

  getNftsPageUrl(): string {
    return this.getNftsPagePath();
  }
  getLoginPagePath(): string {
    return `/login`;
  }

  getLoginPageUrl(): string {
    return this.getLoginPagePath();
  }
  getTypeOfAccountPagePath(): string {
    return `/typeOfAccount`;
  }

  getTypeOfAccountPageUrl(): string {
    return this.getTypeOfAccountPagePath();
  }
  getSignUpPagePath(): string {
    return `/signUp`;
  }

  getSignUpPageUrl(): string {
    return this.getSignUpPagePath();
  }
  getVerificationCodePath(): string {
    return `/verificationCode`;
  }

  getVerificationCodePageUrl(): string {
    return this.getSignUpPagePath();
  }
  getSetProfilePath(): string {
    return `/SetProfile`;
  }

  getSetProfilePageUrl(): string {
    return this.getSetProfilePath();
  }
  getIdVerificationPath(): string {
    return `/idVerification`;
  }

  getIdVerificationUrl(): string {
    return this.getIdVerificationPath();
  }
  getExampleByIdPageUrl(id: string): string {
    return this.getExampleByIdPagePath().replace(":id", id);
  }
  getNftsItemDetailsPath(): string {
    return "/nftsItemDetails/:id";
  }

  getNftsItemDetailsUrl(id: string): string {
    return this.getNftsItemDetailsPath().replace(":id", id);
  }
  getNftsCollectionsDetailsPath(): string {
    return "/nftsCollectionsDetails/:id";
  }

  getNftsCollectionsDetailsUrl(id: string): string {
    return this.getNftsCollectionsDetailsPath().replace(":id", id);
  }
  getCommunityPoolsPath(): string {
    return `/communityPools`;
  }

  getCommunityPoolsUrl(): string {
    return this.getCommunityPoolsPath();
  }
  getPaymentPath(): string {
    return `/payment`;
  }

  getPortfolioUrl(): string {
    return this.getPortfolioPath();
  }
  getPortfolioPath(): string {
    return `/portfolio`;
  }

  getPaymentUrl(): string {
    return this.getPaymentPath();
  }
}

export const appRoutesObj = AppRoutes.getInstance();
