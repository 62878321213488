import PaymentComponent from "src/components/user/payment-components/payment-component";

function PaymentContainer() {
  return (
    <div className="flex items-center my-8 justify-center">
      <PaymentComponent />
    </div>
  );
}

export default PaymentContainer;
