function ActivityEmbtyStateComponent() {
  return (
    <div className="w-[486px] mobile:w-[356px] flex-col justify-center items-center gap-6 flex">
      <img alt="" src="/assets/images/activity-embty-state.png" />
      <div className="self-stretch text-center text-white text-2xl mobile:text-base font-bold font-['Montserrat'] leading-loose tracking-tight">
        No activity yet
      </div>
    </div>
  );
}

export default ActivityEmbtyStateComponent;
